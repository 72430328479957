import React, { useState, useEffect } from "react";
import style from "./EventDetails.module.css";
import { useNavigate } from "react-router-dom";
import { IoMdTime } from "react-icons/io";
import { RiOrganizationChart } from "react-icons/ri";
import { MdOutlinePlace } from "react-icons/md";
import { baseUrl } from "../Url";

function EventDetails() {
  const navigate = useNavigate();

  const [eventData, setEventData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const eventId = localStorage.getItem("eventId");

    if (eventId) {
      const fetchEventData = async () => {
        try {
          const response = await fetch(`${baseUrl}/event/details`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              event_id: eventId,
            }),
          });
          if (!response.ok) {
            throw new Error("Failed to fetch event details");
          }
          const data = await response.json();
          setEventData(data.data);
        } catch (error) {
          console.error("Error fetching event details:", error);
        }
      };

      fetchEventData();
    }
  }, []);

  const handleUpdateClick = () => {
    navigate("/event/update");
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const eventId = localStorage.getItem("eventId");
      const response = await fetch(`${baseUrl}/event/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_id: eventId,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.description);
        alert(data.description);
        navigate("/events");
      } else {
        console.error(data.description);
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  return (
    <div className={style.container}>
      {!eventData ? (
        <div className={style.loading}>
          <div className={style.spinner}></div>
        </div>
      ) : (
        <>
          <div className={style.header}>
            <div className={style.headerText}>
              <h1>{eventData.event_title}</h1>
              <p>
                Date :{" "}
                {new Date(
                  eventData.event_start_date.$date
                ).toLocaleDateString()}{" "}
                -{" "}
                {new Date(eventData.event_end_date.$date).toLocaleDateString()}
              </p>
              <p>Mode : {eventData.event_mode}</p>
            </div>
            <div className={style.headerButton}>
              <button className={style.updateBtn} onClick={handleUpdateClick}>
                Update
              </button>
              <button className={style.deleteBtn} onClick={handleDeleteClick}>
                Delete
              </button>
            </div>
          </div>

          <div className={style.MainBox}>
            <div className={style.EventDetails}>
              <div className={style.box}>
                <div className={style.posterDetails}>
                  <div className={style.poster}>
                    <img src={eventData.event_image} alt="" />
                  </div>

                  <div className={style.discription}>
                    <div className={style.details}>
                      <h3>Details</h3>
                      <h4>{eventData.event_discription}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: eventData.event_details,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>

                <div className={style.detailCards}>
                  <div className={style.actionContainer}>
                    <div className={style.dateBox}>
                      <div className={style.dateTime}>
                        <IoMdTime className={style.timeIcon} />
                        <p>Date & Time:</p>
                      </div>
                      <span>
                        {new Date(
                          eventData.event_start_date.$date
                        ).toLocaleString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}{" "}
                        -{" "}
                        {new Date(
                          eventData.event_end_date.$date
                        ).toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}{" "}
                        IST
                      </span>
                    </div>
                  </div>

                  <div className={style.actionContainer}>
                    <div className={style.hostVenue}>
                      <div className={style.hostHead}>
                        <RiOrganizationChart />
                        <p>Hosted By:</p>
                      </div>
                      <span>{eventData.host_name}</span>
                    </div>
                  </div>

                  <div className={style.actionContainer}>
                    <div className={style.hostVenue}>
                      <div className={style.venueDetails}>
                        <MdOutlinePlace />
                        <p>Venue:</p>
                      </div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: eventData.event_venue,
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className={style.attendButton}>
                    <button>View Attendees</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showDeleteModal && (
            <div className={style.modal}>
              <div className={style.modalContent}>
                <h2>Are you sure you want to delete this event?</h2>
                <div className={style.modalButtons}>
                  <button onClick={handleCancelDelete}>Cancel</button>
                  <button onClick={handleConfirmDelete}>Delete</button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EventDetails;
