import React, { useEffect, useState } from "react";
import style from "./SingleLessonEdit.module.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Loader from "../Loader/Loader";

const SingleLessonEdit = () => {
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const orgId = state.organization;
  const topicId = state.topicId;
  const topic_Name = state.topicName;
  const learningId = state.learningId;
  const mainTopicId = state.mainTopicId;
  const UserId = state.Id;

  const [selectedTab, setSelectedTab] = useState(0);
  const [current, setCurrent] = useState(0);
  const [topicDetail, setTopicDetail] = useState([]);
  const [noofpages, setNoOfPages] = useState(0);
  const [topicName, setTopicName] = useState("");
  const [AiId, setAiID] = useState("");
  const [type, setType] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [videourl, setVideoUrl] = useState("");
  const [editorData, setEditorData] = useState("");
  const [lessonEdit, setLessonEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const decrement = () => {
    const updatedCurrent = current - 1;
    setCurrent(updatedCurrent);
    Pagination(updatedCurrent);
  };
  const increment = () => {
    const updatedCurrent = current + 1;
    setCurrent(updatedCurrent);
    Pagination(updatedCurrent);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  async function Topic_Single() {
    await fetch(
      "https://services.1o1learnersclub.com/ai/lesson/generation/for/user/admin",
      {
        method: "POST",
        body: JSON.stringify({
          Organization_ID: orgId,
          topic_id: topicId,
          topic_name: topic_Name,
          learning_id: learningId,
          main_topic_id: mainTopicId,
          user_id: UserId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setTopicName(data.data[0].topic_name);
        setNoOfPages(data.data[0].no_of_pages);
        setAiID(data.data[0].ai_id);
        // localStorage.setItem("Aiid", data.data[0].ai_id);
      });
  }

  async function Pagination(page) {
    await fetch("https://services.1o1learnersclub.com/lesson/pagination", {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        ai_id: AiId,
        user_id: UserId,
        page_num: page,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setIsLoading(false);
        setType(data.data[0].type);
        if (data.data[0].type === "image") {
          setImageUrl(data.data[0].text);
        } else if (data.data[0].type === "video_url") {
          setVideoUrl(data.data[0].text);
        } else {
          const Text = data.data[0].text.split("\n");
          setTopicDetail(Text);
          setEditorData(data.data[0].text.replace(/\n/g, "<br />"));
        }
      });
  }

  async function editLesson() {
    await fetch("https://services.1o1learnersclub.com/lesson/edit", {
      method: "POST",
      body: JSON.stringify({
        ai_id: AiId,
        Organization_ID: orgId,
        user_id: UserId,
        page_num: current,
        lesson: editorData,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          Pagination(data.data[0].page_number);
        }
      });
  }

  useEffect(() => {
    Topic_Single();
  }, [orgId]);

  useEffect(() => {
    console.log(current);
    if (AiId !== "") {
      Pagination(0);
    }
  }, [AiId]);

  return (
    <div className={style.Container}>
      <div className={style.topicHead}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "white" } }}
          value={selectedTab}
          onChange={handleTabChange}
          centered
        >
          <Tab
            label="Lesson"
            sx={{
              "&.Mui-selected": {
                color: "white",
                backgroundColor: "#1a1e5d",
              },
              fontSize: "1rem",
              color: "#1a1e5d",
              backgroundColor: "#dbdbdb",
              width: "200px",
              margin: "0 .5rem",
              borderRadius: "10px",
            }}
          />
          {/* <Tab
            label="Assesment"
            sx={{
              "&.Mui-selected": {
                color: "white",
                backgroundColor: "#1a1e5d",
              },
              fontSize: "1rem",
              color: "#1a1e5d",
              backgroundColor: "#dbdbdb",
              width: "200px",
              margin: "0 .5rem",
              borderRadius: "10px",
            }}
          /> */}
        </Tabs>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Typography className={style.Tab}>
          {selectedTab === 0 && (
            <div className={style.LessonDiv}>
              <div className={style.LessonHead}>
                <p>{topicName}</p>
                <div className={style.SecondHead}>
                  <div className={style.Pagination}>
                    <button
                      disabled={current === 0}
                      onClick={() => {
                        decrement();
                      }}
                    >
                      <GrFormPrevious />
                    </button>
                    <button>
                      {current + 1}/{noofpages + 1}
                    </button>
                    <button
                      disabled={current === noofpages}
                      onClick={() => {
                        increment();
                      }}
                    >
                      <GrFormNext />
                    </button>
                  </div>
                  <div className={style.editbutton}>
                    <button
                      onClick={() => {
                        setLessonEdit(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <div className={style.topicDetal}>
                {type === "image" ? (
                  <div className={style.imageView}>
                    <img src={imageUrl} />
                  </div>
                ) : type === "video_url" ? (
                  <div>
                    <video controls width="1000" height="560">
                      <source src={videourl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ) : (
                  <>
                    {topicDetail.map((text, index) => {
                      return (
                        <p
                          key={index}
                          className={text ? style.TextContent : style.Blank}
                        >
                          {text ? (
                            <span dangerouslySetInnerHTML={{ __html: text }} />
                          ) : (
                            "Blank"
                          )}
                        </p>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}
        </Typography>
      )}

      {lessonEdit ? (
        <div className={style.lessonEdit}>
          <div className={style.editClose}>
            <AiOutlineClose
              onClick={() => {
                setLessonEdit(false);
              }}
            />
          </div>
          <div className={style.editSection}>
            <CKEditor
              editor={ClassicEditor}
              data={editorData}
              onChange={handleEditorChange}
              onReady={(editor) => {
                editor.ui.view.editable.element.style.minHeight = "300px";
              }}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                ],
                alignment: {
                  options: ["left", "center", "right"],
                },
              }}
            />
          </div>
          <div className={style.lessonEditButton}>
            <button
              onClick={() => {
                editLesson();
                setLessonEdit(false);
              }}
            >
              Update
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SingleLessonEdit;
