import React, { useState, useEffect } from "react";
import style from "./Subscription.module.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { baseUrl } from "../Url";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { Input } from "@mui/material";

const Subscription = () => {
  const adminStatus = localStorage.getItem("adminStatus");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const userId = localStorage.getItem("adminUserId");

  const { profile } = useSelector((state) => state.profileData);

  const [userType, setUserType] = useState(["All", "Subscriber", "Trail"]);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [selectedCLubId, setSelectedClubId] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [clubList, setClubList] = useState([]);
  const [clubReadOnly, setClubReadOnly] = useState(true);
  const [userReadOnly, setUserReadOnly] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [tableShow, setTableShow] = useState(false);

  async function OrgGet() {
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOrgList(data.data);
      });
  }
  async function ClubGet(OrgId) {
    await fetch(`${baseUrl}/club/list`, {
      method: "POST",
      body: JSON.stringify({
        OrganizationID: OrgId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClubList(data.data);
      });
  }

  async function userGet(id, type, search) {
    id = id !== "" ? id : selectedCLubId;
    type = type !== "" ? type : selectedUserType;
    search = search !== 0 ? search : searchValue;
    await fetch(`${baseUrl}/users/subscribed`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrgId,
        club_id: id,
        user_type: type,
        search: search,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSubscriptionData(data.data);
        setTableShow(true);
      });
  }

  useEffect(() => {
    if (adminStatus === true || adminStatus === "true") {
      OrgGet();
    } else {
      const orgid = profile?.org_id;
      if (orgid !== undefined || orgid !== "") {
        setSelectedOrgId(orgid);
        setClubReadOnly(false);
        ClubGet(orgid);
      }
    }
  }, [adminStatus]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Subscription</p>
        </div>
        <div className={style.headButton}></div>
      </div>
      <div className={style.secondHead}>
        <div className={style.select_section}>
          <div className={style.selectLeft}>
            {adminStatus === true || adminStatus === "true" ? (
              <FormControl sx={{ width: "50%", margin: "0 0.25rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Organizations
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Organizations"
                  value={selectedOrgId}
                  onChange={(e) => {
                    const OrgID = e.target.value;
                    if (OrgID !== "") {
                      setSelectedOrgId(OrgID);
                      ClubGet(OrgID);
                      setClubReadOnly(false);
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Adjust the maximum height as needed
                      },
                    },
                  }}
                >
                  {orgList && orgList.length > 0 ? (
                    orgList.map((org) => (
                      <MenuItem key={org.org_id} value={org.org_id}>
                        {org.org_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No organizations available</MenuItem>
                  )}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            <FormControl
              sx={{ width: "50%", margin: "0 0.25rem", zIndex: "0" }}
            >
              <InputLabel id="demo-simple-select-label">
                Select Club/Batch
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Clubs/Batchs"
                disabled={clubReadOnly}
                value={selectedCLubId}
                onChange={(e) => {
                  const ClubID = e.target.value;
                  if (ClubID !== "") {
                    setSelectedClubId(ClubID);
                    userGet(ClubID, "", 0);
                    setUserReadOnly(false);
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {clubList && clubList.length > 0 ? (
                  clubList.map((club) => (
                    <MenuItem value={club.ClubID} key={club.ClubID}>
                      {club.ClubName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Clubs/Batchs available</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl
              sx={{ width: "50%", margin: "0 0.25rem", zIndex: "0" }}
            >
              <InputLabel id="demo-simple-select-label">
                Select User Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select User Type"
                disabled={userReadOnly}
                onChange={(e) => {
                  const userType = e.target.value;
                  if (userType !== "") {
                    setSelectedUserType(userType);
                    userGet("", userType, 0);
                  } else {
                    setSelectedUserType(""); // Handle the "All" case
                    userGet("", "", 0); // Send empty string for all users
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {userType && userType.length > 0 ? (
                  userType.map((user) => {
                    let displayText = user;
                    if (user === "Subscriber") {
                      displayText = "Subscribers";
                    } else if (user === "Trail") {
                      displayText = "Trials";
                    } else if (user === "All") {
                      displayText = "All Users";
                    }

                    return (
                      <MenuItem value={user === "All" ? "" : user} key={user}>
                        {displayText}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem disabled>No type available</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl
              sx={{ width: "50%", margin: "0 0.25rem", zIndex: "0" }}
            >
              <InputLabel htmlFor="search-input">Search</InputLabel>
              <Input
                id="search-input"
                value={searchValue}
                disabled={userReadOnly}
                onChange={(e) => {
                  const searchValue = e.target.value;
                  setsearchValue(searchValue);
                  userGet("", "", searchValue);
                }}
                placeholder="Type to search"
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.Table}>
          {tableShow && subscriptionData ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    key="name"
                    align="center"
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: "600",
                      color: "#535370",
                      background: "#f8faff",
                      borderRight: "1px solid  #dedede",
                      borderLeft: "1px solid  #dedede",
                      zIndex: "0",
                    }}
                  >
                    User Name
                  </TableCell>
                  <TableCell
                    key="Start"
                    align="center"
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: "600",
                      color: "#535370",
                      background: "#f8faff",
                      borderRight: "1px solid  #dedede",
                      zIndex: "0",
                    }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    key="Start"
                    align="center"
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: "600",
                      color: "#535370",
                      background: "#f8faff",
                      borderRight: "1px solid  #dedede",
                      zIndex: "0",
                    }}
                  >
                    Start date
                  </TableCell>
                  <TableCell
                    key="endDate"
                    align="center"
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: "600",
                      color: "#535370",
                      background: "#f8faff",
                      borderRight: "1px solid  #dedede",
                      zIndex: "0",
                    }}
                  >
                    End date
                  </TableCell>
                  <TableCell
                    key="amount"
                    align="center"
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: "600",
                      color: "#535370",
                      background: "#f8faff",
                      borderRight: "1px solid  #dedede",
                      zIndex: "0",
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    key="courseName"
                    align="center"
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: "600",
                      color: "#535370",
                      background: "#f8faff",
                      borderRight: "1px solid  #dedede",
                      zIndex: "0",
                    }}
                  >
                    Course name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptionData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {data.Username}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {data.user_type}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {data.start_date?.$date
                        ? new Date(data.start_date.$date).toLocaleDateString()
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {data.end_date?.$date
                        ? new Date(data.end_date.$date).toLocaleDateString()
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {data.amount_paid}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        border: "1px solid #e8e4ef",
                        fontSize: "1rem",
                      }}
                    >
                      {data.course_name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
