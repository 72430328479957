import React, { useEffect, useState } from "react";
import style from "./SessionEventDetail.module.css";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../Url";
import { Paper } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
const SessionEventDetail = () => {
  const location = useLocation();
  const state = location.state;
  console.log(state);

  const [eventDetailData, setEventDetailData] = useState([]);
  const [eventUserData, setEventUserData] = useState([]);
  const [scheduleData, setScheduleData] = useState({
    date: "",
    start: "",
    end: "",
  });
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [modifiedAttendees, setModifiedAttendees] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageData, setPageData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTimeChange = (userId, field, time) => {
    setPageData((prevData) =>
      prevData.map((attendee) => {
        if (attendee.user_id === userId) {
          const originalDate = new Date(attendee[field]);
          const [hours, minutes] = time.split(":");
          originalDate.setHours(hours, minutes, 0, 0);
          const updatedAttendee = {
            ...attendee,
            [field]: originalDate.toISOString(),
          };
          setModifiedAttendees((prev) => {
            const existingIndex = prev.findIndex((a) => a.user_id === userId);
            if (existingIndex === -1) {
              return [...prev, updatedAttendee];
            } else {
              const updatedList = [...prev];
              updatedList[existingIndex] = updatedAttendee;
              return updatedList;
            }
          });
          return updatedAttendee;
        }
        return attendee;
      })
    );
  };

  useEffect(() => {
    console.log(pageData);
  }, [pageData]);

  const formatTime = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";
    return date.toTimeString().substring(0, 5);
  };

  const handleHeaderCheckboxChange = () => {
    const newIsAllChecked = !isAllChecked;
    setIsAllChecked(newIsAllChecked);
    setPageData((prevData) =>
      prevData.map((attendee) => ({
        ...attendee,
        attended: newIsAllChecked,
      }))
    );
    if (newIsAllChecked) {
      setModifiedAttendees((prev) => {
        const updatedAttendees = pageData.map((attendee) => ({
          ...attendee,
          attended: true,
        }));
        return [
          ...prev.filter(
            (a) => !updatedAttendees.some((u) => u.user_id === a.user_id)
          ),
          ...updatedAttendees,
        ];
      });
    } else {
      setModifiedAttendees((prev) =>
        prev.filter((attendee) => {
          const originalAttendee = pageData.find(
            (user) => user.user_id === attendee.user_id
          );
          return (
            originalAttendee &&
            (attendee.StartDateTime !== originalAttendee.StartDateTime ||
              attendee.EndDateTime !== originalAttendee.EndDateTime)
          );
        })
      );
    }
  };

  const handleCheckboxChange = (userId) => {
    setPageData((prevData) =>
      prevData.map((attendee) => {
        if (attendee.user_id === userId) {
          const updatedAttendee = {
            ...attendee,
            attended: !attendee.attended,
          };
          setModifiedAttendees((prev) => {
            const existingIndex = prev.findIndex((a) => a.user_id === userId);
            const originalAttendee = pageData.find((a) => a.user_id === userId);
            if (existingIndex === -1) {
              return [...prev, updatedAttendee];
            } else if (
              updatedAttendee.attended === false &&
              updatedAttendee.StartDateTime ===
                originalAttendee.StartDateTime &&
              updatedAttendee.EndDateTime === originalAttendee.EndDateTime
            ) {
              const updatedList = [...prev];
              updatedList.splice(existingIndex, 1);
              return updatedList;
            } else {
              const updatedList = [...prev];
              updatedList[existingIndex] = updatedAttendee;
              return updatedList;
            }
          });
          return updatedAttendee;
        }
        return attendee;
      })
    );
  };

  useEffect(() => {
    console.log(modifiedAttendees);
  }, [modifiedAttendees]);

  async function eventDetailGet() {
    await fetch(`${baseUrl}/session/event-details`, {
      method: "POST",
      body: JSON.stringify({
        session_event_id: state?.eventId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "event detail list");
        const startDateTime = new Date(data.data.StartDateTime);
        const endDateTime = new Date(data.data.EndDateTime);

        const formattedDate = startDateTime.toISOString().split("T")[0];

        const formattedStartTime = startDateTime
          .toTimeString()
          .split(" ")[0]
          .slice(0, 5);
        const formattedEndTime = endDateTime
          .toTimeString()
          .split(" ")[0]
          .slice(0, 5);

        setEventDetailData(data.data);
        setEventUserData(data.data.attendees);
        setScheduleData({
          date: formattedDate,
          start: formattedStartTime,
          end: formattedEndTime,
        });
      });
  }

  async function attendanceMark() {
    const updates = modifiedAttendees.map((attendee) => ({
      ...attendee,
      StartDateTime: new Date(attendee.StartDateTime).toISOString(),
      EndDateTime: new Date(attendee.EndDateTime).toISOString(),
    }));
    await fetch(`${baseUrl}/session/event-attendee-edit`, {
      method: "POST",
      body: JSON.stringify({
        session_event_id: state?.eventId,
        updates: updates,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "event edit");
        if (data.status === true) {
          eventDetailGet();
        }
      });
  }

  useEffect(() => {
    if (eventUserData) {
      const paginatedData = eventUserData?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      setPageData(paginatedData);
    }
  }, [eventUserData, rowsPerPage, page]);

  useEffect(() => {
    if (state?.eventId) {
      eventDetailGet();
    }
  }, [state?.eventId]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>{eventDetailData?.session_name}</p>
        </div>
      </div>
      <div className={style.eventDetails}>
        <div className={style.eventTop}>
          <div className={style.eventTitle}>
            <p>{eventDetailData?.event_name}</p>
            <span>{eventDetailData?.description}</span>
          </div>
          <div className={style.schedule}>
            <p>Schedule</p>
            <div className={style.scheduleDetails}>
              <div className={style.eventInput}>
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  value={scheduleData.date}
                  readOnly
                />
              </div>
              <div className={style.eventInput}>
                <label htmlFor="s-time">Start time</label>
                <input
                  type="time"
                  name="s-time"
                  id="s-time"
                  value={scheduleData.start}
                  readOnly
                />
              </div>
              <div className={style.eventInput}>
                <label htmlFor="e-time">End time</label>
                <input
                  type="time"
                  name="e-time"
                  id="e-time"
                  value={scheduleData.end}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.eventBottom}>
          <Paper sx={{ height: "100%", width: "100%" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: "#ededed" }}>
                  <TableCell>Name</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>
                    <div className={style.tableSelect}>
                      <input
                        type="checkbox"
                        checked={isAllChecked}
                        onChange={handleHeaderCheckboxChange}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageData.map((list) => (
                  <TableRow
                    key={list.user_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {list.user_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className={style.tableInput}>
                        <input
                          type="time"
                          value={formatTime(list.StartDateTime)}
                          onChange={(e) =>
                            handleTimeChange(
                              list.user_id,
                              "StartDateTime",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className={style.tableInput}>
                        <input
                          type="time"
                          value={formatTime(list.EndDateTime)}
                          onChange={(e) =>
                            handleTimeChange(
                              list.user_id,
                              "EndDateTime",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={style.tableSelect}>
                        <input
                          type="checkbox"
                          checked={list.attended}
                          onChange={() => handleCheckboxChange(list.user_id)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={eventUserData?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 30]}
            />
          </Paper>
          <div className={style.attendedButton}>
            <button
              onClick={() => {
                attendanceMark();
              }}
            >
              Save Attendance
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionEventDetail;
