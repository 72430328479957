import React,{useEffect,useState} from 'react'
import style from './Lesson.module.css'
import { useNavigate, useLocation } from "react-router-dom";
import MyEditor from '../Editor/Myeditor';
const Lesson = () => {
  const [updatePopup, setUpdatePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [syllabusName, setSyllabusName] = useState("");
  const [syllabusDescription, setSyllabusDescription] = useState("");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const location=useLocation()
  const state=location.state
  const syllabusID=state.Syllabus_ID
const [syllabusData,setSyllabusData]=useState([])
const navigate=useNavigate()


  async function syllabusGet() {
    await fetch("https://services.1o1learnersclub.com/syllabus/get", {
      method: "POST",
      body: JSON.stringify({
        Syllabus_ID:syllabusID
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSyllabusData(data.data[0])
       
      });
  }

  async function syllabusDelete(e) {
    e.preventDefault()
    await fetch("https://services.1o1learnersclub.com/syllabus/delete", {
      method: "POST",
      body: JSON.stringify({
        Syllabus_ID:syllabusID
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/syllabus",{
            state: { learning:syllabusData.Learning_ID },
          })
        }
       
      });
  }

  async function syllabusUpdate(e) {
    e.preventDefault()
    await fetch("https://services.1o1learnersclub.com/syllabus/update", {
      method: "POST",
      body: JSON.stringify({
        Syllabus_ID :syllabusID,
        Name : syllabusName,
        Description : syllabusDescription
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          navigate("/syllabus",{
            state: { learning:syllabusData.Learning_ID },
          })
        }
      });
  }
useEffect(()=>{
syllabusGet()
},[key])

  return (
    <div className={style.Container}>
{deletePopup ? (
        <>
          <div
            onClick={() => {
              setDeletePopup(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.Popup}>
            <div className={style.popupText}>
              <p>Are you sure you want to Delete?</p>
            </div>
            <div className={style.PopupButton}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                 syllabusDelete(e)
                  setDeletePopup(false);
                }}
              >
                YES
              </button>
              <button
                onClick={() => {
                  setDeletePopup(false);
                }}
              >
                NO
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {updatePopup ? (
        <>
          <div
            onClick={() => {
              setUpdatePopup(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <form action="" onSubmit={syllabusUpdate} >
              <input
                type="text"
                value={syllabusName}
                required
                onChange={(e) => {
                  setSyllabusName(e.target.value);
                }}
                placeholder="Syllabusname"
              />
              <input
                type="text"
                value={syllabusDescription}
                required
                onChange={(e) => {
                  setSyllabusDescription(e.target.value);
                }}
                placeholder="Description"
              />

              <button type="submit">Update</button>
            </form>
          </div>
        </>
      ) : (
        ""
      )}


      <div className={style.Syllabus}>
      <div className={style.Heading}>
        <div className={style.HeaderText}>
          <p>{syllabusData.Name}</p>
        </div>
        <div className={style.HeadButton}>
          <button onClick={()=>{
            setUpdatePopup(true)
          }}>Update</button>
          <button onClick={()=>{
            setDeletePopup(true)
          }}>Delete</button>
        </div>
      </div>
      <div className={style.ListTitle}>
        <p>SubCategory</p>
      </div>
      <div className={style.List}>
        <li>Integer</li>
        <li>Float</li>
      </div>
      </div>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>LessonsList</p>
        </div>
        <div className={style.HeadButton}>
          <button>Create</button>
        </div>
      </div>
      <div className={style.Lessons}>
          <div className={style.LessonHeading}>
            <p>Lesson</p>
            <div className={style.headerButton}>
              <button>Update</button>
              <button>Delete</button>
            </div>
          </div>
          <div className={style.LessonContent}>
            <p>AI can support novices in acquiring the necessary skills and
            knowledge to perform skilled jobs more effectively. Here are a few
            ways in which AI can provide support: Training and Tutorials: AI can
            provide interactive training modules and tutorials that guide
            novices through step-by-step instructions, demonstrations, and
            simulation.</p>
          </div>
          <div className={style.LessonHeading}>
            <p>Revision</p>
            <div className={style.headerButton}>
              <button>Update</button>
              <button>Delete</button>
            </div>
          </div>
          <div className={style.LessonContent}>
          <ol type="1"><li><p>Which are considered as  programming languages?</p></li></ol>
          <div className={style.Checkbox}>
            <input type="checkbox" name="answer"/><label>English</label><br/>
            <input type="checkbox" name="answer"/><label>Python</label><br/>
            <input type="checkbox" name="answer"/><label>Sql</label>
          </div>
          </div>
          
        </div>
          <MyEditor/>
      </div>
  )
}

export default Lesson