import React, { useState, useEffect } from "react";
import style from "./Club.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { baseUrl } from "../Url";
import { HiOutlineViewGrid } from "react-icons/hi";
import Loader from "../Loader/Loader";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../action/User";


const Club = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state;
  const org_id = state?.organization;
  const key = JSON.parse(localStorage.getItem("access-token"));

  const userRole=localStorage.getItem("adminStatus")

  const { profile } = useSelector((state) => state.profileData);

  const [updatePopup, setUpdatePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [clubPopup, setClubPopup] = useState(false);
  const [clubName, setClubName] = useState("");
  const [clubDescription, setClubDescription] = useState("");
  const [clubData, setClubData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [clubId, setClubId] = useState("");
  const [deleteClubId, setDeleteClubId] = useState("");
  const [loading, setLoading] = useState(true);
  const [deleteClub, setDeleteClub] = useState("");
  const[clubCreationFail,setClubCreationFail]=useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const[apiResponse,setApiResponse]=useState(false)
  const[clubCreation,setClubCreation]=useState(false)
  const[clubDeletion,setClubDeletion]=useState(false)
  const[clubUpdation,setClubUpdation]=useState(false)
  const[orgID,setOrgID]=useState('')
  

  const dispatch=useDispatch()

  useEffect(() => {
    const newOrgID = org_id ? org_id : profile?.org_id;
    setOrgID(newOrgID);
  }, [org_id, profile]);

  async function orgGet() {
    await fetch(`${baseUrl}/organization/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrgData(data.data[0]);
      });
  }

  async function clubList() {
    await fetch(`${baseUrl}/club/list`, {
      method: "POST",
      body: JSON.stringify({
        OrganizationID: orgID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClubData(data.data);
        setLoading(false);
      });
  }
  const validateFields = () => {
    if (!clubName) {
      setErrorMessage("Clubname is required");
      return false;
    }
    if (!clubDescription) {
      setErrorMessage("Description is required");
      return false;
    }
    return true;
  };


  async function clubAdd(e) {
    // e.preventDefault();
    if (!validateFields()) {
      setClubCreationFail(true);
      return;
    }
    else{
    await fetch(`${baseUrl}/club/add`, {
      method: "POST",
      body: JSON.stringify({
        OrganizationID: orgID,
        ClubName: clubName,
        Description: clubDescription,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          clubList();
          setClubPopup(false);
          setClubCreation(true)
          setClubName('')
          setClubDescription('')
        }
      });
  }
}
const validateEditFields = () => {
  if (!editName) {
    setErrorMessage("Clubname is required");
    return false;
  }
  if (!editDescription) {
    setErrorMessage("Description is required");
    return false;
  }
  return true;
};
  //club update api

  async function clubUpdate(e) {
    if (!validateEditFields()) {
      setClubCreationFail(true);
      return;
    }
    else{
    await fetch(`${baseUrl}/club/update`, {
      method: "POST",
      body: JSON.stringify({
        ClubID: clubId,
        ClubName: editName,
        Description: editDescription,
        OrganizationID: orgID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          clubList();
          setUpdatePopup(false);
          setClubUpdation(true)
        }
      });
  }
  }
  
  //Club delete api

  async function clubDelete(e) {
    await fetch(`${baseUrl}/club/delete`, {
      method: "POST",
      body: JSON.stringify({
        ClubID: deleteClubId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          clubList();
          setDeletePopup(false);
          setClubDeletion(true)
        }
      });
  }

  useEffect(() => {
   
    if(orgID!==""&& orgID!==undefined){
      console.log(orgID);
      clubList();
      orgGet();
    }
  }, [orgID]);

  // useEffect(()=>{
  //   if(userRole===false || userRole==="false"){
  //     dispatch(UserProfile());
  //   }
  // },[userRole])

  return (
    <div className={style.Container}>
      <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={clubCreation || clubDeletion || clubUpdation}
        autoHideDuration={3000}
        onClose={() => {
          setClubCreation(false);
          setClubDeletion(false);
          setClubUpdation(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {clubCreation
            ? "Club created successfully"
            : clubDeletion
              ? "Club deleted successfully"
              : clubUpdation
                ? "Club updated successfully"
                : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={clubCreationFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setClubCreationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <div className={style.HeaderDiv}>
        <div className={style.Title}>
          <p>{orgData?.org_name}</p>
        </div>
        <div className={style.clubNames}>
          <p>Club List/Batch List</p>
        </div>
        <div className={style.HeaderButton}>
          <button
            onClick={() => {
              setClubPopup(true);
            }}
          >
            Create
          </button>
        </div>
      </div>

      <div className={style.clubSection}>
        {loading ? (
          <Loader />
        ) : (
          <div className={style.Club}>
            {clubData && clubData.length > 0
              ? clubData.map((item) => {
                  return (
                    <div className={style.Cards}>
                      <div className={style.cardTop}>
                        <p>{item.ClubName}</p>
                      </div>
                      <div className={style.cardBottom}>
                        <Tooltip title="View">
                          <IconButton>
                            <HiOutlineViewGrid
                              className={style.viewButton}
                              onClick={() => {
                                navigate("/members", {
                                  state: {
                                    club: item,
                                    Org_id: orgID,
                                  },
                                });
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <IconButton>
                            <BiEdit
                              className={style.editButton}
                              onClick={(e) => {
                                setEditName(item.ClubName);
                                setEditDescription(item.Description);
                                setClubId(item.ClubID);
                                setUpdatePopup(true);
                                e.stopPropagation();
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton>
                            <RiDeleteBinLine
                              className={style.deleteButton}
                              onClick={(e) => {
                                setDeletePopup(true);
                                setDeleteClubId(item.ClubID);
                                e.stopPropagation();
                                setDeleteClub(item.ClubName);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        )}
      </div>

      {clubPopup ? (
        <>
          <div
            onClick={() => {
              setClubPopup(false);
              setClubName('')
          setClubDescription('')
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setClubPopup(false);
                  setClubName('')
          setClubDescription('')
                }}
              />
            </div>
            <div className={style.createSection}>
              <div className={style.clubName}>
                <label htmlFor="">Club Name/Batch Name</label>
                <input
                  type="text"
                  value={clubName}
                  onChange={(e) => {
                    setClubName(e.target.value);
                  }}
                  placeholder=""
                />
              </div>
              <div className={style.description}>
                <label htmlFor="">Description</label>
                <input
                  type="text"
                  value={clubDescription}
                  onChange={(e) => {
                    setClubDescription(e.target.value);
                  }}
                  placeholder=""
                />
              </div>
            </div>
            <div className={style.createButton}>
              <button
                className={style.create}
                onClick={() => {
                  clubAdd();
                }}
              >
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setClubPopup(false);
                  setClubName('')
          setClubDescription('')
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {updatePopup ? (
        <>
          <div
            onClick={() => {
              setUpdatePopup(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setUpdatePopup(false);
                }}
              />
            </div>
            <div className={style.createSection}>
              <div className={style.clubName}>
                <label htmlFor="">Club Name/Batch Name</label>
                <input
                  value={editName}
                  type="text"
                  required
                  onChange={(e) => {
                    setEditName(e.target.value);
                  }}
                  placeholder=""
                />
              </div>
              <div className={style.description}>
                <label htmlFor="">Description</label>
                <input
                  value={editDescription}
                  type="text"
                  required
                  onChange={(e) => {
                    setEditDescription(e.target.value);
                  }}
                  placeholder=""
                />
              </div>
            </div>
            <div className={style.createButton}>
              <button
                className={style.create}
                onClick={() => {
                  clubUpdate();
                }}
              >
                Update
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setUpdatePopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {deletePopup ? (
        <>
          <div
            onClick={() => {
              setDeletePopup(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setDeletePopup(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>
                  Are you sure you want to delete <span>{deleteClub}</span> ?
                </p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.Delete}
                  onClick={() => {
                   
                    clubDelete();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.DeleteCancel}
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Club;
