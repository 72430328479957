import React, { useEffect, useState } from "react";
import style from "./LessonMapping.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import Loader from "../Loader/Loader";

const LessonMapping = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(state, "userDetail");
  const org_id = state.organization;
  const club_id = state.club;
  const userName = state.user;
  const auth_tocken = state.tocken;
  const userId = state.id;

  const [nameDetail, setNameDetail] = useState([]);
  const [lessonDetail, setLessonDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function nameList() {
    await fetch(`${baseUrl}/get/name`, {
      method: "POST",
      body: JSON.stringify({
        org_id: org_id,
        club_id: club_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setNameDetail(data.data);
      });
  }

  async function existingLesson() {
    await fetch(`${baseUrl}/userlearning/details/admin`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLessonDetail(data.data);
        setIsLoading(false)
      });
  }

  useEffect(() => {
    nameList();
    existingLesson();
  }, [org_id]);

  return (
    <div className={style.Container}>
      <div className={style.topHeader}>
        <h3>User Lesson List</h3>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={style.header}>
            <div className={style.header_Orgname}>
              <h4>{nameDetail.org_name}</h4>
            </div>
            <div className={style.header_Clubname}>
              <h4>{nameDetail.club_name}</h4>
            </div>
            <div className={style.header_Username}>
              <h4>{userName}</h4>
            </div>
            <div className={style.Lessonmapping}>
              <button
                onClick={() => {
                  navigate("/single/lesson/mapping", {
                    state: { organization: org_id, club: club_id, user: userId, token: auth_tocken },
                  });
                }}
              >
                Lesson Mapping
              </button>
            </div>
          </div>
          <div className={style.existingCategories}>
            <div className={style.userLesson}>
              <h2>{userName} Lesson List</h2>
            </div>
            <div className={style.categoryGrid}>
              {lessonDetail.length > 0 &&
                lessonDetail.map((lesson) => {
                  return (
                    <div className={style.card}
                      onClick={() => {
                        navigate("/user/lesson", {
                          state: {
                            user: userId, learningId: lesson.learning_id,
                            category: lesson.category_details, goal: lesson.goal, status: lesson.Status,
                            timeline: lesson.timeline, userID: userId
                          }
                        })
                      }}
                    >
                      <div className={style.CardHead}
                      >
                        <p>{lesson.category_details}</p>
                      </div>
                      <div className={style.cardDetail}>
                        <p>Goal: {lesson.goal}</p>
                        <p>Timeline: {lesson.timeline}</p>
                        <p>Status: {lesson.Status}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}

    </div>
  );
};

export default LessonMapping;
