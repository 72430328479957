import React, { useEffect, useRef, useState } from "react";
import style from "./QuizEdit.module.css";
import { baseUrl } from "../Url";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoMdCheckmarkCircleOutline,
  IoMdCloudUpload,
} from "react-icons/io";
import { TbMessageQuestion, TbUnderline } from "react-icons/tb";
import {
  MdAdd,
  MdAddCircleOutline,
  MdDelete,
  MdFormatBold,
  MdFormatItalic,
  MdFormatStrikethrough,
  MdOutlineMic,
  MdOutlineQuestionAnswer,
  MdClose,
} from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import ReactQuill from "react-quill";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

const QuizEdit = () => {
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const quizId = state.quizId;
  const orgId = state.orgId;

  const textFieldRef = useRef(null);

  const [quizListData, setQuizListData] = useState([]);
  const [quizName, setQuizName] = useState("");
  const [quizValue, setQuizValue] = useState("");
  const [content, setContent] = useState("");
  const [blankAnswers, setBlankAnswers] = useState({});
  const [activeButtons, setActiveButtons] = useState({
    bold: false,
    italic: false,
    strikethrough: false,
    underline: false,
  });
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [strikeThrough, setStrikeThrough] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [questionType, setQuestionType] = useState("");
  const [fillTheBlankView, setFillTheBlankView] = useState(false);
  const [questionDeleteView, setQuestionDeleteView] = useState(false);
  const [settingView, setSettingView] = useState(false);
  const [questionValue, setQuestionValue] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [deleteDisable, setDeleteDisable] = useState(false);
  const [multipleChoiceView, setMultipleChoiceView] = useState(false);
  const [ordinaryAnswer, setOrdinaryAnswer] = useState("");
  const [ordinary, setOrdinary] = useState(false);
  const [firstOptionSet, setFirstOptionSet] = useState(["", "", "", ""]);
  const [secondOptionSet, setSecondOptionSet] = useState(["", "", "", ""]);
  const [matchAnswerValues, setMatchAnswerValues] = useState(["", "", "", ""]);
  const [matchValueView, setMatchValueView] = useState(false);
  const [dictationView, setDictationView] = useState(false);
  const [firstOptionSetImage, setFirstOptionSetImage] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [secondOptionSetImageText, setSecondOptionSetImageText] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [imageMatchAnswerValues, setImageMatchAnswerValues] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [matchOptionImageView, setMatchOptionImageView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [uniqueId, setUniqueId] = useState("");
  const [correctMark, setCorrectMark] = useState("");
  const [examTime, setExamTime] = useState("");
  const [inCorrectMark, setInCorrectMark] = useState("");
  const [validation, setValidation] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [editSuccess, setEditSuccess] = useState(false);
  const [points, setPoints] = useState("");
  const [quizTime, setQuizTime] = useState("");
  const[noOfAttempts,setNoOfAttempts]=useState("")
  const[settingsValidation,setSettingsValidation]=useState(false)
  const[crossWordPuzzle,setCrossWordPuzzle]=useState(false)
  const [grid, setGrid] = useState(
    Array.from({ length: 10 }, () =>
      Array.from({ length: 10 }, () => ({ cluevalue: '', clueNumber: null,clueID:'' }))
    )
  );
  const [cluesAcross, setCluesAcross] = useState([{id:'1027277301', number: 1, clue: '', answer: '' }]);
  const [cluesDown, setCluesDown] = useState([{id:'1037101023', number: 1, clue: '', answer: '' }]);
  const [selectingCell, setSelectingCell] = useState(false);
  const [selectedClueIndex, setSelectedClueIndex] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const[mediaCollection,setMediaCollection]=useState([])
  const[mediaPopup,setMediaPopup]=useState(false)
  const [selectedTab, setSelectedTab] = useState(0);
  const[selectedImageOptionIndex,setSelectedImageOptionIndex]=useState(null)


  const stripHtmlTags = (htmlString) => {
    return htmlString.replace(/<[^>]+>/g, "");
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  

  //Fill the blank question handling sections {

  //---Blank Creation

  const handleAddBlankClick = () => {
    let newBlankCount = 1;

    if (Object.keys(blankAnswers).length > 0) {
      const maxBlankNumber = Math.max(
        ...Object.keys(blankAnswers)
          .filter((key) => key.startsWith("[blank "))
          .map((key) => parseInt(key.match(/\d+/)[0]))
      );

      newBlankCount = maxBlankNumber + 1;
    }

    const newBlankName = `[blank ${newBlankCount}]`;

    if (textFieldRef.current) {
      const selectionStart = textFieldRef.current.selectionStart;
      const selectionEnd = textFieldRef.current.selectionEnd;
      const newContent =
        content.substring(0, selectionStart) +
        newBlankName +
        content.substring(selectionEnd);

      setContent(newContent);
      setBlankAnswers((prevAnswers) => ({
        ...prevAnswers,
        [newBlankName]: "",
      }));
    } else {
      setContent((prevQuestion) => `${prevQuestion}${newBlankName}`);
      setBlankAnswers((prevAnswers) => ({
        ...prevAnswers,
        [newBlankName]: "",
      }));
    }
  };

  //Question value formatting

  const handleToggleBold = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));
    setBold(!bold);
  };

  const handleToggleItalic = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));

    setItalic(!italic);
  };

  const handleToggleStrikeThrough = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));

    setStrikeThrough(!strikeThrough);
  };
  const handleToggleUnderline = (button) => {
    setActiveButtons((prev) => ({ ...prev, [button]: !prev[button] }));

    setUnderline(!underline);
  };

  //-----Blank Answer handling

  const handleBlankAnswerChange = (blankName, answer) => {
    setBlankAnswers((prevAnswers) => ({
      ...prevAnswers,
      [blankName]: answer,
    }));
  };

  //---Blank Deletion

  const handleDeleteBlank = (blankName) => {
    const updatedBlankAnswers = { ...blankAnswers };
    delete updatedBlankAnswers[blankName];
    setBlankAnswers(updatedBlankAnswers);

    const updatedContent = content.replace(blankName, "");
    setContent(updatedContent);
  };

  //----Space key clear handling

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      const selectionStart = textFieldRef.current.selectionStart;
      const selectionEnd = textFieldRef.current.selectionEnd;
      Object.keys(blankAnswers).forEach((blankNumber) => {
        const blankPosition = content.indexOf(`[blank ${blankNumber}]`);
        if (
          blankPosition !== -1 &&
          blankPosition === selectionStart &&
          selectionStart === selectionEnd
        ) {
          handleDeleteBlank(blankNumber);
        }
      });
    }
  };

  //---Blur handling

  const handleBlur = () => {
    if (content.trim() === "") {
      setBlankAnswers({});
    }
  };

  //---Assessment Data Creation

  const handleFilltheBlankQuestionCreation = () => {
    // Transform blankAnswers state to match the desired structure
    const transformedAnswer = {};
    Object.keys(blankAnswers).forEach((blankName) => {
      const numericKey = blankName.match(/\d+/);
      if (numericKey) {
        transformedAnswer[numericKey[0]] = blankAnswers[blankName];
      }
    });

    // Create the assessmentData with the transformed answer
    const assessmentData = {
      question: content,
      type: questionType,
      options: [],
      answer: transformedAnswer,
      unique_id: uniqueId,
      point:points,
      no_of_attempts:noOfAttempts,
      quiz_time:quizTime
    };

    console.log(assessmentData);
    if (content === "") {
      setValidationMessage("Question is missing");
      setValidation(true);
    } else if (Object.keys(transformedAnswer).length === 0) {
      setValidationMessage("Please enter the blank value");
      setValidation(true);
    }else if(points==""){
      setValidationMessage("Please enter the points");
      setValidation(true);
    } else if(noOfAttempts==""){
      setValidationMessage("Please enter the count of attempts");
      setValidation(true);
    } else if(quizTime==""){
      setValidationMessage("Please enter the time for the quiz");
      setValidation(true);
    }  else {
      quizQuestionEdit(assessmentData);
    }
  };

  //}

  //Multiple Choice question handling sections{

  //Question change

  const handleMultipleQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----Answer Handling

  const handleMultipleAnsweChange = (index, value) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Check box value handling

  const handleCheckboxChange = (index) => {
    setCorrectAnswers((prevCorrectAnswers) => {
      if (prevCorrectAnswers.includes(index)) {
        return prevCorrectAnswers.filter((i) => i !== index);
      } else {
        // Make sure to store unique indices
        return [...new Set([...prevCorrectAnswers, index])];
      }
    });
  };

  //----Option set creation

  const handleMultipleOptionAdd = () => {
    setOptions((prevOptions) => [...prevOptions, ""]);
  };

  //----Option set deletion

  const handleMultipleOptionDelete = (index) => {
    if (options.length > 2) {
      setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
    } else {
      setDeleteDisable(true);
    }
  };

  //---Assessment Data Creation

  const handleMulitipleQuestionCreation = () => {
    const assessmentData = {
      question: questionValue,
      type: questionType,
      options: options,
      answer: correctAnswers.map((index) => options[index]),
      unique_id: uniqueId,
      point:points,
      no_of_attempts:noOfAttempts,
      quiz_time:quizTime
    };
    console.log(assessmentData);
    if (questionValue === "") {
      setValidationMessage("Question is missing");
      setValidation(true);
    } else if (options.length === 0) {
      setValidationMessage("Options is missing");
      setValidation(true);
    } else if (correctAnswers.length === 0) {
      setValidationMessage("Answer list is missing");
      setValidation(true);
    } else if(points==""){
      setValidationMessage("Please enter the points");
      setValidation(true);
    } else if(noOfAttempts==""){
      setValidationMessage("Please enter the count of attempts");
      setValidation(true);
    } else if(quizTime==""){
      setValidationMessage("Please enter the time for the quiz");
      setValidation(true);
    } else {
      quizQuestionEdit(assessmentData);
    }
  };

  //}

  //Ordinary question handling sections{

  //Question change

  const handleOrdinaryQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //---Assessment Data Creation

  const handleOrdinaryQuestionCreation = () => {
    const assessmentData = {
      question: questionValue,
      type: questionType,
      options: [],
      answer: ordinaryAnswer,
      unique_id: uniqueId,
      point:points,
      no_of_attempts:noOfAttempts,
      quiz_time:quizTime
    };

    console.log(assessmentData);
    if (questionValue === "") {
      setValidationMessage("Question is missing");
      setValidation(true);
    } else if (ordinaryAnswer.length === 0) {
      setValidationMessage("Answer is missing");
      setValidation(true);
    } else if(points==""){
      setValidationMessage("Please enter the points");
      setValidation(true);
    } else if(noOfAttempts==""){
      setValidationMessage("Please enter the count of attempts");
      setValidation(true);
    } else if(quizTime==""){
      setValidationMessage("Please enter the time for the quiz");
      setValidation(true);
    } else {
      quizQuestionEdit(assessmentData);
    }
  };

  // }

  //Match the following question handling sections{

  //Question change

  const handleMatchQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----First Option Set handling

  const handleFirstOptionSet = (index, value) => {
    setFirstOptionSet((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //-----Second Option Set handling

  const handleSecondOptionSet = (index, value) => {
    setSecondOptionSet((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Answer set handling

  const handleMatchAnswerKey = (index, value) => {
    setMatchAnswerValues((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Options Add

  const handleMatchOptionAdd = (type) => {
    if (type === "first") {
      setFirstOptionSet((prevOptions) => [...prevOptions, ""]);
    } else if (type === "second") {
      setSecondOptionSet((prevOptions) => [...prevOptions, ""]);
    }
  };

  //---Option Deletion

  const handleDeleteMatchOption = (index, type) => {
    const minOptionSetLength = firstOptionSet.length;

    if (
      (type === "first" && minOptionSetLength > 2) ||
      (type === "second" && secondOptionSet.length > minOptionSetLength)
    ) {
      if (type === "first") {
        setFirstOptionSet((prevOptions) =>
          prevOptions.filter((_, i) => i !== index)
        );
      } else if (type === "second") {
        setSecondOptionSet((prevOptions) =>
          prevOptions.filter((_, i) => i !== index).slice(0, minOptionSetLength)
        );
      }
    }
  };

  //----Answer Add

  const handleAddMatchAnswer = () => {
    if (matchAnswerValues.length < firstOptionSet.length) {
      setMatchAnswerValues((prevOptions) => [...prevOptions, ""]);
    } else {
      console.log("Maximum number of answers reached");
    }
  };
  //---Answer Deletion

  const handleDeleteMatchAnswer = (index) => {
    const minAnswerLength = firstOptionSet.length;

    if (matchAnswerValues.length > minAnswerLength) {
      setMatchAnswerValues((prevOptions) =>
        prevOptions.filter((_, i) => i !== index)
      );
    }
  };

  //---Assessment Data Creation

  const handleMatchQuestionCreation = () => {
    const assessmentData = {
      left: firstOptionSet,
      question: questionValue,
      type: questionType,
      right: secondOptionSet,
      options: [],
      answer: matchAnswerValues,
      unique_id: uniqueId,
      point:points,
      no_of_attempts:noOfAttempts,
      quiz_time:quizTime
    };
    console.log(assessmentData);
    if (questionValue.trim() === "") {
      setValidationMessage("Question is missing");
      setValidation(true);
    } else if (firstOptionSet.every((option) => option.trim() === "")) {
      setValidationMessage("First option set is missing");
      setValidation(true);
    } else if (secondOptionSet.every((option) => option.trim() === "")) {
      setValidationMessage("Second option set is missing");
      setValidation(true);
    } else if (matchAnswerValues.every((answer) => answer.trim() === "")) {
      setValidationMessage("Answer list is missing");
      setValidation(true);
    } else if(points==""){
      setValidationMessage("Please enter the points");
      setValidation(true);
    } else if(noOfAttempts==""){
      setValidationMessage("Please enter the count of attempts");
      setValidation(true);
    } else if(quizTime==""){
      setValidationMessage("Please enter the time for the quiz");
      setValidation(true);
    } else {
      quizQuestionEdit(assessmentData);
    }
  };

  //dictation
  const handleDictaionQuestionChange = (html) => {
    setQuestionValue(html);
  };

  const [recordings, setRecordings] = useState([
    { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
  ]);

  const handleAddRecording = () => {
    setRecordings([
      ...recordings,
      { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
    ]);
  };

  const handleDeleteRecording = (index) => {
    const updatedRecordings = [...recordings];
    updatedRecordings.splice(index, 1);
    setRecordings(updatedRecordings);
  };

  const handleAnswerChange = (index, answer) => {
    const updatedRecordings = [...recordings];
    updatedRecordings[index].answer = answer;
    setRecordings(updatedRecordings);
  };

  const toggleRecording = (index) => {
    const updatedRecordings = [...recordings];
    updatedRecordings[index].isRecording = !recordings[index].isRecording;
    setRecordings(updatedRecordings);
  };

  const handleStartStopRecording = async (index) => {
    const isRecording = recordings[index].isRecording;
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const mediaRecorder = new MediaRecorder(stream);
        const chunks = [];

        mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        mediaRecorder.onstop = async () => {
          const blob = new Blob(chunks, { type: "audio/wav" });

          // Send the audio blob to the backend
          const formData = new FormData();
          formData.append("audio", blob);
          try {
            const response = await fetch(`${baseUrl}/voice/upload/new`, {
              method: "POST",
              body: formData,
            });
            if (!response.ok) {
              throw new Error("Failed to upload audio");
            }
            const responseData = await response.json();
            const audioUrl = responseData.data[0].url;

            // Update the recordings state with the received audio URL
            const updatedRecordings = [...recordings];
            updatedRecordings[index].audio = audioUrl;
            updatedRecordings[index].mediaRecorder = null;
            setRecordings(updatedRecordings);
          } catch (error) {
            console.error("Error uploading audio:", error);
          }
        };

        mediaRecorder.start();
        toggleRecording(index);
        const updatedRecordings = [...recordings];
        updatedRecordings[index].mediaRecorder = mediaRecorder;
        setRecordings(updatedRecordings);
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    } else {
      const mediaRecorder = recordings[index].mediaRecorder;
      mediaRecorder.stop();
      toggleRecording(index);
    }
  };

  const handleDictationQuestionClear = () => {
    setRecordings([
      { audio: null, mediaRecorder: null, isRecording: false, answer: "" },
    ]);
    setQuestionValue("");
  };

  const handleDictationQuestionCreation = () => {
    const assessmentData = {
      left: recordings.map((recording) => recording.audio),
      question: questionValue,
      type: questionType,
      right: [],
      options: [],
      answer: recordings.map((recording) => recording.answer),
      unique_id: uniqueId,
      point:points,
      no_of_attempts:noOfAttempts,
      quiz_time:quizTime
    };
    console.log(assessmentData);
    if (questionValue.trim() === "") {
      setValidationMessage("Question is missing");
      setValidation(true);
    } else if (recordings.some((recording) => !recording.audio)) {
      setValidationMessage("Audio is missing");
      setValidation(true);
    } else if (recordings.some((recording) => !recording.answer)) {
      setValidationMessage("Answer is missing");
      setValidation(true);
    } else if(points==""){
      setValidationMessage("Please enter the points");
      setValidation(true);
    } else if(noOfAttempts==""){
      setValidationMessage("Please enter the count of attempts");
      setValidation(true);
    } else if(quizTime==""){
      setValidationMessage("Please enter the time for the quiz");
      setValidation(true);
    } else {
      quizQuestionEdit(assessmentData);
    }
  };

  //match the following with image

  //Match the following question handling sections{

  const handleImageMatchQuestionChange = (html) => {
    setQuestionValue(html);
  };

  //----First Option Set handling

  const handleImageFirstOptionSet = async (index, file) => {
    if(typeof file === 'string'){
      setFirstOptionSetImage((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[index] = file;
        return updatedImages;
      });
    }else{
    const formData = new FormData();
    formData.append("audio", file);

    try {
      const response = await fetch(`${baseUrl}/voice/upload/new`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json(); // Assuming the API returns the URL in JSON format
        const imageUrl = responseData.data[0].url;
        setFirstOptionSetImage((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index] = imageUrl;
          return updatedImages;
        });
      } else {
        // Handle error response from the API
        console.error("Failed to upload image", response);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error uploading image:", error);
    }
  }
  };

  const handleImageReplace = (index) => {
    const input = document.getElementById(`fileInput-${index}`);
    input.click();
  };

  const handleReplaceImage = (index) => {
    // Trigger the file input click event
    // document.getElementById(`fileInput_${index}`).click();
    setSelectedImageOptionIndex(index)
  setMediaPopup(true)
  filesGet()
  };
  //-----Second Option Set handling

  const handleImageSecondOptionSet = (index, value) => {
    setSecondOptionSetImageText((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Answer set handling

  const handleImageMatchAnswerKey = (index, value) => {
    setImageMatchAnswerValues((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  //----Options Add

  const handleImageMatchOptionAdd = (type) => {
    if (type === "first") {
      setFirstOptionSetImage((prevOptions) => [...prevOptions, ""]);
    } else if (type === "second") {
      setSecondOptionSetImageText((prevOptions) => [...prevOptions, ""]);
    }
  };

  //---Option Deletion

  const handleDeleteImageMatchOption = (index, type) => {
    const minOptionSetLength = firstOptionSetImage.length;

    if (
      (type === "first" && minOptionSetLength > 2) ||
      (type === "second" &&
        secondOptionSetImageText.length > minOptionSetLength)
    ) {
      if (type === "first") {
        setFirstOptionSetImage((prevOptions) =>
          prevOptions.filter((_, i) => i !== index)
        );
      } else if (type === "second") {
        setSecondOptionSetImageText((prevOptions) =>
          prevOptions.filter((_, i) => i !== index).slice(0, minOptionSetLength)
        );
      }
    }
  };

  //----Answer Add

  const handleAddImageMatchAnswer = () => {
    if (imageMatchAnswerValues.length < firstOptionSetImage.length) {
      setImageMatchAnswerValues((prevOptions) => [...prevOptions, ""]);
    } else {
      console.log("Maximum number of answers reached");
    }
  };

  //---Answer Deletion

  const handleDeleteImageMatchAnswer = (index) => {
    const minAnswerLength = firstOptionSetImage.length;

    if (imageMatchAnswerValues.length > minAnswerLength) {
      setImageMatchAnswerValues((prevOptions) =>
        prevOptions.filter((_, i) => i !== index)
      );
    }
  };

  //clear section

  const handlematchImageQuestionClear = () => {
    setFirstOptionSetImage(["", "", "", ""]);
    setSecondOptionSetImageText(["", "", "", ""]);
    setImageMatchAnswerValues(["", "", "", ""]);
    setQuestionValue("");
  };

  //---Assessment Data Creation

  const handleImageMatchQuestionCreation = () => {
    const assessmentData = {
      left: firstOptionSetImage,
      question: questionValue,
      type: questionType,
      right: secondOptionSetImageText,
      options: [],
      answer: imageMatchAnswerValues,
      unique_id: uniqueId,
      point:points,
      no_of_attempts:noOfAttempts,
      quiz_time:quizTime
    };

    const jsonData = JSON.stringify(assessmentData);
    console.log(jsonData);
    console.log(assessmentData);
    if (questionValue.trim() === "") {
      setValidationMessage("Question is missing");
      setValidation(true);
    } else if (firstOptionSetImage.every((option) => option.trim() === "")) {
      setValidationMessage("First option set is missing");
      setValidation(true);
    } else if (
      secondOptionSetImageText.every((option) => option.trim() === "")
    ) {
      setValidationMessage("Second option set is missing");
      setValidation(true);
    } else if (imageMatchAnswerValues.every((answer) => answer.trim() === "")) {
      setValidationMessage("Answer list is missing");
      setValidation(true);
    }else if(points==""){
      setValidationMessage("Please enter the points");
      setValidation(true);
    } else if(noOfAttempts==""){
      setValidationMessage("Please enter the count of attempts");
      setValidation(true);
    } else if(quizTime==""){
      setValidationMessage("Please enter the time for the quiz");
      setValidation(true);
    }  else {
      quizQuestionEdit(assessmentData);
    }
  };

  //crossword puzzle
//Question Handling

const handleCrossWordPuzzleQuestionChange = (html) => {
  setQuestionValue(html);
};

//----Answer Handling

const handleCrossWordPuzzleinputChange = (e, row, col) => {
  const newGrid = [...grid];
  newGrid[row][col] = {
    ...newGrid[row][col],  // Preserve existing properties
    cluevalue: e.target.value  // Update the cell value
  };
  setGrid(newGrid);
};



//clear section

const handleCrossWordPuzzleQuestionClear = () => {
  setQuestionValue("");
  setOptions(["", "", "", ""]);
  setCorrectAnswers([]);
};


const generateUniqueId = () => {
  const timestamp = new Date().getTime(); // Get current timestamp
  const randomId = Math.random().toString(36).substring(2, 15); // Generate random number
  return `${timestamp}-${randomId}`; // Combine timestamp and random number
};

const addClue = (direction) => {
  const newNumber = direction === 'across' ? cluesAcross.length + 1 : cluesDown.length + 1;
  const newId = generateUniqueId(); // Generate a unique ID for the clue
  const newClue = { id: newId, number: newNumber, clue: '', answer: '' };
  if (direction === 'across') {
    setCluesAcross([...cluesAcross, newClue]);
  } else if (direction === 'down') {
    setCluesDown([...cluesDown, newClue]);
  }
};



const handleClueChange = (e, index, direction) => {
  const updatedClues = direction === 'across' ? [...cluesAcross] : [...cluesDown];
  updatedClues[index].clue = e.target.value;
  if (direction === 'across') {
    setCluesAcross(updatedClues);
  } else if (direction === 'down') {
    setCluesDown(updatedClues);
  }
};




// Function to handle click on the button to start selecting a cell for the clue
const startSelectingCell = (index, direction,id) => {
  const clues = direction === 'across' ? cluesAcross : cluesDown;
  const clueObj = clues[index];

  // Check if the clue and its answer are not empty
  if (!clueObj.clue || !clueObj.answer) {
    // Show error message and prevent selecting the starting cell
    setValidationMessage('Please enter both clue and answer before selecting the starting cell.');
    setValidation(true);
    return;
  }

  setSelectingCell(true);
  setSelectedClueIndex(index);
  setSelectedDirection(direction);
};

// Function to handle click on the crossword grid cells
const handleCellClick = (rowIndex, colIndex) => {
  const prevClueId = selectedDirection === 'across' ? cluesAcross[selectedClueIndex]?.id : cluesDown[selectedClueIndex]?.id;
  const prevClueNumber = selectedDirection === 'across' ? cluesAcross[selectedClueIndex]?.number : cluesDown[selectedClueIndex]?.number;

  // Clear the cells associated with the previous selection
  if (prevClueId && prevClueNumber && selectedDirection) {
    const updatedGrid = [...grid];
    updatedGrid.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        if (cell.clueID === prevClueId ) {
          updatedGrid[rowIndex][colIndex] = { cluevalue: '', clueNumber: null, clueID: '' };
        }
      });
    });
    setGrid(updatedGrid);
  }
  if (selectingCell && selectedClueIndex !== null && selectedDirection !== null) {
    const updatedGrid = [...grid];
    const clueObj = selectedDirection === 'across' ? cluesAcross[selectedClueIndex] : cluesDown[selectedClueIndex];
    const answerLength = clueObj.answer.length;
    let remainingCells;

    // Calculate the remaining cells based on the direction
    if (selectedDirection === 'across') {
      remainingCells = updatedGrid[0].length - rowIndex - 1; // Calculate remaining cells to the right
    } else {
      remainingCells = updatedGrid[0].length - colIndex - 1; // Calculate remaining cells below
    }

    // Check if there's enough space to display the entire answer
    if (answerLength > remainingCells) {
      // Set validation state and error message
      setValidation(true);
      setValidationMessage('Not enough space to display the entire answer');
      return; // Exit the function
    }

    // Update the grid to mark the starting cell for the clue's answer
    if (selectedDirection === 'across') {
      for (let i = 0; i < answerLength; i++) {
        if (rowIndex + i < updatedGrid.length) {
          updatedGrid[rowIndex + i][colIndex] = {
            cluevalue: clueObj.answer[i].toUpperCase(),
            clueNumber: i === 0 ? clueObj.number : null , // Show clue number only in the starting cell
            clueID:clueObj.id||""
          };
        }
      }
    } else {
      for (let i = 0; i < answerLength; i++) {
        if (colIndex + i < updatedGrid[rowIndex].length) {
          updatedGrid[rowIndex][colIndex + i] = {
            cluevalue: clueObj.answer[i].toUpperCase(),
            clueNumber: i === 0 ? clueObj.number : null , // Show clue number only in the starting cell
            clueID: clueObj.id ||""
          };
        }
      }
    }

    // Update the state with the modified grid and reset the selection flags
    setGrid(updatedGrid);
    setSelectingCell(false);
    setSelectedClueIndex(null);
    setSelectedDirection(null);
  }
};



const handleClueAnswerChange = (e, index, direction) => {
  const updatedClues = direction === 'across' ? [...cluesAcross] : [...cluesDown];
  const newAnswer = e.target.value;

  // Update the clue's answer
  updatedClues[index].answer = newAnswer;
  if (direction === 'across') {
    setCluesAcross(updatedClues);
  } else if (direction === 'down') {
    setCluesDown(updatedClues);
  }

  // Update the grid cells corresponding to the clue's answer
  const updatedGrid = [...grid];
  const clueObj = direction === 'across' ? cluesAcross[index] : cluesDown[index];
  const answerLength = newAnswer.length;

  // Find the starting cell for the clue's answer
  updatedGrid.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      if (
        (direction === 'across' && cell.clueNumber === clueObj.number&&cell.clueID===clueObj.id) ||
        (direction === 'down' && cell.clueNumber === clueObj.number &&cell.clueID===clueObj.id && rowIndex >= rowIndex)
      ) {
        // Clear the cells affected by the removal
        for (let i = 0; i < row.length; i++) {
          const targetRowIndex = direction === 'down' ? rowIndex : rowIndex+ i;
          const targetColIndex = direction === 'across' ? colIndex  : colIndex + i;
          if (
            updatedGrid[targetRowIndex] &&
            updatedGrid[targetRowIndex][targetColIndex] &&
            (direction === 'across' ? i >= answerLength : i >= answerLength && rowIndex + i >= rowIndex)
          ) {
            updatedGrid[targetRowIndex][targetColIndex].cluevalue = '';
          }
        }

        // Update the remaining cells with the adjusted answer
        for (let i = 0; i < answerLength; i++) {
          const targetRowIndex = direction === 'down' ? rowIndex : rowIndex+ i ;
          const targetColIndex = direction === 'across' ? colIndex  : colIndex + i;
          if (updatedGrid[targetRowIndex] && updatedGrid[targetRowIndex][targetColIndex]) {
            updatedGrid[targetRowIndex][targetColIndex].cluevalue = newAnswer[i].toUpperCase();
          }
        }
      }
    });
  });

  // Update the grid state with the modified grid
  setGrid(updatedGrid);
};






//---Assessment Data Creation

const handleCrossWordPuzzleQuestionCreation = () => {
  const asessmentData = [
    {
      question: questionValue,
      type: questionType,
      options: grid,
      answer: "",
      left:cluesAcross,
      right:cluesDown,
      point:points,
      no_of_attempts:noOfAttempts,
      quiz_time:quizTime
    },
  ];
  console.log(asessmentData);
  const hasClue = grid.some(row => row.some(cell => cell.clueNumber !== null || cell.clueID !== ''));
  if (questionValue === "") {
    setValidationMessage("Question is missing");
    setValidation(true);
  }else if (cluesAcross.every(clue => clue.clue === '') && cluesDown.every(clue => clue.clue === '')) {
    setValidationMessage("Please enter at least one clue for either across or down direction.");
    setValidation(true);
    return;
  }else if (!hasClue) {
    setValidationMessage("Please assign at least one answer to a cell.");
    setValidation(true);
    return;
  }else if(points==""){
    setValidationMessage("Please enter the points");
    setValidation(true);
  } else if(noOfAttempts==""){
    setValidationMessage("Please enter the count of attempts");
    setValidation(true);
  } else if(quizTime==""){
    setValidationMessage("Please enter the time for the quiz");
    setValidation(true);
  }  else {
    quizQuestionEdit(asessmentData);
  }
};


  //}

  async function quizQuestionList() {
    await fetch(`${baseUrl}/quiz/individual/list`, {
      method: "POST",
      body: JSON.stringify({
        quiz_id: quizId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setQuizListData(data.data[0].questions);
        setQuizName(data.data[0].quiz_name);
        setQuizValue(data.data[0].questions[0]);
      });
  }

  async function quizQuestionEdit(data) {
    await fetch(`${baseUrl}/quiz/question/edit`, {
      method: "POST",
      body: JSON.stringify({
        quiz_id: quizId,
        quiz: data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setEditSuccess(true);
        } else {
          setValidation(true);
          setValidationMessage(data.description);
        }
      });
  }

  async function filesGet() {
    await fetch(`${baseUrl}/gallery/file/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMediaCollection(data.data)
      });
  }

  useEffect(() => {
    if (quizValue.type === "fill in the blanks") {
      setContent(quizValue.question);

      // Extract blank names from the question
      const blankNames = quizValue.question.match(/\[blank \d+\]/g) || [];

      const initialBlankAnswers = {};
      blankNames.forEach((blankName) => {
        const numericKey = blankName.match(/\d+/);
        if (numericKey) {
          const apiAnswerKey = numericKey[0];
          initialBlankAnswers[blankName] = quizValue.answer[apiAnswerKey] || "";
        }
      });

      // Set the state with the initial values
      setBlankAnswers(initialBlankAnswers);
      setLoading(false);

      setQuestionType(quizValue.type);
      setUniqueId(quizValue.unique_id);
      setCorrectMark(quizValue.mark);
      setExamTime(quizValue.time);
      setInCorrectMark(quizValue.negative_mark);
      setMultipleChoiceView(false);
      setCrossWordPuzzle(false)
      setOrdinary(false);
      setMatchValueView(false);
      setDictationView(false);
      setFillTheBlankView(true);
      setMatchOptionImageView(false);
      setNoOfAttempts(quizValue.no_of_attempts)
      setPoints(quizValue.point)
      setQuizTime(quizValue.quiz_time)
      console.log(quizValue.question);
    } else if (
      quizValue.type === "multiple choices" ||
      quizValue.type === "multiple choice"
    ) {
      if (quizValue.generation === "admin") {
        setLoading(false);
        setFillTheBlankView(false);
        setOrdinary(false);
        setMatchValueView(false);
        setDictationView(false);
        setMultipleChoiceView(true);
        setMatchOptionImageView(false);
        setCrossWordPuzzle(false)
        setOptions(quizValue.options);
        setQuestionValue(quizValue.question);
        setQuestionType(quizValue.type);
        setUniqueId(quizValue.unique_id);
        setCorrectMark(quizValue.mark);
        setExamTime(quizValue.time);
        setInCorrectMark(quizValue.negative_mark);
        setNoOfAttempts(quizValue.no_of_attempts)
      setPoints(quizValue.point)
      setQuizTime(quizValue.quiz_time)

        if (quizValue.answer !== "") {
          setCorrectAnswers(
            quizValue.answer.map((answer) => {
              return quizValue.options.findIndex((option) => option === answer);
            })
          );
        }
      } else {
        setLoading(false);
        setFillTheBlankView(false);
        setOrdinary(false);
        setMatchValueView(false);
        setDictationView(false);
        setMultipleChoiceView(true);
        setMatchOptionImageView(false);
        setCrossWordPuzzle(false)
        setOptions(quizValue.options);
        setQuestionValue(quizValue.question);
        setQuestionType(quizValue.type);
        setUniqueId(quizValue.unique_id);
        setNoOfAttempts(quizValue.no_of_attempts)
      setPoints(quizValue.point)
      setQuizTime(quizValue.quiz_time)
      }
    } else if (quizValue.type === "ordinary") {
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setMatchValueView(false);
      setDictationView(false);
      setOrdinary(true);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false)
      setQuestionValue(quizValue.question);
      setQuestionType(quizValue.type);
      setOrdinaryAnswer(quizValue.answer);
      setUniqueId(quizValue.unique_id);
      setCorrectMark(quizValue.mark);
      setExamTime(quizValue.time);
      setInCorrectMark(quizValue.negative_mark);
      setNoOfAttempts(quizValue.no_of_attempts)
      setPoints(quizValue.point)
      setQuizTime(quizValue.quiz_time)
    } else if (quizValue.type === "Match the following") {
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setOrdinary(false);
      setMatchValueView(true);
      setDictationView(false);
      setMatchOptionImageView(false);
      setCrossWordPuzzle(false)
      setQuestionValue(quizValue.question);
      setFirstOptionSet(quizValue.left);
      setSecondOptionSet(quizValue.right);
      setMatchAnswerValues(quizValue.answer);
      setQuestionType(quizValue.type);
      setUniqueId(quizValue.unique_id);
      setCorrectMark(quizValue.mark);
      setExamTime(quizValue.time);
      setInCorrectMark(quizValue.negative_mark);
      setNoOfAttempts(quizValue.no_of_attempts)
      setPoints(quizValue.point)
      setQuizTime(quizValue.quiz_time)
    } else if (quizValue.type === "dictation") {
      const { answer, left } = quizValue;

      // Create an array of recordings with initial values from API response
      const initialRecordings = left.map((audio, index) => ({
        audio,
        answer: answer[index] || "", // Use empty string if answer is not provided in API response
        mediaRecorder: null,
        isRecording: false,
      }));

      // Set the recordings state with the initial values
      setRecordings(initialRecordings);
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setOrdinary(false);
      setMatchValueView(false);
      setDictationView(true);
      setCrossWordPuzzle(false)
      setMatchOptionImageView(false);
      setQuestionValue(quizValue.question);
      setQuestionType(quizValue.type);
      setUniqueId(quizValue.unique_id);
      setCorrectMark(quizValue.mark);
      setExamTime(quizValue.time);
      setInCorrectMark(quizValue.negative_mark);
      setNoOfAttempts(quizValue.no_of_attempts)
      setPoints(quizValue.point)
      setQuizTime(quizValue.quiz_time)
    } else if (quizValue.type === "Match the following with image") {
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setOrdinary(false);
      setMatchValueView(false);
      setDictationView(false);
      setMatchOptionImageView(true);
      setCrossWordPuzzle(false)
      setQuestionValue(quizValue.question);
      setFirstOptionSetImage(quizValue.left);
      setSecondOptionSetImageText(quizValue.right);
      setImageMatchAnswerValues(quizValue.answer);
      setQuestionType(quizValue.type);
      setUniqueId(quizValue.unique_id);
      setCorrectMark(quizValue.mark);
      setExamTime(quizValue.time);
      setInCorrectMark(quizValue.negative_mark);
      setNoOfAttempts(quizValue.no_of_attempts)
      setPoints(quizValue.point)
      setQuizTime(quizValue.quiz_time)
    }
    else if (quizValue.type ==="cross word puzzle") {
      setLoading(false);
      setFillTheBlankView(false);
      setMultipleChoiceView(false);
      setOrdinary(false);
      setMatchValueView(false);
      setDictationView(false)
      setMatchOptionImageView(false)
      setCrossWordPuzzle(true)
      setQuestionValue(quizValue.question);
      setCluesAcross(quizValue.left);
      setCluesDown(quizValue.right);
      setGrid(quizValue.options)
      setMatchAnswerValues(quizValue.answer);
      setQuestionType(quizValue.type);
      setUniqueId(quizValue.unique_id);
      setNoOfAttempts(quizValue.no_of_attempts)
      setPoints(quizValue.point)
      setQuizTime(quizValue.quiz_time)
      
    }
    console.log(quizValue);
  }, [quizValue]);

  useEffect(() => {
    if (quizId) {
      quizQuestionList();
    }
  }, [quizId]);

  useEffect(() => {
    if (quizValue.type === "ordinary") {
    }
  }, [quizValue]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={editSuccess}
        onClose={() => {
          setEditSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          Quiz edited successfully
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validation}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidation(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          {validationMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={settingsValidation}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setSettingsValidation(false);
        }}
      >
        <Alert
          variant="filled"
          severity="info"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          {questionValue===""?"Settings added please create a question"
           :"Please save question"}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.headerText}>
          <h5>Edit your quiz</h5>
        </div>
      </div>
      <div className={style.quizSection}>
        <div className={style.quizEditSection}>
          {fillTheBlankView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButton}>
                    <div className={style.questionDeleteButton}>
                      <Tooltip title="Assessment Delete">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          sx={{ fontSize: "2rem" }}
                        >
                          <MdDelete
                            onClick={() => {
                              setQuestionDeleteView(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={style.creationTopRight}>
                      <Button
                        variant="contained"
                        endIcon={<MdAdd />}
                        color="secondary"
                        onClick={handleAddBlankClick}
                      >
                        Add Blank
                      </Button>
                    </div>

                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>

                    <div className={style.questionCreateButton}>
                      <Tooltip title="Assessment Update">
                        <IconButton>
                          <IoMdCheckmarkCircleOutline
                            onClick={handleFilltheBlankQuestionCreation}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottomBlank}>
                  <div className={style.formatButtons}>
                    <Button
                      onClick={() => {
                        handleToggleBold("bold");
                      }}
                      style={{
                        background: activeButtons.bold
                          ? "#e6e6e6"
                          : "transparent",
                        color: "#2e2e40",
                        fontSize: "1.5rem",
                        padding: "0.25rem",
                        minWidth: " 3rem",
                        margin: "0 0.25rem",
                      }}
                    >
                      <MdFormatBold />
                    </Button>
                    <Button
                      onClick={() => {
                        handleToggleItalic("italic");
                      }}
                      style={{
                        background: activeButtons.italic
                          ? "#e6e6e6"
                          : "transparent",
                        color: "#2e2e40",
                        fontSize: "1.5rem",
                        padding: "0.25rem",
                        minWidth: " 3rem",
                        margin: "0 0.25rem",
                      }}
                    >
                      <MdFormatItalic />
                    </Button>
                    <Button
                      onClick={() => {
                        handleToggleStrikeThrough("strikethrough");
                      }}
                      style={{
                        background: activeButtons.strikethrough
                          ? "#e6e6e6"
                          : "transparent",
                        color: "#2e2e40",
                        fontSize: "1.5rem",
                        padding: "0.25rem",
                        minWidth: " 3rem",
                        margin: "0 0.25rem",
                      }}
                    >
                      <MdFormatStrikethrough />
                    </Button>
                    <Button
                      onClick={() => {
                        handleToggleUnderline("underline");
                      }}
                      style={{
                        background: activeButtons.underline
                          ? "#e6e6e6"
                          : "transparent",
                        color: "#2e2e40",
                        fontSize: "1.5rem",
                        padding: "0.25rem",
                        minWidth: " 3rem",
                        margin: "0 0.25rem",
                      }}
                    >
                      <TbUnderline />
                    </Button>
                  </div>
                  <div className={style.textArea}>
                    <TextField
                      inputRef={textFieldRef}
                      sx={{ width: "100%" }}
                      multiline
                      rows={6}
                      variant="outlined"
                      onChange={(e) => {
                        setContent(e.target.value);
                      }}
                      value={content}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                      InputProps={{
                        style: {
                          fontWeight: bold ? "bold" : "normal",
                          fontStyle: italic ? "italic" : "normal",
                          textDecoration: `${
                            strikeThrough ? "line-through" : ""
                          } ${underline ? "underline" : ""}`,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your blank values</p>
                  <p>(To remove the blanks use the delete button)</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottomLeft}>
                  {Object.keys(blankAnswers).map((blankName) => {
                    // Extract the numeric key from the blank name

                    return (
                      <div key={blankName} className={style.blankValues}>
                        <p>{blankName}:</p>
                        <TextField
                          sx={{ width: "75%", margin: "0 1rem" }}
                          variant="outlined"
                          value={blankAnswers[blankName]}
                          onChange={(e) =>
                            handleBlankAnswerChange(blankName, e.target.value)
                          }
                        />
                        <IconButton
                          disabled={Object.keys(blankAnswers).length <= 1}
                          aria-label="delete"
                          color="error"
                          onClick={() => handleDeleteBlank(blankName)}
                        >
                          <MdDelete />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {multipleChoiceView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButton}>
                    <div className={style.questionDeleteButton}>
                      <Tooltip title="Assessment Delete">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          sx={{ fontSize: "2rem" }}
                        >
                          <MdDelete
                            onClick={() => {
                              setQuestionDeleteView(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionCreateButton}>
                      <Tooltip title="Assessment Update">
                        <IconButton>
                          <IoMdCheckmarkCircleOutline
                            onClick={handleMulitipleQuestionCreation}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    theme="snow"
                    value={questionValue}
                    onChange={handleMultipleQuestionChange}
                    style={{ width: "100%", height: "100%" }}
                  />
                  {/* <TextField
                              sx={{ width: "100%" }}
                              id="outlined-basic"
                              multiline
                              rows={6}
                              variant="outlined"
                              onChange={(e) => {
                                setQuestionValue(e.target.value);
                              }}
                              value={questionValue}
                            /> */}
                </div>
              </div>

              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your options</p>
                  <p>(Please check the box against the correct answer )</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottom}>
                  <div className={style.optionBottomLeft}>
                    {options.map((option, index) => (
                      <div key={index} className={style.optionItem}>
                        <TextField
                          sx={{ width: "95%" }}
                          required
                          variant="filled"
                          value={option}
                          onChange={(e) =>
                            handleMultipleAnsweChange(index, e.target.value)
                          }
                        />
                        <Checkbox
                          checked={correctAnswers.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                          disabled={options[index] === ""}
                        />
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handleMultipleOptionDelete(index)}
                          disabled={deleteDisable}
                        >
                          <MdDelete />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <div className={style.firstOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={handleMultipleOptionAdd}
                    >
                      Add Options
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {ordinary ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButton}>
                    <div className={style.questionDeleteButton}>
                      <Tooltip title="Assessment Delete">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          sx={{ fontSize: "2rem" }}
                        >
                          <MdDelete
                            onClick={() => {
                              setQuestionDeleteView(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionCreateButton}>
                      <Tooltip title="Assessment Update">
                        <IconButton>
                          <IoMdCheckmarkCircleOutline
                            onClick={handleOrdinaryQuestionCreation}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    theme="snow"
                    value={questionValue}
                    onChange={handleOrdinaryQuestionChange}
                    style={{ width: "100%", height: "100%" }}
                  />
                  {/* <TextField
                              sx={{ width: "100%" }}
                              id="outlined-basic"
                              multiline
                              rows={6}
                              variant="outlined"
                              onChange={(e) => {
                                setQuestionValue(e.target.value);
                              }}
                              value={questionValue}
                            /> */}
                </div>
              </div>
              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your answer</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottomLeft}>
                  <TextField
                    sx={{ width: "85%", margin: " 1rem" }}
                    variant="outlined"
                    value={ordinaryAnswer}
                    onChange={(e) => {
                      // Append the entered answer to the array
                      setOrdinaryAnswer(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {matchValueView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>

                  <div className={style.actionButton}>
                    <div className={style.questionDeleteButton}>
                      <Tooltip title="Assessment Delete">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          sx={{ fontSize: "2rem" }}
                        >
                          <MdDelete
                            onClick={() => {
                              setQuestionDeleteView(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>

                    <div className={style.questionCreateButton}>
                      <Tooltip title="Assessment Update">
                        <IconButton>
                          <IoMdCheckmarkCircleOutline
                            onClick={handleMatchQuestionCreation}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className={style.creationBottom}>
                  <ReactQuill
                    theme="snow"
                    value={questionValue}
                    onChange={handleMatchQuestionChange}
                    style={{ width: "100%", height: "100%" }}
                  />
                  {/* <TextField
                              sx={{ width: "100%" }}
                              id="outlined-basic"
                              multiline
                              rows={5}
                              variant="outlined"
                              onChange={(e) => {
                                setQuestionValue(e.target.value);
                              }}
                              value={questionValue}
                            /> */}
                </div>
              </div>
              <div className={style.mainOptionSet}>
                <div className={style.firstOptionSet}>
                  <div className={style.firstOptionTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your first option set</p>
                  </div>
                  {firstOptionSet.map((first, index) => (
                    <div className={style.firstOptionSetValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={first}
                        onChange={(e) =>
                          handleFirstOptionSet(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteMatchOption(index, "first")}
                        disabled={firstOptionSet.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.firstOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={() => handleMatchOptionAdd("first")}
                    >
                      Add first Options
                    </Button>
                  </div>
                </div>
                <div className={style.secondOptionSet}>
                  <div className={style.secondOptionTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your second option set</p>
                  </div>
                  {secondOptionSet.map((second, index) => (
                    <div className={style.secondOptionSetValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={second}
                        onChange={(e) =>
                          handleSecondOptionSet(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteMatchOption(index, "second")}
                        disabled={secondOptionSet.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.secondOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={() => handleMatchOptionAdd("second")}
                    >
                      Add second Options
                    </Button>
                  </div>
                </div>
                <div className={style.matchAnswerKey}>
                  <div className={style.matchAnswerTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your answer Keys</p>
                  </div>
                  {matchAnswerValues.map((answer, index) => (
                    <div className={style.matchAnswerValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={answer}
                        onChange={(e) =>
                          handleMatchAnswerKey(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteMatchAnswer(index)}
                        disabled={matchAnswerValues.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.answerKeyAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={handleAddMatchAnswer}
                    >
                      Add Answer
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {dictationView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButton}>
                    <div className={style.questionDeleteButton}>
                      <Tooltip title="Assessment Delete">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          sx={{ fontSize: "2rem" }}
                        >
                          <MdDelete
                            onClick={() => {
                              setQuestionDeleteView(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>

                    <div className={style.questionCreateButton}>
                      <Tooltip title="Assessment Update">
                        <IconButton>
                          <IoMdCheckmarkCircleOutline
                            onClick={handleDictationQuestionCreation}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    theme="snow"
                    value={questionValue}
                    onChange={handleDictaionQuestionChange}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </div>

              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your Question</p>
                  <p>(Please enter answer against the audio)</p>
                  <span>*</span>
                </div>
                <div className={style.optionBottom}>
                  {recordings.map((recording, index) => (
                    <div className={style.dictationValues}>
                      <div className={style.optionBottomDictation}>
                        <Button
                          startIcon={<MdOutlineMic />}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleStartStopRecording(index)}
                        >
                          {recording.isRecording
                            ? "Stop Recording"
                            : "Start Recording"}
                        </Button>
                        {recording.audio && (
                          <audio controls>
                            <source src={recording.audio} type="audio/wav" />
                            Your browser does not support the audio element.
                          </audio>
                        )}

                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handleDeleteRecording(index)}
                          disabled={recordings.length <= 1}
                        >
                          <MdDelete />
                        </IconButton>
                      </div>

                      <div className={style.MicSection}>
                        <TextField
                          sx={{
                            width: "75%",
                          }}
                          label="Enter Answer"
                          value={recording.answer}
                          onChange={(e) =>
                            handleAnswerChange(index, e.target.value)
                          }
                        />
                      </div>
                    </div>
                  ))}
                  <div className={style.firstOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={handleAddRecording}
                    >
                      Add Options
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* Match The Following Questions with image */}

          {matchOptionImageView ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButton}>
                    <div className={style.questionDeleteButton}>
                      <Tooltip title="Assessment Delete">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          sx={{ fontSize: "2rem" }}
                        >
                          <MdDelete
                            onClick={() => {
                              setQuestionDeleteView(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>

                    <div className={style.questionCreateButton}>
                      <Tooltip title="Assessment Update">
                        <IconButton>
                          <IoMdCheckmarkCircleOutline
                            onClick={handleImageMatchQuestionCreation}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    theme="snow"
                    value={questionValue}
                    onChange={handleImageMatchQuestionChange}
                    style={{ width: "100%", height: "100%" }}
                  />
                  {/* <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    multiline
                    rows={5}
                    variant="outlined"
                    onChange={(e) => {
                      setQuestionValue(e.target.value);
                    }}
                    value={questionValue}
                  /> */}
                </div>
              </div>
              <div className={style.mainOptionSet}>
                <div className={style.firstOptionSet}>
                  <div className={style.firstOptionTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your first option image set</p>
                  </div>
                  {firstOptionSetImage.map((first, index) => (
                    <div className={style.firstOptionSetValues} key={index}>
                      {first ? (
                        <div onClick={() => handleReplaceImage(index)}>
                          <img
                            src={first}
                            alt={`Option ${index + 1}`}
                            style={{
                              width: "100px",
                              height: "100px",
                            }}
                          />
                          <input
                            id={`fileInput_${index}`}
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }} // Hide the input element
                            onChange={(e) =>
                              handleImageFirstOptionSet(
                                index,
                                e.target.files[0]
                              )
                            }
                          />
                        </div>
                      ) : (
                        <div className={style.ImageInput}>
                          <>
                            <TextField
                              onClick={() => handleReplaceImage(index)} // Trigger the file input when the text field is clicked
                              placeholder="Choose file"
                              sx={{ width: "95%" }}
                              variant="filled"
                              InputProps={{
                                readOnly: true, // Make the text field read-only to prevent manual editing
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton edge="end">
                                      <IoMdCloudUpload />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <input
                              type="file"
                              id={`fileInput_${index}`}
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleImageFirstOptionSet(
                                  index,
                                  e.target.files[0]
                                )
                              }
                            />
                          </>
                        </div>
                      )}
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() =>
                          handleDeleteImageMatchOption(index, "first")
                        }
                        disabled={firstOptionSetImage.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.firstOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={() => handleImageMatchOptionAdd("first")}
                    >
                      Add first Options
                    </Button>
                  </div>
                </div>
                <div className={style.secondOptionSet}>
                  <div className={style.secondOptionTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your second option set</p>
                  </div>
                  {secondOptionSetImageText.map((second, index) => (
                    <div className={style.secondOptionSetValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={second}
                        onChange={(e) =>
                          handleImageSecondOptionSet(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() =>
                          handleDeleteImageMatchOption(index, "second")
                        }
                        disabled={secondOptionSetImageText.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.secondOptionAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={() => handleImageMatchOptionAdd("second")}
                    >
                      Add second Options
                    </Button>
                  </div>
                </div>
                <div className={style.matchAnswerKey}>
                  <div className={style.matchAnswerTop}>
                    <MdOutlineQuestionAnswer />
                    <p>Enter your answer Keys</p>
                  </div>
                  {imageMatchAnswerValues.map((answer, index) => (
                    <div className={style.matchAnswerValues} key={index}>
                      <TextField
                        sx={{ width: "95%" }}
                        required
                        variant="filled"
                        value={answer}
                        onChange={(e) =>
                          handleImageMatchAnswerKey(index, e.target.value)
                        }
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteImageMatchAnswer(index)}
                        disabled={imageMatchAnswerValues.length <= 2}
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  ))}
                  <div className={style.answerKeyAdd}>
                    <Button
                      variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary"
                      onClick={handleAddImageMatchAnswer}
                    >
                      Add Answer
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
           {/* cross word puzzle */}
           {crossWordPuzzle ? (
            <div className={style.fillQuestionSection}>
              <div className={style.questionCreation}>
                <div className={style.creationTop}>
                  <div className={style.creationTopLeft}>
                    <TbMessageQuestion />
                    <p>Enter your question?</p>
                    <span>*</span>
                  </div>
                  <div className={style.actionButton}>
                  <div className={style.questionDeleteButton}>
                                <Tooltip title="Assessment Delete">
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    sx={{ fontSize: "2rem" }}
                                  >
                                    <MdDelete
                                      onClick={() => {
                                        setQuestionDeleteView(true);
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                    <div className={style.settingsIcon}>
                      <IconButton>
                        <IoSettingsOutline
                          onClick={() => {
                            setSettingView(true);
                          }}
                        />
                      </IconButton>
                    </div>
                    <div className={style.questionCreateButton}>
                                <Tooltip title="Assessment Update">
                                  <IconButton>
                                    <IoMdCheckmarkCircleOutline
                                      onClick={handleCrossWordPuzzleQuestionCreation}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                  </div>
                </div>
                <div className={style.creationBottom}>
                  <ReactQuill
                    theme="snow"
                    value={questionValue}
                    onChange={handleCrossWordPuzzleQuestionChange}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </div>

              <div className={style.optionSet}>
                <div className={style.optionTop}>
                  <MdOutlineQuestionAnswer />
                  <p>Enter your clues and answer and click the button Select Starting Cell to add in the cells </p>
                  <span>*</span>
                </div>
                <div className={style.optionBottom}>
                  <div className={style.crosswordcontainer}>
                  <div className={style.crosswordpuzzle}>
                  {grid.map((row, rowIndex) => (
                    <div key={rowIndex} className={style.row}>
                      {row.map((cell, colIndex) => (
                        <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                          <input
                            type="text"
                            maxLength="1"
                            className={style.cell}
                            value={cell.cluevalue}
                            readOnly
                            onClick={() => handleCellClick(rowIndex, colIndex)}
                            // onChange={(e) => handleCrossWordPuzzleinputChange(e, rowIndex, colIndex)}
                          />
                          {cell.clueNumber && (  // Display clue number only if it exists
                            <div className={style.clueNumber}>{cell.clueNumber}</div>
                          )}
                        </div>
                      ))}
                    </div>
                    ))}
                </div>
                <div className={style.PuzzleQuestion}>
                  <div className={style.QuestionAcross}>
                  <h4>Across</h4>
                  {cluesAcross.map((clueObj, index) => (
                    <div key={index} className={style.QuestionAcrossInputContainer}>
                      <span>{clueObj.number}. </span>
                      <input type="text" placeholder="Enter your clue" value={clueObj.clue} onChange={(e) => handleClueChange(e, index, 'across')} />
                      <input type="text" placeholder="Enter your answer" value={clueObj.answer} onChange={(e) => handleClueAnswerChange(e, index, 'across')} />
                      <Button sx={{width:"fit-content",margin:"4px 0"}} variant="contained"  onClick={() => startSelectingCell(index, 'across',clueObj.id)}>Select Starting Cell</Button>
                    </div>
                  ))}
                  <Button sx={{width:"fit-content"}} variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary" onClick={() => addClue('across')}>Add Across Clue</Button>
                  </div>
                  <div className={style.QuestionDown}>
                  <h4>Down</h4>
                  {cluesDown.map((clueObj, index) => (
                      <div key={index} className={style.QuestionDownInputContainer}>
                        <span>{clueObj.number}. </span>
                        <input type="text" placeholder="Enter your clue" value={clueObj.clue} onChange={(e) => handleClueChange(e, index, 'down')} />
                        <input type="text" placeholder="Enter your answer" value={clueObj.answer} onChange={(e) => handleClueAnswerChange(e, index, 'down')} />
                        <Button sx={{width:"fit-content",margin:"4px 0"}} variant="contained" onClick={() => startSelectingCell(index, 'down',clueObj.id)}>Select Starting Cell</Button>
                      </div>
                    ))}
                    <Button sx={{width:"fit-content"}} variant="contained"
                      endIcon={<MdAddCircleOutline />}
                      color="secondary" onClick={() => addClue('down')}>Add Down Clue</Button>
                    </div>
                </div>
                  </div>
                  
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={style.quizNumberList}>
        <Box sx={{ width: "100%", flexGrow: 1 }}>
  <SimpleTreeView
    aria-label="file system navigator"
    sx={{
      height: 600,
      flexGrow: 1,
      maxWidth: 400,
      overflowY: "auto",
    }}
  >
    <TreeItem
      sx={{ padding: "0.5rem" }}
      itemId="1" // Change nodeId to itemId
      label={<span style={{ fontSize: "1.25rem" }}>{quizName}</span>}
    >
      {quizListData &&
        quizListData.length > 0 &&
        quizListData.map((question, index) => (
          <TreeItem
            sx={{ margin: "0.5rem 0" }}
            key={question.id}
            itemId={`question-${index + 2}`} // Change nodeId to itemId
            label={
              <span
                style={{
                  fontSize: "1.25rem",
                  textTransform: "lowercase",
                }}
              >
                {`${index + 1}. ${stripHtmlTags(
                  question.question
                ).substr(0, 20)}... (${
                  question.type.length > 10
                    ? question.type.substring(0, 10) + "..."
                    : question.type
                })`}
              </span>
            }
            onClick={() => {
              setQuizValue(question);
            }}
          />
        ))}
    </TreeItem>
  </SimpleTreeView>
</Box>


        </div>
      </div>
      {settingView ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.settingsListing}>
            <div className={style.settingHeader}>
              <div className={style.headerSettingText}>
                <h5>Add your settings</h5>
              </div>

              <div className={style.settingClose}>
                <MdClose
                  onClick={() => {
                    setSettingView(false)
                  }}
                />
              </div>
            </div>

            <div className={style.settingSection}>
              
                <div className={style.mainSection}>
                <label htmlFor="">Points</label>
                  <input
                    type="text"
                    name="points"
                    id="points"
                    required
                    placeholder="Points"
                    value={points}
                    onChange={(e) => {
                      setPoints(e.target.value);
                    }}
                  />

                </div>
                <div className={style.mainSection}>
                <label htmlFor="">Time</label>
                  <input
                    type="text"
                    name="Time"
                    id="Time"
                    required
                    placeholder="Time"
                    value={quizTime}
                    onChange={(e) => {
                      setQuizTime(e.target.value);
                    }}
                  />
                </div>
              
              <div className={style.mainSection}>
              <label htmlFor="">No of attempts</label>
                  <input
                    type="text"
                    name="count"
                    id="count"
                    required
                    placeholder="No of attempts"
                    value={noOfAttempts}
                    onChange={(e) => {
                      setNoOfAttempts(e.target.value);
                    }}
                  />
              </div>
            </div>
            <div className={style.settingButton}>
              <button className={style.add} onClick={() => {
                 setSettingsValidation(true)
                 setSettingView(false)
                }}>Add</button>
              <button
                className={style.cancel}
                onClick={() => {
                  setSettingView(false)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {mediaPopup?
      <>
      <div className={style.Overlay}>
      </div>
      <div className={style.MediaPopup}>
            <div className={style.MediaPopupHeader}>
              <div className={style.MediaPopupHeaderText}>
                <h5>Attachment Details</h5>
              </div>

              <div className={style.MediaPopupHeaderClose}>
                <MdClose
                  onClick={() => {
                    setMediaPopup(false)
                  }}
                />
              </div>
            </div>
            <div className={style.MediaPopupSection}>
            <div className={style.tabSection}>
            <Tabs
              TabIndicatorProps={{ style: { backgroundColor: "#83b516" } }}
              value={selectedTab}
              onChange={handleTabChange}
              centered
            >
              <Tab
                label="Images"
                sx={{
                  "&.Mui-selected": {
                    color: "#3e61a3",
                    backgroundColor: "",
                  },
                  fontSize: "1rem",
                  color: "#1a1e5d",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
              />
              <Tab
                label="Input"
                sx={{
                  "&.Mui-selected": {
                    color: "#3e61a3",
                    backgroundColor: "",
                  },
                  fontSize: "1rem",
                  color: "#1a1e5d",
                  backgroundColor: "",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
               
              />
              
            </Tabs>
          </div>
          <Typography>
            {selectedTab===0&&(
              <div className={style.ImageGRid}>
                {mediaCollection&&mediaCollection.length>0?mediaCollection.map((item,index)=>{
              return(
              <div key={index} onClick={()=>{
                handleImageFirstOptionSet(selectedImageOptionIndex,item.file_url)
                setMediaPopup(false)
              }} className={style.Card}>
              <img src={item.file_url}/>
            </div>
              )
            }):''}
              </div>
            )}
            {selectedTab===1&&(
              <div className={style.MediaPopupInputTab}>
                <button onClick={()=>{
                  const input = document.getElementById(`fileInput_${selectedImageOptionIndex}`);
                  input.click();
                  setMediaPopup(false)
                }} >Select File</button>
              </div>
            )}
          </Typography>
              
            </div>
          </div>
      </>

      :''}


    </div>
  );
};

export default QuizEdit;
