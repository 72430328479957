import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar/Sidebar'
const Layout = ({ logoutValue, setLogoutValue }) => {
  return (
    <>
    <Sidebar logoutValue={logoutValue} setLogoutValue={setLogoutValue}/>
    <Outlet/>
    </>
  )
}

export default Layout