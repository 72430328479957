import React, { useEffect, useState } from "react";
import style from "./Member.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { AiOutlineClose } from "react-icons/ai";
import { BsPersonFillAdd } from "react-icons/bs";
import { MdKeyboardArrowDown, MdOutlineLibraryBooks } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiLockOpenAlt } from "react-icons/bi";
import { baseUrl } from "../Url";
import { useDispatch, useSelector } from "react-redux";
import { RoleGet } from "../../action/User";
import { ClubGet } from "../../action/List";
import Pagination from "../Pagination/Pagination";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { PiPasswordBold } from "react-icons/pi";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { IoCalendarNumberOutline } from "react-icons/io5";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Loader from "../Loader/Loader";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { HiOutlineViewGrid } from "react-icons/hi";

const Member = () => {
  const [showMember, setShowMember] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [editFirstName, setEditFirstName] = useState("");
  const [editSecondName, setEditSecondName] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [editRoleName, setEditRoleName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMessage, setpasswordMessage] = useState("");
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [editPhone, setEditPhone] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [clubData, setClubData] = useState([]);
  const [memberDelete, setMemberDelete] = useState(false);
  const [memberID, setMemberId] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryPopup, setSubCategoryPopup] = useState(false);
  const [goalShow, setGoalShow] = useState(false);
  const [goalList, setGoalList] = useState([]);
  const [goal, setGoal] = useState("");
  const [month, setMonth] = useState("");
  const [comment, setComment] = useState("");
  const [lastCategoryId, setLastCategoryId] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [topicShow, setTopicShow] = useState(false);
  const [mainCategoryId, setMainCategoryId] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [clubSelectShow, setClubSelectShow] = useState(false);
  const [EditClubSelectShow, setEditClubSelectShow] = useState(false);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [userId, setUserId] = useState("");
  const [editClubs, setEditClubs] = useState([]);
  const [itemID, setItemID] = useState("");
  const [view, setView] = useState(false);
  const [events, setEvents] = useState([]);
  const [popup, setPopup] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [titleSecond, setTitleSecond] = useState("");
  const [calendarItem, setCalendarItem] = useState({});
  const [descriptionSecond, setDescriptionSecond] = useState("");
  const [startDateSecond, setStartDateSecond] = useState("");
  const [startTimeSecond, setStartTimeSecond] = useState("");
  const [endDateSecond, setEndDateSecond] = useState("");
  const [endTimeSecond, setEndTimeSecond] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [edit, setEdit] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [passwordId, setPasswordId] = useState("");
  const [conditionPopup, SetConditionPopup] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [deleteMemberName, setDeleteMemberName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [subCategoryShow, setSubCategoryShow] = useState(false);
  const [topicListShow, setTopicListShow] = useState(false);
  const [goalListShow, setGoalListShow] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [divide, setDivide] = useState(false);
  const [memberCreationFail, setMemberCreationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiResponse, setApiResponse] = useState(false);
  const [passwordCondition, setPasswordCondition] = useState(false);
  const [userCreation, setUserCreation] = useState(false);
  const [userDeletion, setUserDeletion] = useState(false);
  const [userUpdation, setUserUpdation] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [courseMapShow, setCourseMapShow] = useState(false);
  const [mainCategoryShow, setMainCategoryShow] = useState(false);
  const [mappedLessonList, setMappedLessonList] = useState([]);
  const [lessonMappingSuccess, setLessonMappingSuccess] = useState(false);
  const [learningId, setLearningId] = useState("");
  const [mappedLearningDelete, setMappedLearningDelete] = useState(false);
  const [courseDeleteSuccess, setCourseDeleteSuccess] = useState(false);
  const [courseMapError, setCourseMapError] = useState(false);
  const [courseMapErrorMessage, setCourseMapErrorMessage] = useState("");
  const [countryName, setCountryName] = useState("");
  const [editCountryName, setEditCountryName] = useState("");
  const countryData = [
    "Australia",
    "Canada",
    "India",
    "United Kingdom",
    "United States",
  ];






  const handleCategoryClick = (category) => {
    setSelectedCategories([...selectedCategories, category]);
    const categoryId = category.id;
    console.log("Selected Category ID:", categoryId);
  };

  const handleBreadcrumbClick = (id, index) => {
    // Slice the array to include only elements up to (but not including) the clicked index
    setSelectedCategories(selectedCategories.slice(0, index));
    setSubCategoryShow(true);
    subcategorySecondGet(id);
    console.log("Clicked Breadcrumb Category ID:", id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const clubid = state.club.ClubID;
  console.log(clubid);
  const org_id = state.Org_id;

  const key = JSON.parse(localStorage.getItem("access-token"));

  const userRole = localStorage.getItem("adminStatus");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const { roles } = useSelector((state) => state.roleData);
  console.log(roles);
  const { clubs } = useSelector((state) => state.clubData);
  console.log(clubs);

  const itemsPerPage = 12; // Adjust as needed
  const users = memberList;
  const totalUsers = users.length;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayUsers = users.slice(startIndex, endIndex);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setShowEditPassword();
  };
  const togglePassword = () => {
    setShowEditPassword(!showEditPassword);
  };

  const toggleSelectedClub = (clubID) => {
    setSelectedClubs((prevSelectedClubs) => {
      // Ensure that prevSelectedClubs is always an array
      const selectedClubsArray = Array.isArray(prevSelectedClubs)
        ? prevSelectedClubs
        : [prevSelectedClubs];

      if (selectedClubsArray.includes(clubID)) {
        // If the club is already selected, remove it
        return selectedClubsArray.filter((id) => id !== clubID);
      } else {
        // If the club is not selected, add it
        return [...selectedClubsArray, clubID];
      }
    });
  };
  const handleCheckboxChange = (clubID) => {
    const newSelectedClubs = selectedClubs.includes(clubID)
      ? selectedClubs.filter((id) => id !== clubID)
      : [...selectedClubs, clubID];

    setSelectedClubs(newSelectedClubs);
  };
  const handleClub = (clubID) => {
    const newSelectedClubs = editClubs.includes(clubID)
      ? editClubs.filter((id) => id !== clubID)
      : [...editClubs, clubID];

    setEditClubs(newSelectedClubs);
  };

  const handleEventClick = (info) => {
    const clickedItemID = info.event.extendedProps.CalendarItemID;
    setItemID(clickedItemID);
    setView(true);
    sheduleIndvidual(clickedItemID);
  };

  const handleDateClick = (arg) => {
    const clickedDate = new Date(arg.date);
    const currentDate = new Date();
    const endDate = new Date(arg.view.currentEnd - 1);
    currentDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    if (clickedDate >= currentDate && clickedDate <= endDate) {
      setPopup(true);
      setStartDate(arg.dateStr);
    } else {
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = new Date(e.target.value);
    const selectedStartDate = startDate !== "" ? new Date(startDate) : null;
    if (selectedStartDate && selectedEndDate < selectedStartDate) {
      console.error("End date cannot be less than start date");
      e.target.value = "";
      setEndDate("");
    } else {
      // Update the state with the selected end date because it's valid.
      setEndDate(e.target.value);
    }
  };

  const handlestartTimeChange = (e) => {
    const selectedStartTime = e.target.value;
    const currentDateTime = new Date();
    const selectedDateTime = new Date(`${startDate}T${selectedStartTime}`);

    if (selectedDateTime <= currentDateTime) {
      setStartTime("");
    } else {
      setStartTime(selectedStartTime);
    }
  };

  const handleEndTimeChange = (e) => {
    const selectedEndTime = e.target.value;
    if (startDate === endDate && selectedEndTime <= startTime) {
      setEndTime("");
    } else {
      setEndTime(selectedEndTime);
    }
  };

  const handleStartDateSecondChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    if (selectedDate < currentDate) {
      console.error("Selected date is in the past");
      e.target.value = "";
      setStartDateSecond("");
    } else {
      setStartDateSecond(e.target.value);
    }
  };

  const handleEndDateSecondChange = (e) => {
    const selectedEndDate = new Date(e.target.value);
    const selectedStartDate =
      startDateSecond !== "" ? new Date(startDateSecond) : null;
    // Check if there is a valid start date and if the selected end date is less than the start date.
    if (selectedStartDate && selectedEndDate < selectedStartDate) {
      // Display an error message or take appropriate action.
      console.error("End date cannot be less than start date");
      // Reset the input value:
      e.target.value = "";
      setEndDateSecond(""); // This clears the input field.
    } else {
      // Update the state with the selected end date because it's valid.
      setEndDateSecond(e.target.value);
    }
  };

  const handleStartTimeSecondChange = (e) => {
    const selectedStartTime = e.target.value;
    const currentDateTime = new Date();
    const selectedDateTime = new Date(
      `${startDateSecond}T${selectedStartTime}`
    );
    if (selectedDateTime <= currentDateTime) {
      setStartTimeSecond("");
    } else {
      setStartTimeSecond(selectedStartTime);
    }
  };

  const handleEndTimeSecondChange = (e) => {
    const selectedEndTime = e.target.value;
    if (
      startDateSecond === endDateSecond &&
      selectedEndTime <= startTimeSecond
    ) {
      setEndTimeSecond("");
    } else {
      setEndTimeSecond(selectedEndTime);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const startDateTime = new Date(`${startDate}T${startTime}`);
    const endDateTime = new Date(`${endDate}T${endTime}`);
    if (title && startDateTime && endDateTime) {
      const newEvent = {
        title,
        description,
        start: startDateTime.toISOString(),
        end: endDateTime.toISOString(),
      };
      setEvents([...events, newEvent]);
      Shedule(newEvent);
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const startDateTime = new Date(`${startDateSecond}T${startTimeSecond}`);
    const endDateTime = new Date(`${endDateSecond}T${endTimeSecond}`);
    if (titleSecond && startDateTime && endDateTime) {
      const newEvent = {
        titleSecond,
        descriptionSecond,
        start: startDateTime,
        end: endDateTime,
      };
      sheduleUpdate(newEvent);
      console.log(newEvent);
    }
  };

  const handleEditorChangeSecond = (event, editor) => {
    const data = editor.getData();
    setDescriptionSecond(data);
  };

  const handlePasswordChange = (e) => {
    setEditPassword(e.target.value);
    setpasswordMessage("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setpasswordMessage("");
  };

  const handleUpdateClick = () => {
    if (editPassword === confirmPassword) {
      passReset(confirmPassword);
      console.log("Passwords match. Make API request here.");
    } else {
      setpasswordMessage("Passwords do not match");
      setPasswordCondition(true);
    }
  };

  const handleCancelClick = () => {
    // Reset the form or close the popup
    setEditPassword("");
    setConfirmPassword("");
    setpasswordMessage("");
    setShowPasswordPopup(false);
  };

  //club get api

  async function clubGet(e) {
    // e.preventDefault();
    await fetch(`${baseUrl}/club/get`, {
      method: "POST",
      body: JSON.stringify({
        ClubID: clubid,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClubData(data.data[0]);
        setSelectedClubs([data.data[0].ClubID]);
      });
  }

  //member list getting api

  async function allMember() {
    await fetch(`${baseUrl}/user/get/by/org/club`, {
      method: "POST",
      body: JSON.stringify({
        org_id: org_id,
        club_id: clubid,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMemberList(data.data);
        setLoading(false);
      });
  }
  const validateFields = () => {
    if (!userName) {
      setErrorMessage("Username is required");
      return false;
    }
    if (!password) {
      setErrorMessage("Password is required");
      return false;
    } else {
      const passwordRegex =
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})(?!\s).*$/;
      if (!passwordRegex.test(password.trim())) {
        setErrorMessage("Password does not match criteria");
        return false;
      }
    }
    if (!phone) {
      setErrorMessage("Phone number is required");
      return false;
    } else {
      const numericPhoneRegex = /^[0-9]+$/;
      if (!numericPhoneRegex.test(phone.trim())) {
        setErrorMessage("Phone must contain only numbers");
        return false;
      }
      if (phone.trim().length !== 10) {
        setErrorMessage("Phone number must contain 10 digits");
        return false;
      }
    }
    if (!email) {
      setErrorMessage("Email is required");
      return false;
    } else {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRegex.test(email.trim())) {
        setErrorMessage("Invalid email format");
        return false;
      }
    }
    if (selectedRoleId === "") {
      setErrorMessage("Role is required");
      return false;
    }
    if (selectedClubs.length === 0) {
      setErrorMessage("Select at least one club");
      return false;
    }
    return true;
  };

  //member add

  async function memberAdd(e) {
    console.log("function Called");
    const bodyData = {
      FirstName: firstName,
      MiddleName: "",
      LastName: secondName,
      Nickname: "",
      Username: userName,
      Password: password,
      DOB: "",
      Gender: "",
      ContactNumber: phone,
      Email: email,
      CommunicationAddress: "",
      PermanentAddress: "",
      BillingAddress: "",
      MemberRoles: selectedRoleId,
      CurrentAddress: "",
      SignupSource: "admin",
      org_id: org_id,
      role: selectedRoleName,
      club_id: Array.isArray(selectedClubs) ? selectedClubs : [selectedClubs],
      country: countryName,

    };
    // const isFormValid = validateForm();

    // if (isFormValid) {
    if (!validateFields()) {
      setMemberCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/account/signup/by/admin`, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success") {
            setShowMember(false);
            allMember();
            setApiError("");
            setErrors({});
            setUserCreation(true);
          } else {
            setApiError(data.message);
            setApiResponse(true);
          }
        });
      // }
    }
  }

  const validateEditFields = () => {
    if (!editUserName) {
      setErrorMessage("Username is required");
      return false;
    }
    if (!editPhone) {
      setErrorMessage("Phone number is required");
      return false;
    } else {
      const numericPhoneRegex = /^[0-9]+$/;
      if (!numericPhoneRegex.test(editPhone.trim())) {
        setErrorMessage("Phone must contain only numbers");
        return false;
      }
      if (editPhone.trim().length !== 10) {
        setErrorMessage("Phone number must contain 10 digits");
        return false;
      }
    }
    if (!editEmail) {
      setErrorMessage("Email is required");
      return false;
    } else {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRegex.test(editEmail.trim())) {
        setErrorMessage("Invalid email format");
        return false;
      }
    }
    if (selectedRoleId === "") {
      setErrorMessage("Role is required");
      return false;
    }
    if (editClubs.length === 0) {
      setErrorMessage("Select at least one club");
      return false;
    }

    return true;
  };

  //member edit

  async function memberEdit(e) {
    const bodyData = {
      user_id: userId,
      FirstName: editFirstName,
      MiddleName: "",
      LastName: editSecondName,
      Nickname: "",
      Username: editUserName,
      DOB: "",
      Gender: "",
      ContactNumber: editPhone,
      country:editCountryName,
      Email: editEmail,
      CommunicationAddress: "",
      PermanentAddress: "",
      BillingAddress: "",
      MemberRoles: selectedRoleId,
      CurrentAddress: "",
      SignupSource: "admin",
      org_id: org_id,
      role: selectedRoleName,
      club_id: editClubs,
    };
    if (!validateEditFields()) {
      setMemberCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/account/update/by/admin`, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success") {
            setUpdatePopup(false);
            allMember();
            setUserUpdation(true);
          } else {
            setApiError(data.message);
            setApiResponse(true);
          }
        });
    }
  }
  //member deleting api

  async function DeleteMember() {
    await fetch(`${baseUrl}/user/delete/by/admin`, {
      method: "POST",
      body: JSON.stringify({
        user_id: memberID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setMemberDelete(false);
          allMember();
          setUserDeletion(true);
        }
      });
  }

  //main categories get api

  async function categoryGet() {
    await fetch(
      `${baseUrl}/main/categories/get/new
    `,
      {
        method: "POST",
        body: JSON.stringify({
          Organization_ID: org_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCategoryList(data.data);
      });
  }

  //sub categories get

  async function subcategoryGet(category_Id) {
    await fetch(`${baseUrl}/sub/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: org_id,
        id: category_Id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        setSubCategoryList(data.data);
        // if (data.category === true) {
        //   setSubCategoryList(data.data);
        //   setGoalShow(false);
        // } else {
        //   setSubCategoryShow(false);
        //   goalGet();
        //   setGoalShow(true);
        //   setLastCategoryId(category_Id);
        //   TopicGet();
        // }
      });
  }

  //sub categories get

  async function subcategorySecondGet(subid) {
    await fetch(`${baseUrl}/sub/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: org_id,
        id: subid,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSubCategoryList(data.data);
        // if (data.category === true) {
        //   setSubCategoryList(data.data);
        //   setGoalShow(false);
        // } else {
        //   setSubCategoryShow(false);
        //   goalGet();
        //   setGoalShow(true);
        //   setLastCategoryId(subid);
        //   TopicGet();
        // }
      });
  }
  //goal get

  async function goalGet() {
    await fetch(`${baseUrl}/goal/list`, {
      method: "POST",
      body: JSON.stringify({
        source: "web",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setGoalList(data.data);
      });
  }

  //topic get

  async function TopicGet() {
    await fetch(`${baseUrl}/topic/get`, {
      method: "POST",
      body: JSON.stringify({
        category_id: categoryValue === 0 ? mainCategoryId : lastCategoryId,
        org_id: org_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setTopicList(data.data);
        } else {
          setApiResponse(true)
          setApiError(data.description)
        }

      });
  }

  const saveData = {
    Organization_ID: org_id,
    club_id: clubid,
    Goal: [
      {
        goal: "upSkill",
        time_line: "3",
        comments: "",
      },
    ],
    Topics: topicList,
    Categories: [
      {
        first_level: mainCategoryId,
        last_level: categoryValue === 0 ? mainCategoryId : lastCategoryId,
      },
    ],
  };

  //learnings save

  async function learningSave() {
    await fetch(`${baseUrl}/clublearning/saving`, {
      method: "POST",
      body: JSON.stringify(saveData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLessonMappingSuccess(true);
          mappedLessons()
          setCourseDeleteSuccess(false)
          setTopicList([])
          setMainCategoryId("")
          setLastCategoryId("")
        } else {
          setCourseMapError(true)
          setCourseMapErrorMessage(data.description)
          setTopicList([])
          setMainCategoryId("")
          setLastCategoryId("")
        }
      });
  }

  //mapped lesson list

  async function mappedLessons() {
    await fetch(`${baseUrl}/clublearning/mapped/listings`, {
      method: "POST",
      body: JSON.stringify({
        club_id: clubid,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMappedLessonList(data.data);
      });
  }


  //mapped lesson delete

  async function courseDelete() {
    await fetch(`${baseUrl}/mapped/learning/delete`, {
      method: "POST",
      body: JSON.stringify({
        learning_id: learningId
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          mappedLessons()
          setLessonMappingSuccess(true);
          setCourseDeleteSuccess(true)
        }
      });
  }

  //calander sheduling

  async function Shedule(eventData) {
    await fetch(`${baseUrl}/schedule/calendars`, {
      method: "POST",
      body: JSON.stringify({
        club_id: clubid,
        ItemType: "Task",
        Title: eventData.title,
        Description: eventData.description,
        StartDateTime: eventData.start,
        EndDateTime: eventData.end,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setPopup(false);
          setTitle("");
          setDescription("");
          setStartDate("");
          setStartTime("");
          setEndDate("");
          setEndTime("");
          sheduleGet();
        }
      });
  }

  //shedules get

  async function sheduleGet() {
    await fetch(`${baseUrl}/calendaritem/get/club`, {
      method: "POST",
      body: JSON.stringify({
        club_id: clubid,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const formattedEvents = data.data.map((item) => ({
          title: item.Title,
          start: item.StartDateTime.$date, // Assuming StartDateTime is correctly formatted
          end: item.EndDateTime.$date, // Assuming EndDateTime is correctly formatted
          description: item.Description,
          CalendarItemID: item.CalendarItemID,
        }));
        setEvents(formattedEvents);
      });
  }

  //shedule indvidual listing

  async function sheduleIndvidual(calendarItemID) {
    await fetch(`${baseUrl}/calendaritem/get/individual/club`, {
      method: "POST",
      body: JSON.stringify({
        CalendarItemID: calendarItemID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCalendarItem(data.data[0]);
      });
  }

  //shedule update

  async function sheduleUpdate(event) {
    await fetch(`${baseUrl}/calendaritem/update/club`, {
      method: "POST",
      body: JSON.stringify({
        CalendarItemID: itemID,
        Title: event.titleSecond,
        Description: event.descriptionSecond,
        StartDateTime: event.start,
        EndDateTime: event.end,
        Status: "inprogress",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setEdit(false);
          setPopup(false);
          sheduleGet();
        }
      });
  }

  //shedule delete

  async function sheduleDelete() {
    await fetch(`${baseUrl}/calendaritem/delete/club`, {
      method: "POST",
      body: JSON.stringify({
        CalendarItemID: itemID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setDeleteItem(false);
          sheduleGet();
        }
      });
  }

  //password reset

  async function passReset(pass) {
    await fetch(`${baseUrl}/password/change`, {
      method: "POST",
      body: JSON.stringify({
        user_id: passwordId,
        new_pwd: pass,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setShowPasswordPopup(false);
          setPasswordChange(true);
        }
      });
  }

  useEffect(() => {
    setTitleSecond(calendarItem.Title);
    setDescriptionSecond(calendarItem.Description);
    setStartDateSecond(
      calendarItem && calendarItem.StartDateTime
        ? new Date(calendarItem.StartDateTime.$date).toISOString().substr(0, 10)
        : ""
    );

    setEndDateSecond(
      calendarItem && calendarItem.EndDateTime
        ? new Date(calendarItem.EndDateTime.$date).toISOString().substr(0, 10)
        : ""
    );

    setStartTimeSecond(
      calendarItem && calendarItem.StartDateTime
        ? new Date(calendarItem.StartDateTime.$date).toLocaleTimeString()
        : ""
    );
    setEndTimeSecond(
      calendarItem && calendarItem.EndDateTime
        ? new Date(calendarItem.EndDateTime.$date).toLocaleTimeString()
        : ""
    );
  }, [calendarItem]);

  useEffect(() => {
    clubGet();
  }, [key]);

  useEffect(() => {
    allMember();
  }, []);

  useEffect(() => {
    const data = {
      orgId: org_id,
      clubId: clubid,
    };
    dispatch(RoleGet(data));
  }, []);
  useEffect(() => {
    dispatch(ClubGet(org_id));
  }, []);

  useEffect(() => {
    if (topicList.length > 0) {
      learningSave();
    }
  }, [topicList]);

  return (
    <div className={style.Container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={userCreation || userDeletion || userUpdation || passwordChange}
        autoHideDuration={3000}
        onClose={() => {
          setUserCreation(false);
          setUserUpdation(false);
          setUserDeletion(false);
          setPasswordChange(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {userCreation
            ? "USer created successfully"
            : userDeletion
              ? "USer deleted successfully"
              : userUpdation
                ? "User updated successfully"
                : passwordChange
                  ? "Password Changed Successfully"
                  : ""}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={memberCreationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setMemberCreationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={apiResponse}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setApiResponse(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiError}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={passwordCondition}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setPasswordCondition(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {passwordMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={lessonMappingSuccess}
        sx={{ width: "24rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setLessonMappingSuccess(false);
        }}
      >
        {courseDeleteSuccess ?
          <Alert
            variant="filled"
            severity="success"
            sx={{ width: "100%", fontSize: "1.15rem" }}
          >

            Mapped course deleted successfully
          </Alert>
          :
          <Alert
            variant="filled"
            severity="success"
            sx={{ width: "100%", fontSize: "1.15rem" }}
          >

            Course mapped successfully
          </Alert>}
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={courseMapError}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCourseMapError(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {courseMapErrorMessage}
        </Alert>
      </Snackbar>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={style.HeaderDiv}>
            <div className={style.Title}>
              {userRole === false || userRole === "false" ? (
                <p
                  onClick={() => {
                    navigate("/club", {
                      state: { organization: clubData.OrganizationID },
                    });
                  }}
                >
                  {clubData.ClubName}
                </p>
              ) : (
                <>
                  <p
                    onClick={() => {
                      navigate("/organization");
                    }}
                  >
                    {clubData.OrgName}{" "}
                  </p>
                  <span>
                    <IoIosArrowForward />
                  </span>
                  <p
                    onClick={() => {
                      navigate("/club", {
                        state: { organization: clubData.OrganizationID },
                      });
                    }}
                  >
                    {clubData.ClubName}
                  </p>
                </>
              )}
            </div>
            <div className={style.headButtons}>
              <button
                className={style.AddButton}
                onClick={(e) => {
                  setShowMember(true);
                }}
              >
                Create Member{" "}
              </button>
              <button
                className={style.existButton}
                onClick={() => {
                  navigate("/user/mapping", { state: { org: org_id } });
                }}
              >
                Add Member
              </button>
            </div>
          </div>

          <div className={style.tabSection}>
            <Tabs
              TabIndicatorProps={{ style: { backgroundColor: "#83b516" } }}
              value={selectedTab}
              onChange={handleTabChange}
              centered
            >
              <Tab
                label="Members"
                sx={{
                  "&.Mui-selected": {
                    color: "#3e61a3",
                    backgroundColor: "",
                  },
                  fontSize: "1.25rem",
                  color: "#1a1e5d",
                  backgroundColor: "",
                  width: "500px",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
              />
              <Tab
                label="Learning"
                sx={{
                  "&.Mui-selected": {
                    color: "#3e61a3",
                    backgroundColor: "",
                  },
                  fontSize: "1.25rem",
                  color: "#1a1e5d",
                  backgroundColor: "",
                  width: "500px",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  mappedLessons();
                }}
              />
              <Tab
                label="schedule "
                sx={{
                  "&.Mui-selected": {
                    color: "#3e61a3",
                    backgroundColor: "",
                  },
                  fontSize: "1.25rem",
                  color: "#1a1e5d",
                  backgroundColor: "",
                  width: "500px",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  sheduleGet();
                }}
              />
            </Tabs>
          </div>
          <Typography className={style.Tab}>
            {/* first tab for member listing */}

            {selectedTab === 0 && (
              <div className={style.List}>
                <Paper
                  sx={{
                    width: "100%",
                    // overflow: "hidden",
                  }}
                >
                  <TableContainer
                    sx={{
                      maxHeight: 680,
                      // width:"100%"
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            key="name"
                            align="center"
                            style={{
                              fontSize: "1.25rem",
                              fontWeight: "600",
                              color: "#535370",
                              background: "#f8faff",
                              borderRight: "2px solid  #dedede",
                              zIndex: "0",
                            }}
                          >
                            User Name
                          </TableCell>

                          <TableCell
                            key="role"
                            align="center"
                            style={{
                              fontSize: "1.25rem",
                              fontWeight: "600",
                              color: "#535370",
                              background: "#f8faff",
                              borderRight: "2px solid  #dedede",
                              zIndex: "0",
                            }}
                          >
                            Role
                          </TableCell>
                          <TableCell
                            key="email"
                            align="center"
                            style={{
                              fontSize: "1.25rem",
                              fontWeight: "600",
                              color: "#535370",
                              background: "#f8faff",
                              borderRight: "2px solid  #dedede",
                              zIndex: "0",
                            }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            key="action"
                            align="center"
                            style={{
                              fontSize: "1.25rem",
                              fontWeight: "600",
                              color: "#535370",
                              background: "#f8faff",
                              zIndex: "9",
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {memberList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Username}
                            >
                              <TableCell
                                key="name"
                                align="left"
                                style={{
                                  border: "1px solid #e8e4ef",
                                  fontSize: "1rem",
                                }}
                              >
                                {row.Username}
                              </TableCell>

                              <TableCell
                                key="role"
                                align="left"
                                style={{
                                  border: "1px solid #e8e4ef",
                                  fontSize: "1rem",
                                }}
                              >
                                {row.role}
                              </TableCell>
                              <TableCell
                                key="email"
                                align="left"
                                style={{
                                  border: "1px solid #e8e4ef",
                                  fontSize: "1rem",
                                }}
                              >
                                {row.Email}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid #e8e4ef",
                                }}
                              >
                                <div className={style.Buttons}>
                                  <Tooltip title="Edit">
                                    <IconButton>
                                      <BiEdit
                                        className={style.editButton}
                                        onClick={() => {
                                          setUpdatePopup(true);
                                          setEditClubs(row.club_id);
                                          setEditFirstName(row.FirstName);
                                          setEditSecondName(row.LastName);
                                          setEditPhone(row.ContactNumber);
                                          setEditEmail(row.Email);
                                          setEditUserName(row.Username);
                                          setUserId(row._id);
                                          setEditRoleName(row.role);
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Reset Password">
                                    <IconButton>
                                      <PiPasswordBold
                                        className={style.passwordRest}
                                        onClick={() => {
                                          setShowPasswordPopup(true);
                                          setPasswordId(row._id);
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Calender">
                                    <IconButton>
                                      <IoCalendarNumberOutline
                                        className={style.calanderSchedule}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                    <IconButton>
                                      <RiDeleteBinLine
                                        className={style.deleteButton}
                                        onClick={(e) => {
                                          setMemberDelete(true);
                                          setMemberId(row._id);
                                          setDeleteMemberName(row.Username);
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={memberList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{
                      fontSize: "1.15rem",
                    }}
                  />
                </Paper>

                {/* <table className={style.table}>
              <tr>
                <th>User Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
              {displayUsers.length > 0 &&
                displayUsers.map((member) => {
                  return (
                    <tr>
                      <td>{member.Username}</td>
                      <td>{member.role}</td>
                      <td>{member.Email}</td>
                      <td>
                        <div className={style.Buttons}>
                          <Tooltip title="Edit">
                            <IconButton>
                              <BiEdit
                                className={style.editButton}
                                onClick={() => {
                                  setUpdatePopup(true);
                                  setEditClubs(member.club_id);
                                  setEditFirstName(member.FirstName);
                                  setEditSecondName(member.LastName);
                                  setEditPhone(member.ContactNumber);
                                  setEditEmail(member.Email);
                                  setEditUserName(member.Username);
                                  setUserId(member._id);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Reset Password">
                            <IconButton>
                              <PiPasswordBold
                                className={style.passwordRest}
                                onClick={() => {
                                  setShowPasswordPopup(true);
                                  setPasswordId(member._id);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Calander">
                            <IconButton>
                              <IoCalendarNumberOutline
                                className={style.calanderSchedule}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton>
                              <RiDeleteBinLine
                                className={style.deleteButton}
                                onClick={(e) => {
                                  setMemberDelete(true);
                                  setMemberId(member._id);
                                  setDeleteMemberName(member.Username);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </table>
            <Pagination
              pageCount={Math.ceil(totalUsers / itemsPerPage)}
              onPageChange={handlePageChange}
            /> */}
              </div>
            )}

            {/* second tab for categories listing */}

            {selectedTab === 1 && (
              <div className={style.categoryList}>
                <div className={style.categoryGrid}>
                  {mappedLessonList.length > 0 &&
                    mappedLessonList.map((list) => {
                      const truncatedName =
                        list.category_details.length > 20
                          ? list.category_details.substring(0, 20) + "..."
                          : list.category_details;

                      return (
                        <div className={style.Card}>
                          <div className={style.cardTop}>
                            <p>{truncatedName}</p>
                          </div>
                          <div className={style.cardAction}>
                            <Tooltip title="Delete">
                              <IconButton>
                                <RiDeleteBinLine
                                  className={style.deleteButton}
                                  onClick={(e) => {
                                    console.log(list._id.$oid);
                                    setLearningId(list.learning_id)
                                    setMappedLearningDelete(true)
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* <div className={style.assignedClub}>
                  <button
                    onClick={() => {
                      navigate("/club/lesson", {
                        state: { club: clubid, clubname: clubData.ClubName },
                      });
                    }}
                  >
                    Lesson List
                  </button>
                </div> */}
                <div className={style.assignedClub}>
                  <button
                    onClick={() => {
                      setSubCategoryPopup(true);
                      setMainCategoryShow(true);
                      categoryGet();
                    }}
                  >
                    Add Course
                  </button>
                </div>
              </div>
            )}

            {/* third tab for calander */}

            {selectedTab === 2 && (
              <div className={style.shedule}>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  events={events}
                  dateClick={handleDateClick}
                  eventClick={handleEventClick}
                  showNonCurrentDates={false}
                  headerToolbar={{
                    start: "prev,next",
                    center: "title",
                    end: "dayGridMonth,timeGridWeek",
                  }}
                  timeZone="local"
                  height="550px"
                  aspectRatio={2}
                  eventTimeFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                  }}
                />
              </div>
            )}
          </Typography>
        </>
      )}

      {subCategoryPopup ? (
        <>
          <div className={style.learningOverlay}></div>
          <div className={style.categorySelection}>
            <div className={style.Header}>
              <div className={style.navLinks}>
                <div className={style.linkLeft}>
                  <p
                    onClick={() => {
                      setSubCategoryShow(false);
                      setMainCategoryShow(true);
                      categoryGet();
                      setCurrentPath("");
                    }}
                  >
                    {currentPath}
                  </p>
                </div>
                {divide && selectedCategories.length > 0 ? (
                  <span className={style.divide}>/</span>
                ) : (
                  ""
                )}

                {selectedCategories.map((category, index) => {
                  const truncatedName =
                    category.Name.length > 10
                      ? category.Name.substring(0, 10) + "..."
                      : category.Name;
                  return (
                    <div className={style.linkRight} key={category.category_id}>
                      <span>{index > 0 && "/"}</span>
                      <p
                        onClick={() =>
                          handleBreadcrumbClick(category.parent_id, index)
                        }
                      >
                        {truncatedName}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className={style.headerName}>
                <p>Select your course</p>
              </div>
              <div className={style.headerClose}>
                <AiOutlineClose
                  onClick={() => {
                    setSelectedCategories([]);
                    setDivide(false);
                    setSubCategoryPopup(false);
                    setSubCategoryShow(false);
                    setCurrentPath("");
                  }}
                />
              </div>
            </div>
            {mainCategoryShow ? (
              <div className={style.SelectionGrid}>
                {categoryList.length > 0 &&
                  categoryList.map((list) => {
                    const truncatedName =
                      list.Name.length > 20
                        ? list.Name.substring(0, 20) + "..."
                        : list.Name;
                    const truncatedPath =
                      list.Name.length > 10
                        ? list.Name.substring(0, 10) + "..."
                        : list.Name;
                    return (
                      <div className={style.sectionCard}>
                        <div className={style.cardContent}>
                          <p>{truncatedName}</p>
                        </div>

                        <div className={style.cardBottom}>
                          {list.next_data === 0 ? (
                            <Tooltip title="Course Map">
                              <IconButton>
                                <MdOutlineLibraryBooks
                                  className={style.courseMap}
                                  onClick={() => {
                                    setMainCategoryId(list.category_id);
                                    setCourseMapShow(true);
                                    setCategoryValue(list.next_data);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="View">
                              <IconButton>
                                <HiOutlineViewGrid
                                  className={style.viewButton}
                                  onClick={() => {
                                    subcategoryGet(list.category_id);
                                    setSubCategoryShow(true);
                                    setMainCategoryShow(false);
                                    setMainCategoryId(list.category_id);
                                    setCurrentPath(truncatedPath);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              ""
            )}

            {subCategoryShow ? (
              <div className={style.SelectionGrid}>
                {subCategoryList.length > 0 &&
                  subCategoryList.map((sub) => {
                    const truncatedName =
                      sub.Name.length > 20
                        ? sub.Name.substring(0, 20) + "..."
                        : sub.Name;
                    return (
                      <div className={style.sectionCard}>
                        <div className={style.cardContent}>
                          <p>{truncatedName}</p>
                        </div>

                        <div className={style.cardBottom}>
                          {sub.next_data === 0 ? (
                            <Tooltip title="Course Map">
                              <IconButton>
                                <MdOutlineLibraryBooks
                                  className={style.courseMap}
                                  onClick={() => {
                                    setLastCategoryId(sub.category_id);
                                    setCourseMapShow(true);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="View">
                              <IconButton>
                                <HiOutlineViewGrid
                                  className={style.viewButton}
                                  onClick={() => {
                                    subcategoryGet(sub.category_id);
                                    setMainCategoryShow(false);
                                    setLastCategoryId(sub.category_id);
                                    handleCategoryClick(sub);
                                    setDivide(true);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}

      {courseMapShow ? (
        <>
          <div
            onClick={() => {
              setCourseMapShow(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setCourseMapShow(false);
                }}
              />
            </div>
            <div className={style.popupText}>
              <p>Are you sure you want to map the course ?</p>
            </div>
            <div className={style.popupDelete}>
              <button
                className={style.orgDelete}
                onClick={() => {
                  TopicGet();
                  setCourseMapShow(false);
                }}
              >
                Map Course
              </button>
              <button
                className={style.orgDeleteCancel}
                onClick={() => {
                  setCourseMapShow(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {mappedLearningDelete ? (
        <>
          <div
            onClick={() => {
              setMappedLearningDelete(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setMappedLearningDelete(false);
                }}
              />
            </div>
            <div className={style.popupText}>
              <p>Are you sure you want to delete ?</p>
            </div>
            <div className={style.popupDelete}>
              <button
                className={style.orgDelete}
                onClick={() => {
                  courseDelete();
                  setMappedLearningDelete(false);
                }}
              >
                Delete
              </button>
              <button
                className={style.orgDeleteCancel}
                onClick={() => {
                  setMappedLearningDelete(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>) : ("")}



      {/* {goalShow ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setGoalShow(false);
            }}
          ></div>
          <div className={style.selectionPopup}>
            <div className={style.selectionPopupClose}>
              <AiOutlineClose
                onClick={() => {
                  setGoalShow(false);
                }}
              />
            </div>
            <div className={style.selectionPopupSection}>
              <div className={style.selectionTop}>
                <FormControl sx={{ width: "350px", margin: "0 0.25rem" }}>
                  <InputLabel id="demo-simple-select-label">
                    Select your Goal
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label=" Select your Goal"
                    onChange={(e) => {
                      const selectedGoal = e.target.value;
                      setGoal(selectedGoal);
                    }}
                  >
                    {goalList && goalList.length > 0 ? (
                      goalList.map((goalValue) => (
                        <MenuItem
                          value={goalValue.GoalName}
                          key={goalValue.GoalID}
                        >
                          {goalValue.GoalName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No goals are available</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "350px", margin: "0 0.25rem" }}>
                  <InputLabel id="demo-simple-select-label">
                    Select a Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select a Month"
                    onChange={(e) => {
                      setMonth(e.target.value);
                    }}
                  >
                    <MenuItem value="1">1 Month</MenuItem>
                    <MenuItem value="2">2 Month</MenuItem>
                    <MenuItem value="3">3 Month</MenuItem>
                    <MenuItem value="4">4 Month</MenuItem>
                    <MenuItem value="5">5 Month</MenuItem>
                    <MenuItem value="6">6 Month</MenuItem>
                    <MenuItem value="7">7 Month</MenuItem>
                    <MenuItem value="8">8 Month</MenuItem>
                    <MenuItem value="9">9 Month</MenuItem>
                    <MenuItem value="10">10 Month</MenuItem>
                    <MenuItem value="11">11 Month</MenuItem>
                    <MenuItem value="12">12 Month</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={style.comment}>
                <TextField
                  id="outlined-basic"
                  multiline
                  rows={3}
                  maxRows={4}
                  label="Comment"
                  variant="outlined"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
            <div className={style.selectionButton}>
              <button
                onClick={() => {
                  TopicGet();
                  setGoalShow(false);
                  setTopicShow(true);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )} */}

      {/* {topicShow ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setTopicShow(false);
            }}
          ></div>

          <div className={style.topicContainer}>
            <div className={style.topicClose}>
              <AiOutlineClose
                onClick={() => {
                  setTopicShow(false);
                }}
              />
            </div>
            <div className={style.topicSection}>
              <List
                sx={{
                  width: "100%",
                }}
              >
                {topicList.length > 0 &&
                  topicList[0].topics.map((topic) => {
                    return (
                      <ListItem
                        key={topic.value}
                        sx={{
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                          "&:hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <ListItemText
                          sx={{
                            fontSize: "1.25rem",
                            textTransform: "capitalize",
                          }}
                          primary={topic.value}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </div>
            <div className={style.topicButon}>
              <button
                onClick={() => {
                  learningSave();
                  setTopicShow(false);
                }}
              >
                Assign Learning
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )} */}

      {showMember ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setShowMember(false);
              setApiError("");
              setErrors({});
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setShowMember(false);
                  setApiError("");
                  setErrors({});
                }}
              />
            </div>
            {/* <div className={style.membersection}> */}
            <div className={style.sectionValue}>
              <div className={style.inputValues}>
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className={style.inputValues}>
                <label htmlFor="">Second Name</label>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => {
                    setSecondName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={style.sectionValue}>
              <div className={style.inputValues}>
                <label htmlFor="">User Name</label>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
              </div>
              <div className={style.inputValues}>
                <label htmlFor="">Password</label>
                <div className={style.password}>
                  <AiOutlineInfoCircle
                    onClick={() => {
                      SetConditionPopup(true);
                    }}
                  />
                  <input
                    type={showPassword ? "text" : "password"}
                    name=""
                    id=""
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <BiLockOpenAlt
                    onClick={() => {
                      togglePasswordVisibility();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={style.sectionValue}>
              <div className={style.inputValues}>
                <label htmlFor="">Phone</label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder=""
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
              <div className={style.inputValues}>
                <label htmlFor="">Email</label>
                <input
                  type="mail"
                  name=""
                  id=""
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={style.sectionValueBoxMain}>

        <div className={style.sectionValueBox}>
              <FormControl fullWidth>
                      <InputLabel id="country-select-label">
                        Select Country
                      </InputLabel>
                      <Select
                        labelId="country-select-label"
                        id="country-select"
                        // value={countryName}
                        label="Select Country"
                        onChange={(e) => setCountryName(e.target.value)}
                      >
                        {countryData.map((country, index) => (
                          <MenuItem key={index} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
  
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Role"
                    onChange={(e) => {
                      const RoleID = e.target.value;
                      const roleName = roles.find(
                        (role) => role.role_id === RoleID
                      );
                      setSelectedRoleId(RoleID);
                      if (roleName) {
                        setSelectedRoleName(roleName.role_name); // Extract role_name from the object
                      }
                    }}
                  >
                    {roles &&
                      roles.length > 0 &&
                      roles.map((role) => (
                        <MenuItem key={role.role_id} value={role.role_id}>
                          {role.role_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
        </div>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Club/Batch
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Club/Batch"
                  multiple
                  value={selectedClubs}
                  onChange={(event) => setSelectedClubs(event.target.value)} // Add this prop
                >
                  {clubs &&
                    clubs.length > 0 &&
                    clubs.map((club) => {
                      const isSelected = selectedClubs.includes(club.ClubID);
                      return (
                        <MenuItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          key={club.ClubID}
                          value={club.ClubID}
                        >
                          {" "}
                          {club.ClubName}
                          <Checkbox
                            name={club.ClubID}
                            id={club.ClubID}
                            checked={isSelected}
                            onChange={() => handleCheckboxChange(club.ClubID)}
                          />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              {/* <div className={style.clubSelection}> */}
              {/* <p
                  onClick={() => {
                    setClubSelectShow(true);
                  }}
                >
                  Select club
                </p>

                {clubSelectShow ? (
                  <MdKeyboardArrowUp
                    onClick={() => {
                      setClubSelectShow(false);
                    }}
                  />
                ) : (
                  <MdKeyboardArrowDown
                    onClick={() => {
                      setClubSelectShow(true);
                    }}
                  />
                )}

                {clubSelectShow ? (
                  <div className={style.clubCheck}>
                    {clubs &&
                      clubs.length > 0 &&
                      clubs.map((club) => {
                        const isSelected = selectedClubs.includes(club.ClubID);
                        return (
                          <div className={style.clubValue} key={club.ClubID}>
                            <p>{club.ClubName}</p>
                            <input
                              type="checkbox"
                              name={club.ClubID}
                              id={club.ClubID}
                              checked={isSelected}
                              onChange={() => {
                                toggleSelectedClub(club.ClubID);
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  ""
                )} */}

              {/* </div> */}
            </div>
            {/* </div> */}
            <div className={style.createButton}>
              <button
                className={style.create}
                onClick={() => {
                  memberAdd();
                }}
              >
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setShowMember(false);
                  setApiError("");
                  setErrors({});
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {updatePopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setUpdatePopup(false);
              setApiError("");
              setErrors({});
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setUpdatePopup(false);
                  setApiError("");
                  setErrors({});
                }}
              />
            </div>

            <div className={style.membersection}>
              <div className={style.sectionValue}>
                <div className={style.inputValues}>
                  <label htmlFor="">First Name</label>
                  <input
                    value={editFirstName}
                    type="text"
                    name=""
                    id=""
                    onChange={(e) => {
                      setEditFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className={style.inputValues}>
                  <label htmlFor="">Second Name</label>
                  <input
                    value={editSecondName}
                    type="text"
                    name=""
                    id=""
                    onChange={(e) => {
                      setEditSecondName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={style.sectionValue}>
                <div className={style.inputValues}>
                  <label htmlFor="">User Name</label>
                  <input
                    value={editUserName}
                    type="text"
                    name=""
                    id=""
                    placeholder="User Name"
                    onChange={(e) => {
                      setEditUserName(e.target.value);
                    }}
                  />
                </div>
                <div className={style.inputValues}>
                  <label htmlFor="">Phone</label>
                  <input
                    value={editPhone}
                    type="text"
                    name=""
                    id=""
                    placeholder="Phone"
                    onChange={(e) => {
                      setEditPhone(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={style.sectionValue}>
                <div className={style.inputValues}>
                  <label htmlFor="">Email</label>
                  <input
                    value={editEmail}
                    type="mail"
                    name=""
                    id=""
                    placeholder="Email"
                    onChange={(e) => {
                      setEditEmail(e.target.value);
                    }}
                  />
                </div>
                <div className={style.inputValues}>
                  <label htmlFor="">Current Role</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    value={editRoleName}
                    disabled
                  />
                </div>
              </div>

              <div className={style.sectionValueBoxMain}>

            <div className={style.sectionValueBox}>
                <FormControl fullWidth>
                        <InputLabel id="country-select-label">
                          Select Country
                        </InputLabel>
                        <Select
                          labelId="country-select-label"
                          id="country-select"
                          value={editCountryName}
                          label="Select Country"
                          onChange={(e) => setEditCountryName(e.target.value)}
                        >
                          {countryData.map((country, index) => (
                            <MenuItem key={index} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
  
  
  
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Update Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Update Role"
                      onChange={(e) => {
                        const RoleID = e.target.value;
                        const roleName = roles.find(
                          (role) => role.role_id === RoleID
                        );
                        setSelectedRoleId(RoleID);
                        if (roleName) {
                          setSelectedRoleName(roleName.role_name); // Extract role_name from the object
                        }
                      }}
                    >
                      {roles &&
                        roles.length > 0 &&
                        roles.map((role) => (
                          <MenuItem key={role.role_id} value={role.role_id}>
                            {role.role_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
            </div>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Club/Batch
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Club/Batch"
                    multiple
                    value={editClubs}
                    onChange={(event) => setEditClubs(event.target.value)} // Add this prop
                  >
                    {clubs &&
                      clubs.length > 0 &&
                      clubs.map((club) => {
                        const isSelected = editClubs.includes(club.ClubID);
                        return (
                          <MenuItem
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                            key={club.ClubID}
                            value={club.ClubID}
                          >
                            {" "}
                            {club.ClubName}
                            <Checkbox
                              name={club.ClubID}
                              id={club.ClubID}
                              checked={isSelected}
                              onChange={() => {
                                handleClub(club.ClubID);
                              }}
                            />
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                {/* <select
                  className={style.roleSelection}
                  name=""
                  id=""
                  onChange={(e) => {
                    const selectedRoleID = e.target.value;
                    const roleName =
                      e.target.options[e.target.selectedIndex].text;
                    setSelectedRoleId(selectedRoleID);
                    setSelectedRoleName(roleName);
                  }}
                >
                  <option value="">Update Role</option>

                  {roles &&
                    roles.length > 0 &&
                    roles.map((role) => {
                      return (
                        <option value={role.role_id}>{role.role_name}</option>
                      );
                    })}
                </select> */}

                {/* <div className={style.clubSelection}>
                  <p>Select Club</p>

                  {EditClubSelectShow ? (
                    <MdKeyboardArrowUp
                      onClick={() => {
                        setEditClubSelectShow(false);
                      }}
                    />
                  ) : (
                    <MdKeyboardArrowDown
                      onClick={() => {
                        setEditClubSelectShow(true);
                      }}
                    />
                  )}
                </div> */}
                {/* {EditClubSelectShow ? (
                  <div className={style.clubCheckUpdate}>
                    {clubs &&
                      clubs.length > 0 &&
                      clubs.map((club) => {
                        const isSelected = editClubs.includes(club.ClubID);
                        return (
                          <div className={style.clubValue} key={club.ClubID}>
                            <p>{club.ClubName}</p>
                            <input
                              type="checkbox"
                              name={club.ClubID}
                              id={club.ClubID}
                              checked={isSelected}
                              onChange={() => {
                                handleClub(club.ClubID);
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  ""
                )} */}
              </div>

              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    memberEdit();
                  }}
                >
                  Update
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setUpdatePopup(false);
                    setApiError("");
                    setErrors({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {memberDelete ? (
        <>
          <div
            onClick={() => {
              setMemberDelete(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setMemberDelete(false);
                }}
              />
            </div>
            <div className={style.popupText}>
              <p>
                Are you sure you want to delete <span>{deleteMemberName}</span>{" "}
                ?
              </p>
            </div>
            <div className={style.popupDelete}>
              <button
                className={style.orgDelete}
                onClick={() => {
                  DeleteMember();
                }}
              >
                Delete
              </button>
              <button
                className={style.orgDeleteCancel}
                onClick={() => {
                  setMemberDelete(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {popup ? (
        <>
          {!edit ? (
            <>
              <div
                className={style.Overlay}
                onClick={() => {
                  setPopup(false);
                }}
              ></div>
              <div className={style.AddressSection}>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    required
                    placeholder="Event Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <div className={style.Time}>
                    <div className={style.TimeSecond}>
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="startDate"
                        id="startDate"
                        required
                        placeholder="Start Date"
                        readOnly
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className={style.TimeSecond}>
                      <label>End Date</label>
                      <input
                        type="date"
                        name="endDate"
                        id="endDate"
                        required
                        placeholder="End Date"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                  <div className={style.Time}>
                    <div className={style.TimeSecond}>
                      <label>Start Time</label>
                      <input
                        type="time"
                        name="startTime"
                        id="startTime"
                        required
                        placeholder="Start Time"
                        value={startTime}
                        onChange={handlestartTimeChange}
                      />
                    </div>

                    <div className={style.TimeSecond}>
                      <label>End Time</label>
                      <input
                        type="time"
                        name="endTime"
                        id="endTime"
                        required
                        placeholder="End Time"
                        value={endTime}
                        onChange={handleEndTimeChange}
                      />
                    </div>
                  </div>
                  <div className={style.Description}>
                    <label>Description</label>
                    <div className={style.Editor}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={description}
                        onChange={handleEditorChange}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "100px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                          ],
                          alignment: {
                            options: ["left", "center", "right"],
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.FormButton}>
                    <button type="submit">Create</button>
                    <button
                      type="button"
                      onClick={() => {
                        setPopup(false);
                        setTitle("");
                        setDescription("");
                        setStartDate("");
                        setStartTime("");
                        setEndDate("");
                        setEndTime("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <div
                className={style.Overlay}
                onClick={() => {
                  setPopup(false);
                }}
              ></div>
              <div className={style.AddressSection}>
                <form onSubmit={handleUpdate}>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    required
                    placeholder="Event Title"
                    value={titleSecond}
                    onChange={(e) => setTitleSecond(e.target.value)}
                  />

                  <div className={style.Time}>
                    <div className={style.TimeSecond}>
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="startDate"
                        id="startDate"
                        required
                        placeholder="Start Date"
                        value={startDateSecond}
                        onChange={handleStartDateSecondChange}
                      />
                    </div>
                    <div className={style.TimeSecond}>
                      <label>End Date</label>
                      <input
                        type="date"
                        name="endDate"
                        id="endDate"
                        required
                        placeholder="End Date"
                        value={endDateSecond}
                        onChange={handleEndDateSecondChange}
                      />
                    </div>
                  </div>
                  <div className={style.Time}>
                    <div className={style.TimeSecond}>
                      <label>Start Time</label>
                      <input
                        type="time"
                        name="startTime"
                        id="startTime"
                        required
                        placeholder="Start Time"
                        value={startTimeSecond}
                        onChange={handleStartTimeSecondChange}
                      />
                    </div>

                    <div className={style.TimeSecond}>
                      <label>End Time</label>
                      <input
                        type="time"
                        name="endTime"
                        id="endTime"
                        required
                        placeholder="End Time"
                        value={endTimeSecond}
                        onChange={handleEndTimeSecondChange}
                      />
                    </div>
                  </div>
                  <div className={style.Description}>
                    <label>Description</label>
                    <div className={style.Editor}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={descriptionSecond}
                        onChange={handleEditorChangeSecond}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "100px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                          ],
                          alignment: {
                            options: ["left", "center", "right"],
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.FormButton}>
                    <button disabled={startDateSecond === ""} type="submit">
                      Update
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPopup(false);
                        setTitleSecond("");
                        setDescriptionSecond("");
                        setStartDateSecond("");
                        setStartTimeSecond("");
                        setEndDateSecond("");
                        setEndTimeSecond("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      ) : (
        ""
      )}
      {view ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setView(false);
            }}
          ></div>
          <div className={style.ViewPopup}>
            <div className={style.Icons}>
              <span className={style.EditIcons}>
                <span
                  onClick={() => {
                    setPopup(true);
                    setEdit(true);
                    setView(false);
                  }}
                >
                  <GrEdit />
                </span>
                <span
                  onClick={() => {
                    setDeleteItem(true);
                    setView(false);
                  }}
                >
                  <RiDeleteBin6Line />
                </span>
                <span
                  onClick={() => {
                    setView(false);
                  }}
                >
                  {" "}
                  <IoMdClose />
                </span>
              </span>
            </div>
            <div className={style.Content}>
              <div className={style.Title}>
                <p>{calendarItem.Title}</p>
              </div>
              <div className={style.start}>
                {calendarItem.StartDateTime && calendarItem.EndDateTime && (
                  <>
                    <p>
                      {new Date(
                        calendarItem.StartDateTime.$date
                      ).toLocaleDateString(undefined, {
                        hour12: true, // Display time in 12-hour format
                        hour: "numeric",
                        minute: "numeric",
                      })}
                      {" - "}
                      {new Date(
                        calendarItem.EndDateTime.$date
                      ).toLocaleDateString(undefined, {
                        hour12: true, // Display time in 12-hour format
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {deleteItem ? (
        <>
          <div
            onClick={() => {
              setDeleteItem(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setDeleteItem(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    sheduleDelete();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setDeleteItem(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {showPasswordPopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setShowPasswordPopup(false);
            }}
          ></div>
          <div className={style.passwordReset}>
            <div className={style.resetClose}>
              <AiOutlineClose onClick={() => handleCancelClick()} />
            </div>
            <div className={style.passworSection}>
              {/* {passwordMessage && (
                <div style={{ color: "red", fontSize: "1.2rem" }}>
                  {passwordMessage}
                </div>
              )} */}
              <div className={style.resetInput}>
                <AiOutlineInfoCircle
                  onClick={() => {
                    SetConditionPopup(true);
                  }}
                />
                <input
                  type={showPassword ? "text" : "password"}
                  name=""
                  id=""
                  placeholder="Password"
                  value={editPassword}
                  onChange={(e) => {
                    handlePasswordChange(e);
                    setpasswordMessage("");
                  }}
                />
                <BiLockOpenAlt
                  onClick={() => {
                    togglePasswordVisibility();
                  }}
                />
              </div>
              <div className={style.confirmResetInput}>
                <input
                  type={showEditPassword ? "text" : "password"}
                  name=""
                  id=""
                  onClick={() => {
                    if (
                      editPassword !== "" &&
                      (/[A-Z]/.test(editPassword) === false ||
                        /\d/.test(editPassword) === false ||
                        editPassword.length < 6 ||
                        editPassword.length > 15)
                    ) {
                      setpasswordMessage("Password does not match Criteria");
                      setPasswordCondition(true);
                    }
                  }}
                  placeholder="Retype Password"
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e)}
                />
                <BiLockOpenAlt
                  onClick={() => {
                    togglePassword();
                  }}
                />
              </div>
            </div>
            <div className={style.resetButtons}>
              <button
                className={style.resetUpddate}
                onClick={() => {
                  if (
                    editPassword !== "" &&
                    (/[A-Z]/.test(editPassword) === false ||
                      /\d/.test(editPassword) === false ||
                      editPassword.length < 6 ||
                      editPassword.length > 15)
                  ) {
                    setpasswordMessage("Password does not match Criteria");
                    setPasswordCondition(true);
                  } else {
                    handleUpdateClick();
                  }
                }}
              >
                Update
              </button>
              <button
                className={style.restCancel}
                onClick={() => handleCancelClick()}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {conditionPopup ? (
        <>
          <div
            className={style.secondOverlay}
            onClick={() => {
              SetConditionPopup(false);
            }}
          ></div>
          <div className={style.PasswordPopup}>
            <div className={style.content}>
              <ul>
                <p>Password must include:</p>
                <li>6-15 Characters</li>
                <li>At least 1 capital letter</li>
                <li>At least 1 number</li>
                <li>At least 1 special character</li>
                <li>No spaces</li>
              </ul>
            </div>
            <div className={style.action}>
              <button
                onClick={() => {
                  SetConditionPopup(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Member;
