import React, { useEffect, useRef, useState } from "react";
import style from "./Organization.module.css";
import { useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineViewGrid } from "react-icons/hi";
import Loader from "../Loader/Loader";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { baseUrl } from "../Url";

const Organization = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [editName, setEditName] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [description, setDescription] = useState("");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const [orgData, setOrgData] = useState([]);
  const [orgEdit, setOrgEdit] = useState(false);
  const [orgnizationDelete, setOrganizationDelete] = useState(false);
  const [orgDescription, setOrgDescription] = useState("");
  const [orgId, setOrgId] = useState("");
  const [deleteOrgId, setDeleteOrgId] = useState("");
  const [loading, setLoading] = useState(true);
  const [logoImage, setLogoImage] = useState("");
  const [imageSelected, setImageSelected] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [orgSuccess, setOrgSuccess] = useState(false);
  const [orgFail, setOrgFail] = useState(false);
  const [orgDeletion, setOrgDeletion] = useState(false);
  const [orgUpdation, setOrgUpdation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);

  const checkLoginExpiry = () => {
    const loginTime = localStorage.getItem("loginTime");
    if (loginTime) {
      const loginDate = new Date(loginTime);
      const currentTime = new Date();
      const timeDifference = currentTime - loginDate;
      const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
      if (timeDifference >= twentyFourHoursInMillis) {
        alert("Your session has expired. Please log in again.");
        localStorage.clear();
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    checkLoginExpiry();
  }, []);

  const adminStatus = localStorage.getItem("adminStatus");
  console.log(adminStatus);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile);
    const img = {
      preview: URL.createObjectURL(selectedFile),
      data: selectedFile,
    };
    if (selectedFile.type === "image/png" || "image/jpeg") {
      setLogoImage(img);
      setImageSelected(true);
    }
    console.log("Uploaded file:", img);
  };

  async function organizationGet() {
    const userId = localStorage.getItem("adminUserId");
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({ user_id: userId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrgData(data.data);
        setLoading(false);
      });
  }
  const validateFields = () => {
    if (!orgName) {
      setErrorMessage("Organization Name is required");
      return false;
    }
    if (!description) {
      setErrorMessage("Description is required");
      return false;
    }

    return true;
  };

  async function OrganisationAdd() {
    let formData = new FormData();
    formData.append("org_name", orgName);
    formData.append("description", description);
    if (logoImage.data) {
      formData.append("url", logoImage.data);
    }

    if (!validateFields()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/organization/add`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            organizationGet();
            setPopup(false);
            setOrgSuccess(true);
            setLogoImage(null);
            setImageSelected(false);
          } else {
            setApiDescription(data.description);
            setOrgFail(true);
          }
        });
    }
  }

  const validateEditFields = () => {
    if (!editName) {
      setErrorMessage("Organization Name is required");
      return false;
    }
    if (!orgDescription) {
      setErrorMessage("Description is required");
      return false;
    }

    return true;
  };

  async function orgUpdate(e) {
    let formData = new FormData();
    formData.append("org_name", editName);
    formData.append("description", orgDescription);
    formData.append("org_id", orgId);
    if (logoImage.data) {
      formData.append("url", logoImage.data);
    }

    if (!validateEditFields()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/organization/update`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            setOrgEdit(false);
            organizationGet();
            setEditName("");
            setOrgDescription("");
            setLogoImage(null);
            setImageSelected(false);
            setOrgUpdation(true);
          } else {
            setApiDescription(data.description);
            setOrgFail(true);
          }
        });
    }
  }

  async function orgDelete() {
    await fetch(`${baseUrl}/organization/delete`, {
      method: "POST",
      body: JSON.stringify({
        org_id: deleteOrgId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          organizationGet();
          setOrgDeletion(true);
        } else {
          setApiDescription(data.description);
          setOrgFail(true);
        }
      });
  }

  useEffect(() => {
    organizationGet();
  }, [key]);

  return (
    <div className={style.Container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={orgSuccess || orgDeletion || orgUpdation}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setOrgSuccess(false);
          setOrgDeletion(false);
          setOrgUpdation(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {orgSuccess
            ? "Organization created successfully"
            : orgDeletion
            ? "Organization deleted successfully"
            : orgUpdation
            ? "Organisation updated successfully"
            : ""}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={orgFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setOrgFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Organizations</p>
        </div>
        <div className={style.HeadButton}>
          {adminStatus === "true" ? (
            <button
              onClick={() => {
                setPopup(true);
              }}
            >
              Create
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className={style.orgSection}>
          <div className={style.Grid}>
            {orgData && orgData.length > 0
              ? orgData.map((item, index) => {
                  return (
                    <div key={index} className={style.Cards}>
                      <div className={style.orgLogo}>
                        <img
                          src={
                            item.url
                              ? item.url
                              : "https://media.istockphoto.com/id/931643150/vector/picture-icon.jpg?s=612x612&w=0&k=20&c=St-gpRn58eIa8EDAHpn_yO4CZZAnGD6wKpln9l3Z3Ok="
                          }
                          alt=""
                        />
                      </div>
                      <div className={style.cardTop}>
                        <p>{item.org_name}</p>
                      </div>
                      <div className={style.cardBottom}>
                        <Tooltip title="View">
                          <IconButton>
                            <HiOutlineViewGrid
                              className={style.viewButton}
                              onClick={() => {
                                navigate("/club", {
                                  state: {
                                    organization: item.org_id,
                                    orgName: item.org_name,
                                  },
                                });
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Edit">
                          <IconButton>
                            <BiEdit
                              className={style.editButton}
                              onClick={() => {
                                setOrgEdit(true);
                                setEditName(item.org_name);
                                setOrgDescription(item.description);
                                setOrgId(item.org_id);
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        {adminStatus === "true" ? (
                          <Tooltip title="Delete">
                            <IconButton>
                              <RiDeleteBinLine
                                className={style.deleteButton}
                                onClick={() => {
                                  setOrganizationDelete(true);
                                  setDeleteName(item.org_name);
                                  setDeleteOrgId(item.org_id);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      )}

      {popup ? (
        <>
          <div
            onClick={() => {
              setPopup(false);
              setLogoImage(null);
              setImageSelected(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setPopup(false);
                }}
              />
            </div>
            <div action="" className={style.popupSection}>
              <div className={style.sectionTop}>
                <div className={style.orgName}>
                  <label htmlFor="">Org Name</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    required
                    onChange={(e) => {
                      setOrgName(e.target.value);
                    }}
                    placeholder=""
                  />
                </div>
                <div className={style.description}>
                  <label htmlFor="">Description</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    required
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    placeholder=""
                  />
                </div>
              </div>
              <div className={style.sectionBottom}>
                <label htmlFor="" onClick={handleClick}>
                  Org Logo
                  <MdOutlineDriveFolderUpload />
                </label>
                <input
                  ref={fileInputRef}
                  required
                  type="file"
                  name=""
                  id="fileInput"
                  onChange={handleFileUpload}
                  accept="image/png, image/jpeg"
                />
                {imageSelected ? <p> {logoImage.data.name}</p> : ""}
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.create}
                onClick={() => {
                  OrganisationAdd();
                }}
              >
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setPopup(false);
                  setLogoImage(null);
                  setImageSelected(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {orgEdit ? (
        <>
          <div
            onClick={() => {
              setOrgEdit(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setOrgEdit(false);
                }}
              />
            </div>
            <div action="" className={style.popupSection}>
              <div className={style.sectionTop}>
                <div className={style.orgName}>
                  <label htmlFor="">Org Name</label>
                  <input
                    type="text"
                    value={editName}
                    required
                    onChange={(e) => {
                      setEditName(e.target.value);
                    }}
                    placeholder="Organization"
                  />
                </div>
                <div className={style.description}>
                  <label htmlFor="">Description</label>
                  <input
                    type="text"
                    value={orgDescription}
                    required
                    onChange={(e) => {
                      setOrgDescription(e.target.value);
                    }}
                    placeholder="Description"
                  />
                </div>
              </div>
              <div className={style.sectionBottom}>
                <label htmlFor="" onClick={handleClick}>
                  Org Logo
                  <MdOutlineDriveFolderUpload />
                </label>
                <input
                  ref={fileInputRef}
                  type="file"
                  name=""
                  id="fileInput"
                  onChange={handleFileUpload}
                  accept="image/png, image/jpeg"
                />
                {imageSelected ? <p> {logoImage.data.name}</p> : ""}
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.create}
                onClick={() => {
                  orgUpdate();
                }}
              >
                Update
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setOrgEdit(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {orgnizationDelete ? (
        <>
          <div
            onClick={() => {
              setOrganizationDelete(false);
              setDeleteName("");
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setOrganizationDelete(false);
                  setDeleteName("");
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>
                  {" "}
                  Are you sure you want to delete <span>{deleteName}</span>?
                </p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    orgDelete();
                    setDeleteName("");
                    setOrganizationDelete(false);
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setOrganizationDelete(false);
                    setDeleteName("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Organization;
