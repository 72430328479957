import React from "react";
import style from "./Register.module.css";
const Register = () => {
  return (
    <div className={style.Container}>
      <div className={style.Register}>
        <form className={style.form}>
          <div className={style.Header}>
            <label className={style.formText}>Sign Up</label>
          </div>
          <input
            className={style.Input}
            type="text"
            placeholder="Username"
            required
          />
          <input
            className={style.Input}
            type="text"
            placeholder="Mobilenumber"
            required
          />
          <input
            className={style.Input}
            type="password"
            placeholder="Password"
            required
          />
          <input
            className={style.Input}
            type="password"
            placeholder="Confirmpassword"
            required
          />
          <input
            className={style.Input}
            type="text"
            placeholder="Email"
            required
          />

          <button type="submit" className={style.formButton}>
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
