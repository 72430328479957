import React, { useState } from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import style from './Myeditor.module.css'

function MyEditor() {
  const [editorData, setEditorData] = useState('');

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  return (
    <div className={style.MyEditor}>
      {/* <h2>CKEditor in React</h2> */}
      <CKEditor 
        editor={ClassicEditor}
        data={editorData}
        onChange={handleEditorChange}
        config={{
            toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
            alignment: {
              options: ['left', 'center', 'right'],
            },
          }}
      />
    </div>
  );
}

export default MyEditor;
