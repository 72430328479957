import React, { useState, useEffect } from "react";
import style from "./Sidebar.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../action/User";
import logo from "../../assets/duno club  title logo inverse.png";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { CgOrganisation } from "react-icons/cg";
import { BsBookmarksFill } from "react-icons/bs";
import { PiUsersThreeFill } from "react-icons/pi";
import { BiLogOutCircle } from "react-icons/bi";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { RiBillLine } from "react-icons/ri";
import { AiFillCreditCard } from "react-icons/ai";
import { AiFillTrophy, AiOutlineClose } from "react-icons/ai";
import { MdQuiz, MdEventAvailable } from "react-icons/md";
import { GrGallery } from "react-icons/gr";
import { SlBadge } from "react-icons/sl";
import { FcPlanner } from "react-icons/fc";

const Sidebar = ({ }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profileData);

  const key = JSON.parse(localStorage.getItem("access-token"));

  const [logout, setLogout] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const storedUserRole = localStorage.getItem("adminStatus");
    if (storedUserRole !== null) {
      setUserRole(storedUserRole);
    }
  }, []);

  const handleLogout = (e) => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("adminStatus");
    localStorage.removeItem("selectedOrgID");
    localStorage.removeItem("adminUserId");
    localStorage.removeItem("adminUserName");
    navigate("/login");
    window.location.reload();
  };

  const handleCategorySelection = (category) => {
    navigate(`/${category}`);
    setSelectedCategory(category);
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === "/organization") setSelectedCategory("organization");
    else if (path === "/learning") setSelectedCategory("learning");
    else if (path === "/lesson/create/admin") setSelectedCategory("learning");
    else if (path === "/asessments") setSelectedCategory("learning");
    else if (path === "/asessments/creation") setSelectedCategory("learning");
    else if (path === "/exams") setSelectedCategory("learning");
    else if (path === "/exams/creation") setSelectedCategory("learning");
    else if (path === "/user") setSelectedCategory("user");
    else if (path === "/pricing") setSelectedCategory("pricing");
    else if (path === "/pricing/actions") setSelectedCategory("pricing");
    else if (path === "/billing") setSelectedCategory("billing");
    else if (path === "/invoice") setSelectedCategory("billing");
    else if (path === "/reward") setSelectedCategory("reward");
    else if (path === "/events") setSelectedCategory("events");
    else if (path === "/quiz") setSelectedCategory("quiz");
    else if (path === "/quiz/edit") setSelectedCategory("quiz");
    else if (path === "/quiz/create") setSelectedCategory("quiz");
    else if (path === "/club")
      setSelectedCategory(userRole === "true" ? "organization" : "club");
    else if (path === "/members")
      setSelectedCategory(userRole === "true" ? "organization" : "club");
    else if (path === "/user/mapping")
      setSelectedCategory(userRole === "true" ? "organization" : "club");
    else if (path === "/gallery") setSelectedCategory("gallery");
    else if (path === "/performance") setSelectedCategory("user");
  }, [location, userRole]);

  useEffect(() => {
    dispatch(UserProfile());
  }, [key]);

  return (
    <div className={style.Sidebar}>
      <div className={style.Header}>
        <div className={style.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={style.userName}>
          <p>{profile?.Username}</p>
        </div>
      </div>
      <div className={style.Menu}>
        {userRole === true || userRole === "true" ? (
          <div
            className={
              selectedCategory === "organization"
                ? style.selected
                : style.menuIcons
            }
            onClick={() => handleCategorySelection("organization")}
          >
            <CgOrganisation />
            <p>Organizations</p>
          </div>
        ) : userRole === false || userRole === "false" ? (
          <div
            className={
              selectedCategory === "club" ? style.selected : style.menuIcons
            }
            onClick={() => handleCategorySelection("club")}
          >
            <CgOrganisation />
            <p>Club/Batch</p>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            selectedCategory === "learning" ? style.selected : style.menuIcons
          }
          onClick={() => handleCategorySelection("learning")}
        >
          <BsBookmarksFill />
          <p>Learnings</p>
        </div>
        <div
          className={
            selectedCategory === "user" ? style.selected : style.menuIcons
          }
          onClick={() => handleCategorySelection("user")}
        >
          <PiUsersThreeFill />
          <p>Users</p>
        </div>
        <div
          className={
            selectedCategory === "gallery" ? style.selected : style.menuIcons
          }
          onClick={() => handleCategorySelection("gallery")}
        >
          <GrGallery />
          <p>Gallery</p>
        </div>

        <div
          className={
            selectedCategory === "quiz" ? style.selected : style.menuIcons
          }
          onClick={() => handleCategorySelection("quiz")}
        >
          <MdQuiz />
          <p>Quiz</p>
        </div>

        <div
          className={
            selectedCategory === "planning" ? style.selected : style.menuIcons
          }
          onClick={() => handleCategorySelection("planning")}
        >
          <FcPlanner />
          <p>Session Plan</p>
        </div>

        <div
          className={
            selectedCategory === "events" ? style.selected : style.menuIcons
          }
          onClick={() => handleCategorySelection("events")}
        >
          <MdEventAvailable />
          <p>Events</p>
        </div>

        <div
          className={
            selectedCategory === "reward" ? style.selected : style.menuIcons
          }
          onClick={() => handleCategorySelection("reward")}
        >
          <AiFillTrophy />
          <p>Reward </p>
        </div>

        <div
          className={
            selectedCategory === "pricing" ? style.selected : style.menuIcons
          }
          onClick={() => handleCategorySelection("pricing")}
        >
          <FaIndianRupeeSign />
          <p>Pricing</p>
        </div>
        <div
          className={
            selectedCategory === "subscription"
              ? style.selected
              : style.menuIcons
          }
          onClick={() => handleCategorySelection("subscription")}
        >
          <AiFillCreditCard />
          <p>Subscription</p>
        </div>

        <div
          className={
            selectedCategory === "billing" ? style.selected : style.menuIcons
          }
          onClick={() => handleCategorySelection("billing")}
        >
          <RiBillLine />
          <p>Billing</p>
        </div>
        <div
          className={style.menuIcons}
          onClick={() => {
            setLogout(true);
          }}
        >
          <BiLogOutCircle />
          <p>Logout</p>
        </div>
      </div>
      {logout ? (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={logout}
            onClose={() => {
              setLogout(false);
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
          >
            <div className={style.Popup}>
              <div className={style.popupClose}>
                <AiOutlineClose
                  onClick={() => {
                    setLogout(false);
                  }}
                />
              </div>
              <div className={style.popupSection}>
                <div className={style.popupText}>
                  <p>Are you sure you want to logout?</p>
                </div>
                <div className={style.PopupButton}>
                  <button
                    onClick={(e) => {
                      handleLogout();
                    }}
                  >
                    YES
                  </button>
                  <button
                    onClick={() => {
                      setLogout(false);
                    }}
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        ""
      )}{" "}
    </div>
  );
};

export default Sidebar;
