import React,{useEffect, useState} from 'react'
import style from './Pricing.module.css'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../action/User";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useNavigate,useLocation } from 'react-router-dom';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { baseUrl } from "../Url";

const Pricing = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const[createCourseFeature,setCreateCourseFeature]=useState(false)
    const[createMentorFeature,setCreateMentorFeature]=useState(false)
    const[createBatchFeature,setCreateBatchFeature]=useState(false)
    const[editCourseFeature,setEditCourseFeature]=useState(false)
    const[editMentorfeature,setEditMentorFeature]=useState(false)
    const[editBatchFeature,setEditBatchFeature]=useState(false)
    const[deleteCoursePayment,setDeleteCoursePayment]=useState(false)
    const[deleteMentorPayment,setDeleteMentorPayment]=useState(false)
    const[deleteBatchPayment,setDeleteBatchPayment]=useState(false)
     const[deleteCourseFeature,setDeleteCourseFeature]=useState(false)
     const[deleteMentorFeature,setDeletementorFeature]=useState(false)
     const[deleteBatchFeature,setDeletebatchFeature]=useState(false)
     const[mentorList,setMentorList]=useState([])
     const[mentorListShow,setMentorListShow]=useState(false)
     const[selectedMentor,setSelectedmentor]=useState("")
     const[selectedMentorName,setSelectedmentorName]=useState("")
     const[mentorPricelist,setMentorPriceList]=useState([])
     const[batchList,setBatchList]=useState([])
     const[batchListShow,setBatchListShow]=useState(false)
     const[batchPricelist,setBatchPriceList]=useState([])
     const[mentorFeatureCode,setMentorFeatureCode]=useState("")
     const[mentorFeatureName,setMentorFeatureName]=useState("")
     const[mentorFeatureList,setMentorFeatureList]=useState([])
     const[selectedMentorFeaturecode,setselectedMentorFeaturecode]=useState("")
     const[mentorTitle,setMentorTitle]=useState("")
     const[mentorShortDec,setMentorShortDec]=useState("")
     const[mentorDec,setMentorDec]=useState("")
     const[editMentorFeatureCode,setEditMentorFeatureCode]=useState("")
     const[editMentorFeatureName,setEditMentorFeatureName]=useState("")
     const[editMentorCurrency,setEditMentorCurrency]=useState("")
     const[editMentorUom,setEditMentorUom]=useState("")
     const[editMentorTitle,setEditMentorTitle]=useState("")
     const[editMentorShortDec,setEditMentorShortDec]=useState("")
     const[editMentorDec,setEditMentorDec]=useState("")
     const[batchFeatureCode,setBatchFeatureCode]=useState("")
     const[batchFeatureName,setBatchFeatureName]=useState("")
     const[batchFeatureList,setBatchFeatureList]=useState([])
     const[selectedBatchFeaturecode,setselectedBatchFeaturecode]=useState("")
     const[batchTitle,setBatchTitle]=useState("")
     const[batchShortDec,setBatchShortDec]=useState("")
     const[batchDec,setBatchDec]=useState("")
     const[editBatchFeatureCode,setEditBatchFeatureCode]=useState("")
     const[editBatchFeatureName,setEditBatchFeatureName]=useState("")
     const[editBatchCurrency,setEditBatchCurrency]=useState("")
     const[editBatchUom,setEditBatchUom]=useState("")
     const[editBatchTitle,setEditBatchTitle]=useState("")
     const[editBatchShortDec,setEditBatchShortDec]=useState("")
     const[editBatchDec,setEditBatchDec]=useState("")
     const[selectedBatch,setSelectedBatch]=useState("")
     const[selectedBatchID,setSelectedBatchID]=useState("")
     const[courseList,setCourseList]=useState([])
     const[courseListShow,setCourseListShow]=useState(false)
     const[coursePricelist,setCoursePriceList]=useState([])
     const[selectedCourse,setSelectedCourse]=useState("")
     const[selectedCourseID,setSelectedCourseID]=useState("")
     const[courseFeatureName,setCourseFeatureName]=useState("")
     const[courseFeatureCode,setCourseFeatureCode]=useState("")
     const[courseFeatureList,setCourseFeatureList]=useState([])
     const[selectedCourseFeaturecode,setselectedCourseFeaturecode]=useState("")
     const[courseTitle,setCourseTitle]=useState("")
     const[courseShortDec,setCourseShortDec]=useState("")
     const[courseDec,setCourseDec]=useState("")
     const[editCourseFeatureCode,setEditCourseFeatureCode]=useState("")
     const[editCourseFeatureName,setEditCourseFetureName]=useState("")
     const[editCourseCurrency,setEditCourseCurrency]=useState("")
     const[editCourseUom,setEditCourseUom]=useState("")
     const[editCourseTitle,setEditCourseTitle]=useState("")
     const[editCourseShortDec,setEditCourseShortDec]=useState("")
     const[editCourseDec,setEditCourseDec]=useState("")
     const[courseReadonnly,setCourseReadOnly]=useState(true)
     const[courseClub,setCourseClub]=useState('')
     const[courseClubName,setCourseClubName]=useState('')
     const[mentorPrizingid,setMentorPrizingID]=useState("")
     const[clubPrizingid,setClubPrizingID]=useState("")
     const[coursePrizingid,setCoursePrizingID]=useState("")
     const [errors, setErrors] = useState({});
     const[featureList,setFeatureList]=useState([]) 
     const [selectedFeatures, setSelectedFeatures] = useState([]);
     const [selectedFeaturesEdit, setSelectedFeaturesEdit] = useState([]);
     const [selectedBatchFeatures, setSelectedBatchFeatures] = useState([]);
     const [selectedBatchFeaturesEdit, setSelectedBatchFeaturesEdit] = useState([]);
     const [selectedCourseFeatures, setSelectedCourseFeatures] = useState([]);
     const [selectedCourseFeaturesEdit, setSelectedCourseFeaturesEdit] = useState([]);

     const navigate=useNavigate()
     const location=useLocation()
     const state=location.state;

     useEffect(()=>{
      if(state!==null){
        setSelectedTab(state.tab)
      }
     },[state])

     const handleMentorEditorChange = (event, editor) => {
      const data = editor.getData();
      setMentorDec(data);
    };
    const handleEditMentorEditorChange = (event, editor) => {
      const data = editor.getData();
      setEditMentorDec(data);
    };
    const handleCourseEditorChange = (event, editor) => {
      const data = editor.getData();
      setCourseDec(data);
    };
    const handleEditCourseEditorChange = (event, editor) => {
      const data = editor.getData();
      setEditCourseDec(data);
    };
    const handlebatchEditorChange = (event, editor) => {
      const data = editor.getData();
      setBatchDec(data);
    };
    const handleEditbatchEditorChange = (event, editor) => {
      const data = editor.getData();
      setEditBatchDec(data);
    };

     const { profile } = useSelector((state) => state.profileData);
     const[orgID,setOrgID]=useState('')

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
      };

      useEffect(()=>{
   setOrgID(profile?.org_id)
      },[profile])
      //featurelist

//courselist
async function getCourses(clubid) {
  await fetch(`${baseUrl}/get/courses`, {
    method: "POST",
    body: JSON.stringify({
      club_id:clubid
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        setCourseReadOnly(false)
       setCourseList(data.data)
      }
    });
}
const validatecoursePriceCreate = () => {
  const errors = {};
  if (courseDec.trim() === "") {
    errors.courseDec = "Description is required";
  }
  if (courseShortDec.trim() === "") {
    errors.courseShortDec = "Shot Description is required";
  }
  if (selectedCourseFeatures.length === 0) {
    errors.selectedCourseFeatures = "Select at least one checkbox";
  }

  setErrors(errors);

  return Object.keys(errors).length === 0;
};
//course price create
async function coursePriceCreate() {
  const body={
      org_id:orgID,
      club_id:courseClub,
      club_name:courseClubName,
      category_id:selectedCourseID,
      category_name:selectedCourse,
      title:courseTitle,
    description:courseDec,
    short_des:courseShortDec,
    features: selectedCourseFeatures.map((featureCode) => {
      const feature = featureList
        .flatMap((module) => module.Features)
        .find((feature) => feature.Featurecode === featureCode);
      return {
        Featurename: feature.Featurename,
        Featurecode: feature.Featurecode,
      };
    }),
  }
  const isFormValid = validatecoursePriceCreate();
  if(isFormValid){
  await fetch(`${baseUrl}/create/course/prizing`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        getCourserPriceList(selectedCourseID)
              setSelectedCourseFeatures([])
              setErrors({})
      }
    });
  }
}
//course price list
async function getCourserPriceList(id) {
  await fetch(`${baseUrl}/individual/course/prizing/details`, {
    method: "POST",
    body: JSON.stringify({
      category_id:id
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       setCoursePriceList(data.data)
       setCourseListShow(true)
      }
    });
}
const validatecoursePriceEdit = () => {
  const errors = {};
  if (editCourseCurrency.trim() === "") {
    errors.editCourseCurrency = "Currency is required";
  }
  if (editCourseUom.trim() === "") {
    errors.editCourseUom = "UOM is required";
  }
  if (editCourseTitle.trim() === "") {
    errors.editCourseTitle = "Title is required";
  }
  if (editCourseDec.trim() === "") {
    errors.editCourseDec = "Description is required";
  }
  if (editCourseShortDec.trim() === "") {
    errors.editCourseShortDec = "Shot Description is required";
  }
  if (selectedCourseFeaturesEdit.length === 0) {
    errors.selectedCourseFeaturesEdit = "Select at least one checkbox";
  }

  setErrors(errors);

  return Object.keys(errors).length === 0;
};
//course price edit
async function coursePriceEdit() {
  const body={
    coursePrizings_id:coursePrizingid,
    currency:editCourseCurrency,
    uom:editCourseUom,
    title:editCourseTitle,
    description:editCourseDec,
    short_des:editCourseShortDec,
    features: selectedCourseFeaturesEdit.map((featureCode) => {
      const feature = featureList
        .flatMap((module) => module.Features)
        .find((feature) => feature.Featurecode === featureCode);
      return {
        Featurename: feature.Featurename,
        Featurecode: feature.Featurecode,
      };
    }),
  }
    const isFormValid = validatecoursePriceEdit();
    if(isFormValid){

    
  await fetch(`${baseUrl}/edit/course/prizing`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        getCourserPriceList(selectedCourseID)
              setEditCourseUom("")
              setEditCourseTitle('')
              setEditCourseShortDec('')
              setEditCourseDec('')
              setSelectedCourseFeaturesEdit([])
      }
    });
  }
}
//course prize delete
async function coursePriceDelete() {
  await fetch(`${baseUrl}/course/prizing/delete`, {
    method: "POST",
    body: JSON.stringify({
      coursePrizings_id:coursePrizingid
      }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        setDeleteCoursePayment(false)
        getCourserPriceList(selectedCourseID)
        setCoursePrizingID("")
      }
    });
}

//mentorlist
async function getMentors() {
  await fetch(`${baseUrl}/get/mentors`, {
    method: "POST",
    body: JSON.stringify({
      org_id:orgID
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        setMentorList(data.data)
      }
    });
}
//mentor price list
async function getMentorPriceList(id) {
  await fetch(`${baseUrl}/individual/mentor/prizing/details`, {
    method: "POST",
    body: JSON.stringify({
      mentor_id:id
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       setMentorListShow(true)
       setMentorPriceList(data.data)
      }
    });
}

//course prize delete
async function mentorPriceDelete() {
  await fetch(`${baseUrl}/mentor/prizing/delete`, {
    method: "POST",
    body: JSON.stringify({
      MentorPrizings_id:mentorPrizingid,
      }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        setDeleteMentorPayment(false)
        getMentorPriceList(selectedMentor)
        setMentorPrizingID("")
      }
    });
}
//clublist
async function getClubs() {
  await fetch(`${baseUrl}/get/batches`, {
    method: "POST",
    body: JSON.stringify({
      org_id:orgID
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        setBatchList(data.data)
      }
    });
}
//club price list
async function getClubPriceList(id) {
  await fetch(`${baseUrl}/individual/batch/prizing/details`, {
    method: "POST",
    body: JSON.stringify({
      club_id:id
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       setBatchListShow(true)
       setBatchPriceList(data.data)
      }
    });
}


//batch prize delete
async function batchPriceDelete() {
  await fetch(`${baseUrl}/batch/prizing/delete`, {
    method: "POST",
    body: JSON.stringify({
      clubPrizings_id:clubPrizingid
      }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        setDeleteBatchPayment(false)
        getClubPriceList(selectedBatchID)
        setClubPrizingID("")
      }
    });
}
async function getFeatures(modulecode) {
  await fetch(`${baseUrl}/fetch/features`, {
    method: "POST",
    body: JSON.stringify({
      ModuleCode:modulecode
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
        if(modulecode==="COURSE"){
          setCourseFeatureList(data.data)
        }
        else if(modulecode==="MENTOR"){
          setMentorFeatureList(data.data)
        }
        else{
          setBatchFeatureList(data.data)
        }
      }
    });
}
async function courseFeaturecreate() {
  await fetch(`${baseUrl}/create/features`, {
    method: "POST",
    body: JSON.stringify({
      ModuleName:"COURSE",
      ModuleCode:"COURSE",
      Features:
        {
          Featurecode:courseFeatureCode,
          Featurename:courseFeatureName
        }
      
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       getFeatures("COURSE")
       setCreateCourseFeature(false)
       setCourseFeatureCode('')
       setCourseFeatureName('')
      }
    });
}
async function mentorFeaturecreate() {
  await fetch(`${baseUrl}/create/features`, {
    method: "POST",
    body: JSON.stringify({
      ModuleName:"MENTOR",
      ModuleCode:"MENTOR",
      Features:
        {
          Featurecode:mentorFeatureCode,
          Featurename:mentorFeatureName
        }
      
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       getFeatures("MENTOR")
       setCreateMentorFeature(false)
       setMentorFeatureCode('')
       setMentorFeatureName('')
      }
    });
}
async function batchFeaturecreate() {
  await fetch(`${baseUrl}/create/features`, {
    method: "POST",
    body: JSON.stringify({
      ModuleName:"BATCH",
      ModuleCode:"BATCH",
      Features:
        {
          Featurecode:batchFeatureCode,
          Featurename:batchFeatureName
        }
      
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       getFeatures("BATCH")
       setCreateBatchFeature(false)
       setBatchFeatureCode('')
       setBatchFeatureName('')
      }
    });
}
async function courseFeatureedit() {
  await fetch(`${baseUrl}/edit/features`, {
    method: "POST",
    body: JSON.stringify({
      ModuleName:"COURSE",
      ModuleCode:"COURSE",
      FeaturesName:editCourseFeatureName,
      FeaturesCode:selectedCourseFeaturecode
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       getFeatures("COURSE")
       setEditCourseFeature(false)
      }
    });
}
async function mentorFeatureedit() {
  await fetch(`${baseUrl}/edit/features`, {
    method: "POST",
    body: JSON.stringify({
      ModuleName:"MENTOR",
      ModuleCode:"MENTOR",
      FeaturesName:editMentorFeatureName,
      FeaturesCode:selectedMentorFeaturecode
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       getFeatures("MENTOR")
       setEditMentorFeature(false)
      }
    });
}
async function batchFeatureedit() {
  await fetch(`${baseUrl}/edit/features`, {
    method: "POST",
    body: JSON.stringify({
      ModuleName:"BATCH",
      ModuleCode:"BATCH",
      FeaturesName:editBatchFeatureName,
      FeaturesCode:selectedBatchFeaturecode
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       getFeatures("BATCH")
       setEditBatchFeature(false)
      }
    });
}

async function courseFeatureDelete() {
  await fetch(`${baseUrl}/delete/feature`, {
    method: "POST",
    body: JSON.stringify({
      ModuleName:"COURSE",
      ModuleCode:"COURSE",
      FeatureCodeToDelete:selectedCourseFeaturecode
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       getFeatures("COURSE")
       setDeleteCourseFeature(false)
      }
    });
}
async function mentorFeatureDelete() {
  await fetch(`${baseUrl}/delete/feature`, {
    method: "POST",
    body: JSON.stringify({
      ModuleName:"MENTOR",
      ModuleCode:"MENTOR",
      FeatureCodeToDelete:selectedMentorFeaturecode
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       getFeatures("MENTOR")
       setDeletementorFeature(false)
      }
    });
}
async function batchFeatureDelete() {
  await fetch(`${baseUrl}/delete/feature`, {
    method: "POST",
    body: JSON.stringify({
      ModuleName:"BATCH",
      ModuleCode:"BATCH",
      FeatureCodeToDelete:selectedBatchFeaturecode
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if(data.status===true){
       getFeatures("BATCH")
       setDeletebatchFeature(false)
      }
    });
}


useEffect(()=>{
  if(orgID!=="" && orgID!==undefined){
    getClubs()
    getFeatures("COURSE")
  }
},[orgID,profile])



  return (
    <div className={style.Container}>
       {createCourseFeature?
    <>
     <div
            className={style.Overlay}
            onClick={() => {
              setCreateCourseFeature(false);
              setErrors({})
              setCourseFeatureCode('')
       setCourseFeatureName('')
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setCreateCourseFeature(false);
                  setErrors({})
                  setCourseFeatureCode('')
       setCourseFeatureName('')
                }}
              />
            </div>
            <div className={style.membersection}>
            
             <div className={style.categoryName}>
             <label htmlFor="">Feature Code</label>
                <input
                  type="text"
                  name=""
                  id="FeatureCode"
                  autoComplete='off'
                  placeholder="FeatureCode"
                  value={courseFeatureCode}
                  onChange={(e)=>{
                    setCourseFeatureCode(e.target.value)
                  }}
                />
             </div>
              <div className={style.categoryDescription}>
              <label htmlFor="">Feature Name</label>
                <input
                  type="text"
                  name=""
                  id="FeatureName"
                  autoComplete='off'
                  placeholder="FeatureName"
                  value={courseFeatureName}
                  onChange={(e)=>{
                    setCourseFeatureName(e.target.value)
                  }}
                />
              </div>
              <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
            </div>
              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    courseFeaturecreate()
                  }}
                >
                  Create
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setCreateCourseFeature(false);
                    setErrors({})
                     setCourseFeatureCode('')
                     setCourseFeatureName('')
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            
          </div>
    </>
    :''}
    {editCourseFeature?
    <>
     <div
            className={style.Overlay}
            onClick={() => {
              setEditCourseFeature(false);
              setErrors({})
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setEditCourseFeature(false);
                  setErrors({})
                }}
              />
            </div>
            <div className={style.membersection}>
            
              <div className={style.categoryName}>
              <label htmlFor="">Feature Code</label>
                <input
                  type="text"
                  name=""
                  id="FeatureCode"
                  autoComplete='off'
                  placeholder="FeatureCode"
                  value={editCourseFeatureCode}
                  readOnly
                  onChange={(e)=>{
                    setEditCourseFeatureCode(e.target.value)
                  }}
                />
              </div>
              <div  className={style.categoryDescription} >
              <label htmlFor="">Feature Name</label>
                <input
                  type="text"
                  name=""
                  id="FeatureName"
                  autoComplete='off'
                  placeholder="FeatureName"
                  value={editCourseFeatureName}
                  onChange={(e)=>{
                    setEditCourseFetureName(e.target.value)
                  }}
                />
              </div>
              <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
            </div>
              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    courseFeatureedit()
                  }}
                >
                  Update
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setEditCourseFeature(false);
              setErrors({})
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            
          </div>
    </>
    :''}
    {deleteCourseFeature?
    <>
    <div
        onClick={() => {
            setDeleteCourseFeature(false);
        }}
        className={style.Overlay}
      ></div>
      <div className={style.deletePopup}>
        <div className={style.deleteClose}>
          <AiOutlineClose
            onClick={() => {
              setDeleteCourseFeature(false);
            }}
          />
        </div>
        <div className={style.deleteSection}>
          <div className={style.popupText}>
            <p>Are you sure you want to delete ?</p>
          </div>
          <div className={style.popupDelete}>
            <button
              className={style.orgDelete}
              onClick={() => {
                courseFeatureDelete()
              }}
            >
              Delete
            </button>
            <button
              className={style.orgDeleteCancel}
              onClick={() => {
                setDeleteCourseFeature(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
    :''}
           {createMentorFeature?
    <>
     <div
            className={style.Overlay}
            onClick={() => {
              setCreateMentorFeature(false);
              setErrors({})
              setMentorFeatureCode('')
       setMentorFeatureName('')
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setCreateMentorFeature(false);
                  setErrors({})
                  setMentorFeatureCode('')
       setMentorFeatureName('')
                }}
              />
            </div>
            <div className={style.membersection}>
            
              <div className={style.categoryName}>
              <label htmlFor="">Feature Code</label>
                <input
                  type="text"
                  name=""
                  id="FeatureCode"
                  autoComplete='off'
                  placeholder="FeatureCode"
                  value={mentorFeatureCode}
                  onChange={(e)=>{
                    setMentorFeatureCode(e.target.value)
                  }}
                />
              </div>
              <div className={style.categoryDescription}>
              <label htmlFor="">Feature Name</label>
                <input
                  type="text"
                  name=""
                  id="FeatureName"
                  autoComplete='off'
                  placeholder="FeatureName"
                  value={mentorFeatureName}
                  onChange={(e)=>{
                    setMentorFeatureName(e.target.value)
                  }}
                />
              </div>
              <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
            </div>
              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                  mentorFeaturecreate()
                  }}
                >
                  Create
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setCreateMentorFeature(false);
                    setErrors({})
                    setMentorFeatureCode('')
       setMentorFeatureName('')
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            
          </div>
    </>
    :''}
    {editMentorfeature?
    <>
     <div
            className={style.Overlay}
            onClick={() => {
              setEditMentorFeature(false);
              setErrors({})
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setEditMentorFeature(false);
                  setErrors({})
                }}
              />
            </div>
            <div className={style.membersection}>
            
              <div className={style.categoryName}>
              <label htmlFor="">Feature Code</label>
                <input
                  type="text"
                  name=""
                  id="FeatureCode"
                  autoComplete='off'
                  placeholder="FeatureCode"
                  value={editMentorFeatureCode}
                  onChange={(e)=>{
                       setEditMentorFeatureCode(e.target.value)      
                                }}
                />
              </div>
             <div className={style.categoryDescription}>
             <label htmlFor="">Feature Name</label>
                <input
                  type="text"
                  name=""
                  id="FeatureName"
                  autoComplete='off'
                  placeholder="FeatureName"
                  value={editMentorFeatureName}
                  onChange={(e)=>{
                    setEditMentorFeatureName(e.target.value)
                  }}
                />
             </div>
              <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
            </div>
              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                  mentorFeatureedit()
                  }}
                >
                  Update
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setEditMentorFeature(false);
              setErrors({})
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            
          </div>
    </>
    :''}
    {deleteMentorFeature?
    <>
    <div
        onClick={() => {
            setDeletementorFeature(false);
        }}
        className={style.Overlay}
      ></div>
      <div className={style.deletePopup}>
        <div className={style.deleteClose}>
          <AiOutlineClose
            onClick={() => {
              setDeletementorFeature(false);
            }}
          />
        </div>
        <div className={style.deleteSection}>
          <div className={style.popupText}>
            <p>Are you sure you want to delete ?</p>
          </div>
          <div className={style.popupDelete}>
            <button
              className={style.orgDelete}
              onClick={() => {
           mentorFeatureDelete()
              }}
            >
              Delete
            </button>
            <button
              className={style.orgDeleteCancel}
              onClick={() => {
                setDeletementorFeature(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
    :''}
           {createBatchFeature?
    <>
     <div
            className={style.Overlay}
            onClick={() => {
              setCreateBatchFeature(false);
              setErrors({})
              setBatchFeatureCode('')
       setBatchFeatureName('')
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setCreateBatchFeature(false);
                  setErrors({})
                  setBatchFeatureCode('')
       setBatchFeatureName('')
                }}
              />
            </div>
            <div className={style.membersection}>
            
              <input
                type="text"
                name=""
                id="FeatureCode"
                autoComplete='off'
                placeholder="FeatureCode"
                value={batchFeatureCode}
                onChange={(e)=>{
                  setBatchFeatureCode(e.target.value)
                }}
              />
              <input
                type="text"
                name=""
                id="FeatureName"
                autoComplete='off'
                placeholder="FeatureName"
                value={batchFeatureName}
                onChange={(e)=>{
                  setBatchFeatureName(e.target.value)
                }}
              />
              <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
            </div>
              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                  batchFeaturecreate()
                  }}
                >
                  Create
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setCreateBatchFeature(false);
              setErrors({})
              setBatchFeatureCode('')
       setBatchFeatureName('')
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            
          </div>
    </>
    :''}
    {editBatchFeature?
    <>
     <div
            className={style.Overlay}
            onClick={() => {
              setEditBatchFeature(false);
              setErrors({})
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setEditBatchFeature(false);
                  setErrors({})
                }}
              />
            </div>
            <div className={style.membersection}>
            
              <input
                type="text"
                name=""
                id="FeatureCode"
                autoComplete='off'
                placeholder="FeatureCode"
                value={editBatchFeatureCode}
                onChange={(e)=>{
                  setEditBatchFeatureCode(e.target.value)
                }}
              />
              <input
                type="text"
                name=""
                id="FeatureName"
                autoComplete='off'
                placeholder="FeatureName"
                value={editBatchFeatureName}
                onChange={(e)=>{
                  setEditBatchFeatureName(e.target.value)
                }}
              />
              <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
            </div>
              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                  batchFeatureedit()
                  }}
                >
                  Update
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setEditBatchFeature(false);
              setErrors({})
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            
          </div>
    </>
    :''}
    {deleteBatchFeature?
    <>
    <div
        onClick={() => {
            setDeletebatchFeature(false);
        }}
        className={style.Overlay}
      ></div>
      <div className={style.deletePopup}>
        <div className={style.deleteClose}>
          <AiOutlineClose
            onClick={() => {
              setDeletebatchFeature(false);
            }}
          />
        </div>
        <div className={style.deleteSection}>
          <div className={style.popupText}>
            <p>Are you sure you want to delete ?</p>
          </div>
          <div className={style.popupDelete}>
            <button
              className={style.orgDelete}
              onClick={() => {
            batchFeatureDelete()
              }}
            >
              Delete
            </button>
            <button
              className={style.orgDeleteCancel}
              onClick={() => {
                setDeletebatchFeature(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
    :''}
        
    {deleteCoursePayment?
    <>
    <div
        onClick={() => {
            setDeleteCoursePayment(false);
        }}
        className={style.Overlay}
      ></div>
      <div className={style.deletePopup}>
        <div className={style.deleteClose}>
          <AiOutlineClose
            onClick={() => {
              setDeleteCoursePayment(false);
            }}
          />
        </div>
        <div className={style.deleteSection}>
          <div className={style.popupText}>
            <p>Are you sure you want to delete ?</p>
          </div>
          <div className={style.popupDelete}>
            <button
              className={style.orgDelete}
              onClick={() => {
            coursePriceDelete()
              }}
            >
              Delete
            </button>
            <button
              className={style.orgDeleteCancel}
              onClick={() => {
                setDeleteCoursePayment(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
    :''}
    
    {deleteMentorPayment?
    <>
    <div
        onClick={() => {
            setDeleteMentorPayment(false);
        }}
        className={style.Overlay}
      ></div>
      <div className={style.deletePopup}>
        <div className={style.deleteClose}>
          <AiOutlineClose
            onClick={() => {
              setDeleteMentorPayment(false);
            }}
          />
        </div>
        <div className={style.deleteSection}>
          <div className={style.popupText}>
            <p>Are you sure you want to delete ?</p>
          </div>
          <div className={style.popupDelete}>
            <button
              className={style.orgDelete}
              onClick={() => {
                mentorPriceDelete()
              }}
            >
              Delete
            </button>
            <button
              className={style.orgDeleteCancel}
              onClick={() => {
                setDeleteMentorPayment(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
    :''}
   
{deleteBatchPayment?
    <>
    <div
        onClick={() => {
            setDeleteBatchPayment(false);
        }}
        className={style.Overlay}
      ></div>
      <div className={style.deletePopup}>
        <div className={style.deleteClose}>
          <AiOutlineClose
            onClick={() => {
              setDeleteBatchPayment(false);
            }}
          />
        </div>
        <div className={style.deleteSection}>
          <div className={style.popupText}>
            <p>Are you sure you want to delete ?</p>
          </div>
          <div className={style.popupDelete}>
            <button
              className={style.orgDelete}
              onClick={() => {
              batchPriceDelete()
              }}
            >
              Delete
            </button>
            <button
              className={style.orgDeleteCancel}
              onClick={() => {
                setDeleteBatchPayment(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
    :''}
    

      <div className={style.tabSection}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#83b516" } }}
          value={selectedTab}
          onChange={handleTabChange}
          centered
        >
          <Tab
            label="Course pricing"
            sx={{
              "&.Mui-selected": {
                color: "#3e61a3",
                backgroundColor: "",
              },
              fontSize: "1.25rem",
              color: "#1a1e5d",
              backgroundColor: "",
              width: "500px",
              margin: "0 .5rem",
              borderRadius: "10px",
            }}
            onClick={()=>{
              getFeatures("COURSE")
              setMentorListShow(false)
              setBatchListShow(false)
            }}
          />
          <Tab
            label="Mentor pricing"
            sx={{
              "&.Mui-selected": {
                color: "#3e61a3",
                backgroundColor: "",
              },
              fontSize: "1.25rem",
              color: "#1a1e5d",
              backgroundColor: "",
              width: "500px",
              margin: "0 .5rem",
              borderRadius: "10px",
            }}
            onClick={()=>{
              getMentors()
              setCourseListShow(false)
              setBatchListShow(false)
              setCourseReadOnly(true)
              getFeatures("MENTOR")
            }}
            
          />
          {/* <Tab
            label="Batch pricing"
            sx={{
              "&.Mui-selected": {
                color: "#3e61a3",
                backgroundColor: "",
              },
              fontSize: "1.25rem",
              color: "#1a1e5d",
              backgroundColor: "",
              width: "500px",
              margin: "0 .5rem",
              borderRadius: "10px",
            }}
            onClick={()=>{
              getClubs()
              setCourseListShow(false)
              setMentorListShow(false)
              setCourseReadOnly(true)
              getFeatures("BATCH")
            }}
          /> */}
        </Tabs>
      </div>
      <Typography>
        {selectedTab===0&&(
            <div>
                 <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Pricing List</p>
        </div>
        {courseListShow?
        <div className={style.HeadButton}>
          
            <button
            onClick={()=>{
              // setCreateCoursePayment(true)
              // getFeatures("COURSE")
              navigate("/pricing/actions",{
                state:{
                  module:"Course",
                  type:"create",
                  clubid:courseClub,
                  clubname:courseClubName,
                  course:selectedCourse,
                  courseid:selectedCourseID,
                  name:selectedCourse
                  
                }
              })
            }}
            >
              Create
            </button>
          
        </div>
        :''}
      </div>
      <div className={style.select_section}>
        <div className={style.org_select}>
          <FormControl sx={{ width: "50%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select Club/Batch
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Club/Batch"
                onChange={(e) => {
                  const clubID = e.target.value;
                  getCourses(clubID)
                  setCourseClub(clubID)
                  const selectedBatch = batchList.find(batch => batch._id === clubID);
      const clubName = selectedBatch ? selectedBatch.ClubName : '';
      console.log(clubName);
      setCourseClubName(clubName);
                }}
              >
                {batchList && batchList.length > 0 ? (
                  batchList.map((batch) => (
                    <MenuItem key={batch._id} value={batch._id}>
                      {batch.ClubName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Club available</MenuItem>
                )}
              </Select>
            </FormControl>
          
        </div>
        <div className={style.club_select}>
        <FormControl sx={{ width: "50%", margin: "0 0.25rem", }}>
              <InputLabel id="demo-simple-select-label">
                Select Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Category"
                disabled={courseReadonnly}
                onChange={(e) => {
                  const CategoryID = e.target.value;
                  setSelectedCourseID(CategoryID)
                  getCourserPriceList(CategoryID)
                  const selectedcourse = courseList.find(course => course._id === CategoryID);
      const categoryName = selectedcourse ? selectedcourse.Name : '';
      setSelectedCourse(categoryName);
                }}
              >
                {courseList && courseList.length > 0 ? (
                  courseList.map((course) => (
                    <MenuItem key={course._id} value={course._id}>
                      {course.Name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Category available</MenuItem>
                )}
              </Select>
            </FormControl>
          
          
        </div>
      </div>

      <div>
        {courseListShow?
        <Table stickyHeader sx={{borderCollapse:"collapse"}} aria-label="sticky table">
               <TableHead>
                  <TableRow>
                  <TableCell
                      key="batch"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Batch/Club
                    </TableCell>
                    <TableCell
                      key="course"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Course
                    </TableCell>
                    <TableCell
                      key="country"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Country
                    </TableCell>
                    <TableCell
                      key="rate"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                     Rate
                    </TableCell>
                    <TableCell
                      key="currency"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Currency
                    </TableCell>
                    <TableCell
                      key="uom"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      UOM
                    </TableCell>
                    <TableCell
                      key="actions"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
               </TableHead>
                <TableBody>
                  {coursePricelist&&coursePricelist.length>0?coursePricelist.map((course,index)=>{
                    return(
                      <TableRow 
                       key={index}>
                          <TableCell
                           key="club"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}
                          >{course.club_name}</TableCell>
                          <TableCell
                           key="category"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}
                          >{course.category_name}</TableCell>
                          <TableCell
                           key="country"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}
                          >{course.country}</TableCell>
                          <TableCell
                           key="rate"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                           }}
                          >{course.rate}</TableCell>
                          <TableCell
                           key="currency"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}
                          >{course.currency}</TableCell>
                          <TableCell
                           key="uom"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}
                          >{course.uom}</TableCell>
                          <TableCell
                           key="action"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}
                          >
                            <div className={style.Buttons}>
                              <div className={style.iconContainer}>
                                <BiEdit
                                  className={style.editButton}
                                  onClick={()=>{
                                    navigate("/pricing/actions",{
                                      state:{
                                        module:"Course",
                                        type:"edit",
                                        pricingid:course.coursePrizings_id
                                      }
                                    })
                                  }}
                                />
                                <span className={style.tooltipText}>Edit</span>
                              </div>
                              <div className={style.iconContainer}>
                                <RiDeleteBinLine
                                  className={style.deleteButton}
                                  onClick={()=>{
                                    setDeleteCoursePayment(true)
                                    setCoursePrizingID(course.coursePrizings_id)
                                  }}
                                />
                                <span className={style.tooltipText}>Delete</span>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                    )
                  }):''}
                </TableBody>
                        
              </Table>
              :''}
      </div>
      <div className={style.FeatureList}>
        <div className={style.FeatureHead}>
          <p>Features</p>
        </div>
        <Table stickyHeader sx={{borderCollapse:"collapse"}} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
              key="featurecode"
              align="center"
              style={{
                fontSize: "1.25rem",
                fontWeight: "600",
                color: "#535370",
                background: "#f8faff",
                borderRight: "2px solid  #dedede",
                zIndex: "0",
              }}
              >Feature Code</TableCell>
              <TableCell
              key="featurename"
              align="center"
              style={{
                fontSize: "1.25rem",
                fontWeight: "600",
                color: "#535370",
                background: "#f8faff",
                borderRight: "2px solid  #dedede",
                zIndex: "0",
              }}
              >Feature Name</TableCell>
              <TableCell
              key="action"
              align="center"
              style={{
                fontSize: "1.25rem",
                fontWeight: "600",
                color: "#535370",
                background: "#f8faff",
                borderRight: "2px solid  #dedede",
                zIndex: "0",
              }}
              >Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courseFeatureList&&courseFeatureList.length>0?courseFeatureList[0]?.Features.map((feature)=>{
              return(
                <TableRow>
              <TableCell
                           key="featurecode"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>{feature.Featurecode}</TableCell>
              <TableCell
                           key="featurename"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>{feature.Featurename}</TableCell>
              <TableCell
                           key="action"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}><div className={style.Buttons}>
                            <div className={style.iconContainer}>
                              <BiEdit
                                className={style.editButton}
                                onClick={()=>{
                                  setEditCourseFeature(true)
                                  setselectedCourseFeaturecode(feature.Featurecode)
                                  setEditCourseFeatureCode(feature.Featurecode)
                                  setEditCourseFetureName(feature.Featurename)
                                }}
                              />
                              <span className={style.tooltipText}>Edit</span>
                            </div>
                            <div className={style.iconContainer}>
                              <RiDeleteBinLine
                                className={style.deleteButton}
                                onClick={()=>{
                                  setDeleteCourseFeature(true)
                                  setselectedCourseFeaturecode(feature.Featurecode)
                                }}
                              />
                              <span className={style.tooltipText}>Delete</span>
                            </div>
                          </div></TableCell>
            </TableRow>
              )
            }):''}
            
          </TableBody>
        </Table>
        <div className={style.Featurebutton}>
        <button onClick={()=>{
          setCreateCourseFeature(true)
        }}>Create Feature</button>
        </div>
      </div>
            </div>
        )}
        {selectedTab===1&&(
            <div>
                 <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Pricing List</p>
        </div>
        {mentorListShow?
        <div className={style.HeadButton}>
          
            <button
            onClick={()=>{
                // setCreateMentorPayment(true)
                getFeatures("MENTOR")
                navigate("/pricing/actions",{
                  state:{
                    module:"Mentor",
                    type:"create",
                    mentorid:selectedMentor,
                    menotorname:selectedMentorName,
                    name:selectedMentorName
                  }
                })
            }}
            >
              Create
            </button>
          
        </div>
        :''}
      </div>
      <div className={style.select_section}>
        <div className={style.org_select}>
        <FormControl sx={{ width: "50%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select Mentor
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Mentor"
                onChange={(e) => {
                  const mentorID = e.target.value;
                  const selectedmentorname = mentorList.find(mentor => mentor._id === mentorID);
                   const userName = selectedmentorname ? selectedmentorname.Username : '';
                  getMentorPriceList(mentorID)
                  setSelectedmentorName(userName)
                 
                }}
              >
                {mentorList && mentorList.length > 0 ? (
                  mentorList.map((mentor) => (
                    <MenuItem key={mentor._id} value={mentor._id}>
                      {mentor.Username}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Mentor available</MenuItem>
                )}
              </Select>
            </FormControl>
          
        </div>
        
      </div>
      <div>
        {mentorListShow?
        <Table stickyHeader sx={{borderCollapse:"collapse"}} aria-label="sticky table">
               <TableHead>
                  <TableRow>
                    <TableCell
                      key="mentor"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}>MentorName</TableCell>
                    <TableCell
                      key="country"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}>Country</TableCell>
                    <TableCell
                      key="rate"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}>Rate</TableCell>
                    <TableCell
                      key="currency"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}>Currency</TableCell>
                    <TableCell
                      key="uom"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}>UOM</TableCell>
                    <TableCell
                      key="actions"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}>Actions</TableCell>
                  </TableRow>
               </TableHead>
                <TableBody>
                  {mentorPricelist&&mentorPricelist.length>0?mentorPricelist.map((price,index)=>{
                    return(
                      <TableRow key={index}>
                          <TableCell
                           key="mentor"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>{price.mentor_name}</TableCell>
                          <TableCell
                           key="country"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>{price.country}</TableCell>
                          <TableCell
                           key="rate"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>{price.rate}</TableCell>
                          <TableCell
                           key="currency"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>{price.currency}</TableCell>
                          <TableCell
                           key="uom"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>{price.uom}</TableCell>
                          <TableCell
                           key="actions"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>
                            <div className={style.Buttons}>
                              <div className={style.iconContainer}>
                                <BiEdit
                                  className={style.editButton}
                                  onClick={()=>{
                                
                                    navigate("/pricing/actions",{
                                      state:{
                                        module:"Mentor",
                                        type:"edit",
                                        pricingid:price.MentorPrizings_id
                                      }
                                    })
                                  }}
                                />
                                <span className={style.tooltipText}>Edit</span>
                              </div>
                              <div className={style.iconContainer}>
                                <RiDeleteBinLine
                                  className={style.deleteButton}
                                  onClick={()=>{
                                    setDeleteMentorPayment(true)
                                    setMentorPrizingID(price.MentorPrizings_id)
                                  }}
                                />
                                <span className={style.tooltipText}>Delete</span>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                    )
                  }):''}
                        
                </TableBody>
                      
              </Table>
              :''}
      </div>
      <div className={style.FeatureList}>
        <div className={style.FeatureHead}>
          <p>Features</p>
        </div>
        <Table stickyHeader sx={{borderCollapse:"collapse"}} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
              key="featurecode"
              align="center"
              style={{
                fontSize: "1.25rem",
                fontWeight: "600",
                color: "#535370",
                background: "#f8faff",
                borderRight: "2px solid  #dedede",
                zIndex: "0",
              }}>Feature Code</TableCell>
              <TableCell
              key="featurename"
              align="center"
              style={{
                fontSize: "1.25rem",
                fontWeight: "600",
                color: "#535370",
                background: "#f8faff",
                borderRight: "2px solid  #dedede",
                zIndex: "0",
              }}>Feature Name</TableCell>
              <TableCell
              key="action"
              align="center"
              style={{
                fontSize: "1.25rem",
                fontWeight: "600",
                color: "#535370",
                background: "#f8faff",
                borderRight: "2px solid  #dedede",
                zIndex: "0",
              }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {mentorFeatureList&&mentorFeatureList.length>0?mentorFeatureList[0]?.Features.map((feature)=>{
            return(
              <TableRow>
              <TableCell
                           key="featurecode"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>{feature.Featurecode}</TableCell>
              <TableCell
                           key="featurename"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}>{feature.Featurename}</TableCell>
              <TableCell
                           key="action"
                           align="left"
                           style={{
                             border: "1px solid #e8e4ef",
                             fontSize: "1rem",
                             padding:"8px"
                           }}><div className={style.Buttons}>
                            <div className={style.iconContainer}>
                              <BiEdit
                                className={style.editButton}
                                onClick={()=>{
                                  setEditMentorFeature(true)
                                  setEditMentorFeatureCode(feature.Featurecode)
                                  setEditMentorFeatureName(feature.Featurename)
                                  setselectedMentorFeaturecode(feature.Featurecode)
                                }}
                              />
                              <span className={style.tooltipText}>Edit</span>
                            </div>
                            <div className={style.iconContainer}>
                              <RiDeleteBinLine
                                className={style.deleteButton}
                                onClick={()=>{
                                  setDeletementorFeature(true)
                                  setselectedMentorFeaturecode(feature.Featurecode)
                                }}
                              />
                              <span className={style.tooltipText}>Delete</span>
                            </div>
                          </div></TableCell>
            </TableRow>
            )
          }):''}
          </TableBody>
        </Table>
        <div className={style.Featurebutton}>
        <button onClick={()=>{
          setCreateMentorFeature(true)
        }}>Create Feature</button>
        </div>
      </div>
     
            </div>
        )}
        {selectedTab===2&&(
            <div>
                 <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Pricing List</p>
        </div>
        {batchListShow?
        <div className={style.HeadButton}>
            <button
            onClick={()=>{
              // setCreateBatchPayment(true)
              getFeatures("BATCH")
              navigate("/pricing/actions",{
                state:{
                  module:"Batch",
                  type:"create",
                  batchid:selectedBatchID,
                  batchname:selectedBatch
                }
              })
            }}
            >
              Create
            </button>
        </div>
        :''}
      </div>
      <div className={style.select_section}>
        <div className={style.org_select}>
          <select
            name=""
            id=""
            onChange={(e) => {
              const clubID = e.target.value;
              setSelectedBatchID(clubID)
              getClubPriceList(clubID)
              const clubName =
                    e.target.options[e.target.selectedIndex].text;
                    setSelectedBatch(clubName)
            }}
          >
            <option value="">Select Batch/Club</option>
            {batchList.length > 0 &&
              batchList.map((batch,index) => {
                return (
                  <option key={index} value={batch._id}>
                    {batch.ClubName}
                  </option>
                );
              })}
          </select>
        </div>
        
      </div>
      <div>
        {batchListShow?
        <table className={style.table}>
                <tr>
                  <th>Batch/Club</th>
                  <th>Country</th>
                  <th>Rate</th>
                  <th>Currency</th>
                  <th>UOM</th>
                  <th>Actions</th>
                </tr>
                {batchPricelist&&batchPricelist.length>0?batchPricelist.map((batch,index)=>{
                  return(
                    <tr key={index}>
                        <td>{batch.club_name}</td>
                        <td>{batch.country}</td>
                        <td>{batch.rate}</td>
                        <td>{batch.currency}</td>
                        <td>{batch.uom}</td>
                        <td>
                          <div className={style.Buttons}>
                            <div className={style.iconContainer}>
                              <BiEdit
                                className={style.editButton}
                                onClick={()=>{
                                  navigate("/pricing/actions",{
                                    state:{
                                      module:"Batch",
                                      type:"edit",
                                      pricingid:batch.clubPrizings_id
                                    }
                                  })
                                }}
                              />
                              <span className={style.tooltipText}>Edit</span>
                            </div>
                            <div className={style.iconContainer}>
                              <RiDeleteBinLine
                                className={style.deleteButton}
                                onClick={()=>{
                                  setDeleteBatchPayment(true)
                                  setClubPrizingID(batch.clubPrizings_id)
                                }}
                              />
                              <span className={style.tooltipText}>Delete</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                  )
                }):''}
                      
              </table>
              :''}
      </div>
      <div className={style.FeatureList}>
        <div className={style.FeatureHead}>
          <p>Features</p>
        </div>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Feature Code</th>
              <th>Feature Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {batchFeatureList&&batchFeatureList.length>0?batchFeatureList[0]?.Features.map((feature)=>{
            return(
              <tr>
              <td>{feature.Featurecode}</td>
              <td>{feature.Featurename}</td>
              <td><div className={style.Buttons}>
                            <div className={style.iconContainer}>
                              <BiEdit
                                className={style.editButton}
                                onClick={()=>{
                                  setEditBatchFeature(true)
                                  setselectedBatchFeaturecode(feature.Featurecode)
                                }}
                              />
                              <span className={style.tooltipText}>Edit</span>
                            </div>
                            <div className={style.iconContainer}>
                              <RiDeleteBinLine
                                className={style.deleteButton}
                                onClick={()=>{
                                  setDeletebatchFeature(true)
                                  setselectedBatchFeaturecode(feature.Featurecode)
                                }}
                              />
                              <span className={style.tooltipText}>Delete</span>
                            </div>
                          </div></td>
            </tr>
            )
          }):''}
            
          </tbody>
        </table>
        <div className={style.Featurebutton}>
        <button onClick={()=>{
          setCreateBatchFeature(true)
        }}>Create Feature</button>
        </div>
      </div>
            </div>
        )}
      </Typography>
    </div>
  )
}

export default Pricing