import React, { useEffect, useState } from "react";
import style from "./PlanningCreation.module.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { baseUrl } from "../Url";
import { IoIosCloseCircleOutline, IoMdClose } from "react-icons/io";

const PlanningCreation = () => {
  const adminStatus = localStorage.getItem("adminStatus");
  console.log(adminStatus);
  const key = JSON.parse(localStorage.getItem("access-token"));
  const userId = localStorage.getItem("adminUserId");

  const [sessionName, setSessionName] = useState("");
  const [orgDataList, setOrgDataList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState();
  const [courseDataList, setCourseDataList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({
    course_id: "",
    course_name: "",
  });
  const [batchPopupShow, setBatchPopupShow] = useState(false);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [batchDataList, setBatchDataList] = useState([]);
  const [batchSearchValue, setBatchSearchValue] = useState("");
  const [userPopupShow, setUserPopupShow] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userSearchValue, setUserSearchValue] = useState("");
  const [userDataList, setUserDataList] = useState([]);
  const [eventPopupShow, setEventPopupShow] = useState(false);
  const [eventDate, setEventDate] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [startTime, setStartTime] = React.useState("");
  const [sessionId, setSessionId] = React.useState("");
  const [endTime, setEndTime] = React.useState("");

  const [calenderDisable, setCalendarDisable] = useState(true);

  const handleCheckboxChange = (batch) => {
    setSelectedBatches((prevSelected) => {
      if (prevSelected.find((item) => item.club_id === batch.club_id)) {
        return prevSelected.filter((item) => item.club_id !== batch.club_id);
      } else {
        return [...prevSelected, batch];
      }
    });
  };

  const handleRemoveBatch = (batch) => {
    setSelectedBatches(
      selectedBatches.filter((b) => b.club_id !== batch.club_id)
    );
  };

  const handleUserCheckboxChange = (user) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.find((item) => item.user_id === user.user_id)) {
        return prevSelected.filter((item) => item.user_id !== user.user_id);
      } else {
        return [...prevSelected, user];
      }
    });
  };

  const handleRemoveUser = (user) => {
    setSelectedUsers(selectedUsers.filter((u) => u.user_id !== user.user_id));
  };

  const handleDateClick = (info) => {
    if (!calenderDisable) {
      const clickedDate = new Date(info.dateStr);
      const currentDate = new Date();

      if (clickedDate < currentDate.setHours(0, 0, 0, 0)) {
        alert("You cannot select a past date!");
      } else {
        const formattedDate = clickedDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
        setEventPopupShow(true);
        setEventDate(formattedDate); // Update state with formatted date
      }
    }
  };

  const convertToCustomFormat = (time, eventDate) => {
    const localDateTime = new Date(`${eventDate}T${time}`);

    // Extract the components of the date
    const year = localDateTime.getUTCFullYear();
    const month = String(localDateTime.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(localDateTime.getUTCDate()).padStart(2, "0");
    const hours = String(localDateTime.getUTCHours()).padStart(2, "0");
    const minutes = String(localDateTime.getUTCMinutes()).padStart(2, "0");
    const seconds = String(localDateTime.getUTCSeconds()).padStart(2, "0");

    // Return in the desired format
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const handleStartTime = (e) => {
    const selectedStartTime = e.target.value;
    const currentDateTime = new Date();
    const selectedDateTime = new Date(`${eventDate}T${selectedStartTime}`);

    if (selectedDateTime <= currentDateTime) {
      setStartTime("");
    } else {
      setStartTime(selectedStartTime);
    }
  };

  const handleEndTime = (e) => {
    const selectedEndTime = e.target.value;
    if (eventDate && selectedEndTime <= startTime) {
      setEndTime("");
    } else {
      setEndTime(selectedEndTime);
    }
  };

  async function orgGet() {
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setOrgDataList(data.data);
        }
      });
  }

  async function courseGet() {
    await fetch(`${baseUrl}/org/course-list`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrg,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCourseDataList(data);
      });
  }

  async function batchGet() {
    await fetch(`${baseUrl}/list/clubs`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrg,
        search: batchSearchValue,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setBatchDataList(data.data);
        }
      });
  }

  async function userGet() {
    await fetch(`${baseUrl}/list/org-user`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrg,
        search: userSearchValue,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUserDataList(data.data);
        }
      });
  }

  async function createSession() {
    const batchIds = selectedBatches.map((batch) => batch.club_id);
    const formattedUsers = selectedUsers.map((user) => ({
      user_id: user.user_id,
      user_name: user.user_name,
    }));
    await fetch(`${baseUrl}/create/session`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrg,
        club_ids: batchIds,
        course: selectedCourse.course_name,
        course_id: selectedCourse.course_id,
        session_name: sessionName,
        description: "",
        attendees: formattedUsers,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setCalendarDisable(false);
          setSelectedUsers([]);
          setSelectedBatches([]);
          setSessionName("");
          setSelectedCourse({ course_id: "", course_name: "" });
          setSessionId(data.data[0].session_id);
        }
      });
  }

  async function eventCreation() {
    const startUTC = convertToCustomFormat(startTime, eventDate);
    const endUTC = convertToCustomFormat(endTime, eventDate);
    await fetch(`${baseUrl}/session/add-event`, {
      method: "POST",
      body: JSON.stringify({
        session_id: sessionId,
        event_name: eventTitle,
        description: eventDescription,
        StartDateTime: startUTC,
        EndDateTime: endUTC,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setEventPopupShow(false);
          setEventDate(null);
          setStartTime("");
          setEndTime("");
          setEventTitle("");
          setEventDescription("");
        }
      });
  }

  useEffect(() => {
    if (userId) {
      orgGet();
    }
  }, [userId]);

  useEffect(() => {
    if (selectedOrg) {
      courseGet();
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (selectedOrg) {
      batchGet();
    }
  }, [batchSearchValue, selectedOrg]);

  useEffect(() => {
    if (selectedOrg) {
      userGet();
    }
  }, [userSearchValue, selectedOrg]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Session Planning</p>
        </div>
      </div>
      <div className={style.planningSection}>
        <div className={style.sessionCreation}>
          <div className={style.inputContainer}>
            <div className={style.inputValue}>
              <label htmlFor="s-Name">Session name</label>
              <input
                type="text"
                id="s-Name"
                placeholder="Enter the session name"
                value={sessionName}
                onChange={(e) => {
                  setSessionName(e.target.value);
                }}
              />
            </div>
            <div className={style.inputValue}>
              <label htmlFor="org_Name">Organization name</label>
              <select
                name=""
                id="org_Name"
                value={selectedOrg}
                onChange={(e) => {
                  setSelectedOrg(e.target.value);
                }}
              >
                <option value="">Select organization</option>
                {orgDataList &&
                  orgDataList.length > 0 &&
                  orgDataList.map((list, i) => {
                    return (
                      <option value={list.org_id} key={i}>
                        {list.org_name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className={style.inputValue}>
              <label htmlFor="course_name">Course name</label>
              <select
                name=""
                id="course_name"
                onChange={(e) => {
                  const courseId = e.target.value;
                  const courseName = courseDataList.find(
                    (course) => course.category_id === courseId
                  );
                  setSelectedCourse({
                    course_id: courseId,
                    course_name: courseName?.Name,
                  });
                }}
                value={selectedCourse.courseId}
                disabled={!selectedOrg}
              >
                <option value="">Select course</option>
                {courseDataList &&
                  courseDataList.length &&
                  courseDataList.map((list, i) => {
                    return (
                      <option value={list.category_id} key={i}>
                        {list.Name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className={style.inputContainerSecond}>
            <div className={style.inputSearchSection}>
              <label htmlFor="b-Name">Add participants</label>
              <div className={style.selectionSection}>
                <button
                  onClick={() => {
                    setBatchPopupShow(true);
                  }}
                  disabled={!selectedOrg}
                >
                  Add batch
                </button>
                <button
                  disabled={!selectedOrg}
                  onClick={() => {
                    setUserPopupShow(true);
                  }}
                >
                  Add users
                </button>
              </div>
            </div>
          </div>
          {calenderDisable && (
            <div className={style.inputContainerThird}>
              <button>Cancel</button>
              <button
                onClick={() => {
                  createSession();
                }}
              >
                Create
              </button>
            </div>
          )}
        </div>
        <div className={style.sessionCalender}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            showNonCurrentDates={false}
            headerToolbar={{
              start: "prev,next",
              center: "title",
              end: "dayGridMonth,timeGridWeek",
            }}
            timeZone="local"
            height="550px"
            aspectRatio={2}
            dateClick={handleDateClick}
          />
        </div>
      </div>
      {batchPopupShow && (
        <>
          <div
            className={style.overlay}
            onClick={() => {
              setBatchPopupShow(false);
              setSelectedBatches([]);
              setBatchSearchValue("");
            }}
          ></div>
          <div className={style.userSelectPopup}>
            <div className={style.userPopupHeader}>
              <p>Search with batch</p>
              <IoMdClose
                onClick={() => {
                  setBatchPopupShow(false);
                  setSelectedBatches([]);
                  setBatchSearchValue("");
                }}
              />
            </div>
            <div className={style.userPopupSection}>
              <div className={style.popupInput}>
                {selectedBatches.map((batch, index) => (
                  <div className={style.chip} key={index}>
                    <p>{batch.club_name}</p>
                    <IoIosCloseCircleOutline
                      onClick={() => handleRemoveBatch(batch)}
                    />
                  </div>
                ))}
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Enter the batch name"
                  onChange={(e) => {
                    setBatchSearchValue(e.target.value);
                  }}
                />
              </div>
              <div className={style.userPopupList}>
                {batchDataList &&
                  batchDataList.length > 0 &&
                  batchDataList.map((list, i) => {
                    return (
                      <div className={style.popupValue} key={i}>
                        <input
                          type="checkbox"
                          checked={selectedBatches.some(
                            (item) => item.club_id === list.club_id
                          )}
                          onChange={() => handleCheckboxChange(list)}
                        />

                        <p>{list.club_name}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={style.userButtonList}>
              <button
                onClick={() => {
                  setBatchPopupShow(false);
                  setSelectedBatches([]);
                  setBatchSearchValue("");
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setBatchPopupShow(false);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </>
      )}

      {userPopupShow && (
        <>
          <div
            className={style.overlay}
            onClick={() => {
              setUserPopupShow(false);
              setSelectedUsers([]);
              setUserSearchValue("");
            }}
          ></div>
          <div className={style.userSelectPopup}>
            <div className={style.userPopupHeader}>
              <p>Search with user</p>
              <IoMdClose
                onClick={() => {
                  setUserPopupShow(false);
                  setSelectedUsers([]);
                  setUserSearchValue("");
                }}
              />
            </div>
            <div className={style.userPopupSection}>
              <div className={style.popupInput}>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Enter the user"
                  onChange={(e) => {
                    setUserSearchValue(e.target.value);
                  }}
                />
                {selectedUsers.map((user, index) => (
                  <div className={style.chip} key={index}>
                    <p>{user.user_name}</p>
                    <IoIosCloseCircleOutline
                      onClick={() => handleRemoveUser(user)}
                    />
                  </div>
                ))}
              </div>
              <div className={style.userPopupList}>
                {userDataList &&
                  userDataList.length > 0 &&
                  userDataList.map((list, i) => {
                    return (
                      <div className={style.popupValue} key={i}>
                        <input
                          type="checkbox"
                          checked={selectedUsers.some(
                            (item) => item.user_id === list.user_id
                          )}
                          onChange={() => handleUserCheckboxChange(list)}
                        />

                        <p>{list.user_name}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={style.userButtonList}>
              <button
                onClick={() => {
                  setUserPopupShow(false);
                  setSelectedUsers([]);
                  setUserSearchValue("");
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setUserPopupShow(false);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </>
      )}

      {eventPopupShow && (
        <>
          <div
            className={style.overlay}
            onClick={() => {
              setEventPopupShow(false);
              setEventDate(null);
              setStartTime("");
              setEndTime("");
              setEventTitle("");
              setEventDescription("");
            }}
          ></div>
          <div className={style.eventPopup}>
            <div className={style.eventPopupHeader}>
              <p>Create your event</p>
              <IoMdClose
                onClick={() => {
                  setEventPopupShow(false);
                  setEventDate(null);
                  setStartTime("");
                  setEndTime("");
                  setEventTitle("");
                  setEventDescription("");
                }}
              />
            </div>
            <div className={style.eventPopupSection}>
              <div className={style.eventInputContainer}>
                <div className={style.eventInput}>
                  <label htmlFor="e-name">Event title</label>
                  <input
                    type="text"
                    name=""
                    id="e-name"
                    placeholder="Enter the event title"
                    value={eventTitle}
                    onChange={(e) => setEventTitle(e.target.value)}
                  />
                </div>
                <div className={style.eventInput}>
                  <label htmlFor="e-date">Date</label>
                  <input
                    type="date"
                    id="e-date"
                    placeholder="Select the date"
                    value={eventDate} // Bind the state
                    onChange={(e) => setEventDate(e.target.value)} // Update state when the user changes the date
                    min={new Date().toISOString().split("T")[0]} // Restrict past dates
                  />
                </div>
              </div>
              <div className={style.eventInputContainer}>
                <div className={style.eventInput}>
                  <label htmlFor="s-time">Start Time</label>
                  <input
                    type="time"
                    name="startTime"
                    id="startTime"
                    required
                    placeholder="Start Time"
                    value={startTime}
                    onChange={handleStartTime}
                  />
                </div>

                <div className={style.eventInput}>
                  <label htmlFor="e-time">End Time</label>
                  <input
                    type="time"
                    name="endTime"
                    id="endTime"
                    required
                    placeholder="End Time"
                    value={endTime}
                    onChange={handleEndTime}
                  />
                </div>
              </div>
              <div className={style.eventInputContainer}>
                <div className={style.eventSecondInput}>
                  <label htmlFor="e-description">Description</label>
                  <textarea
                    name=""
                    id="e-description"
                    placeholder="Write something.."
                    value={eventDescription}
                    onChange={(e) => {
                      setEventDescription(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className={style.eventButtons}>
              <button
                onClick={() => {
                  setEventPopupShow(false);
                  setEventDate(null);
                  setStartTime("");
                  setEndTime("");
                  setEventTitle("");
                  setEventDescription("");
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  eventCreation();
                }}
              >
                Create
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PlanningCreation;
