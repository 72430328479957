import React from "react";
import style from "./ClubLesson.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { useEffect } from "react";
import { useState } from "react";

const CLubLesson = () => {
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const clubId = state.club;
  const clubName = state.clubname;

  const [clubLessonData, setClubLessonData] = useState([]);

  //club lesson list

  async function clubLesson() {
    await fetch(`${baseUrl}/clublearning/details`, {
      method: "POST",
      body: JSON.stringify({
        club_id: clubId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClubLessonData(data.data);
      });
  }

  useEffect(() => {
    clubLesson();
  }, [clubId]);

  return (
    <div className={style.Container}>
      <div className={style.Header}>
        <div className={style.Title}>
          <p>Club Mapped Lesson List</p>
        </div>
      </div>
      <div className={style.lessonSection}>
        {/* <div className={style.ClubName}>
          <h4>{clubName}</h4>
        </div> */}
        <div className={style.lessonGrid}>
          {clubLessonData.length > 0 &&
            clubLessonData.map((list, i) => {
              return (
                <div className={style.card} key={i}>
                  <div className={style.cardHead}>
                    <p>{list.category_details}</p>
                  </div>
                  <div className={style.cardBottom}>
                    <div className={style.bottomFirst}>
                      <div className={style.inputValues}>
                        <p>Goal</p>
                        <span> : </span>
                        <p>{list.goal}</p>
                      </div>
                      <div className={style.inputValues}>
                        <p>Status </p>
                        <span> :</span>
                        <p>{list.Status}</p>
                      </div>
                    </div>
                    <div className={style.bottomSecond}>
                      <div className={style.timeline}>
                        <p>Timeline</p>
                        <span> : </span>
                        <p>{list.timeline}</p>
                      </div>
                      <div className={style.plan}>
                        <p>Plan</p>
                        <span> :</span>
                        <p> {list.plan}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CLubLesson;
