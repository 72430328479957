import React, { useEffect } from 'react'
import style from './Home.module.css'
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate()
  const key = JSON.parse(localStorage.getItem("access-token"));
  const userId = localStorage.getItem("adminUserId");
  const adminStatus = localStorage.getItem("adminStatus");

  useEffect(() => {
    if (adminStatus === null || adminStatus === undefined || adminStatus === "") {
      navigate("/login");
    } else {
      if (adminStatus === "true") {
        navigate("/organization");
      } else if (adminStatus === "false") {
        navigate("/club");
      }
    }
  }, [adminStatus]);
  return (
    <div className={style.container}>
      <div>Home</div>

    </div>
  )
}

export default Home