import React, { useEffect, useState } from "react";
import style from "./SingleLessonMapping.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { AiOutlineClose } from "react-icons/ai";

const SingleLessonMapping = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const orgId = state.organization;
  const clubId = state.club;
  const key = state.token;

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryPopup, setSubCategoryPopup] = useState(false);
  const [goalShow, setGoalShow] = useState(false);
  const [goalList, setGoalList] = useState([]);
  const [goal, setGoal] = useState("");
  const [month, setMonth] = useState("");
  const [comment, setComment] = useState("");
  const [lastCategoryId, setLastCategoryId] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [topicShow, setTopicShow] = useState(false);
  const [mainCategoryId, setMainCategoryId] = useState("");
  const [categoryValue, setCategoryValue] = useState("");

  async function categoryGet() {
    await fetch(`${baseUrl}/main/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        type: "admin",
        club_id: clubId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCategoryList(data.data);
      });
  }
  async function subcategoryGet(categoryId) {
    await fetch(`${baseUrl}/sub/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        id: categoryId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCategoryValue(data.category);
        if (data.category === true) {
          setSubCategoryList(data.data);
          setGoalShow(false);
        } else {
          setSubCategoryPopup(false);
          goalGet();
          setGoalShow(true);
          setLastCategoryId(categoryId);
          TopicGet();
        }
      });
  }

  async function goalGet() {
    await fetch(`${baseUrl}/goal/list`, {
      method: "POST",
      body: JSON.stringify({
        source: "web",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setGoalList(data.data);
      });
  }

  async function TopicGet() {
    await fetch(`${baseUrl}/topic/get`, {
      method: "POST",
      body: JSON.stringify({
        category_id: lastCategoryId,
        org_id: orgId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setTopicList(data.data);
      });
  }

  const saveData = {
    Organization_ID: orgId,
    Goal: [
      {
        goal: goal,
        time_line: month,
        comments: comment,
      },
    ],
    Topics: topicList,
    Categories: [
      {
        first_level: mainCategoryId,
        last_level: categoryValue ? lastCategoryId : mainCategoryId,
      },
    ],
  };

  async function learningSave() {
    await fetch(`${baseUrl}/userlearning/saving`, {
      method: "POST",
      body: JSON.stringify(saveData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          navigate("/lesson/mapping", {
            state: {
              organization: data.data.org_id,
              club: data.data.club,
              id: data.data.user_id,
              user: data.data.user_name,
              tocken:data.data.token
            },
          });
        }
      });
  }

  useEffect(() => {
    categoryGet();
  }, [orgId]);

  return (
    <div className={style.Container}>
      <div className={style.Header}>
        <h3>Lesson Mapping</h3>
      </div>
      <div className={style.categoryList}>
        <div className={style.categoryGrid}>
          {categoryList.length > 0 &&
            categoryList.map((list) => {
              return (
                <div
                  className={style.Card}
                  onClick={() => {
                    const mainCataid = list.category_id;
                    setMainCategoryId(mainCataid);
                    subcategoryGet(list.category_id);
                    setSubCategoryPopup(true);
                  }}
                >
                  <p>{list.Name}</p>
                </div>
              );
            })}
        </div>
      </div>
      {subCategoryPopup ? (
        <>
          <div
            className={style.categoryOverlay}
            onClick={() => {
              setSubCategoryPopup(false);
            }}
          ></div>
          <div className={style.categorySelection}>
            <div className={style.categoryClose}>
              <AiOutlineClose
                onClick={() => {
                  setSubCategoryPopup(false);
                }}
              />
            </div>
            <div className={style.SelectionHeader}>
              <h4>Sub Categories</h4>
            </div>
            <div className={style.SelectionGrid}>
              {subCategoryList.length > 0 &&
                subCategoryList.map((sub) => {
                  return (
                    <div
                      className={style.sectionCard}
                      onClick={() => {
                        const lastcatID = sub.category_id;
                        setLastCategoryId(lastcatID);
                        if (sub.next_data === 0) {
                          setSubCategoryPopup(false);
                          setGoalShow(true);
                          goalGet();
                        } else {
                          subcategoryGet(sub.category_id);
                        }
                      }}
                    >
                      <p>{sub.Name}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {goalShow ? (
        <>
          <div
            className={style.goalOverlay}
            onClick={() => {
              setGoalShow(false);
            }}
          ></div>
          <div className={style.selectionPopup}>
            <div className={style.selectionPopupClose}>
              <AiOutlineClose
                onClick={() => {
                  setGoalShow(false);
                }}
              />
            </div>
            <div className={style.selectionPopupSection}>
              <div className={style.goal}>
                <p>Goal</p>
                <select
                  onChange={(e) => {
                    const selectedGoal = e.target.value;
                    setGoal(selectedGoal);
                  }}
                >
                  <option value="">Select your Goal</option>
                  {goalList.length > 0 &&
                    goalList.map((goalValue) => {
                      return (
                        <option
                          value={goalValue.GoalName}
                          key={goalValue.GoalID}
                        >
                          {goalValue.GoalName}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className={style.skill}>
                <p>Timeline</p>
                <select
                  name=""
                  id=""
                  onChange={(e) => {
                    setMonth(e.target.value);
                  }}
                >
                  <option value="">Select a Month</option>
                  <option value="1">1 Month</option>
                  <option value="2">2 Month</option>
                  <option value="3">3 Month</option>
                  <option value="4">4 Month</option>
                  <option value="5">5 Month</option>
                  <option value="6">6 Month</option>
                  <option value="7">7 Month</option>
                  <option value="8">8 Month</option>
                  <option value="9">9 Month</option>
                  <option value="10">10 Month</option>
                  <option value="11">11 Month</option>
                  <option value="12">12 Month</option>
                </select>
              </div>
              <div className={style.comment}>
                <p>Comment</p>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className={style.selectionButton}>
                <button
                  onClick={() => {
                    TopicGet();
                    setGoalShow(false);
                    setTopicShow(true);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {topicShow ? (
        <>
          <div
            className={style.categoryOverlay}
            onClick={() => {
              setTopicShow(false);
            }}
          ></div>

          <div className={style.topicContainer}>
            <div className={style.topicClose}>
              <AiOutlineClose
                onClick={() => {
                  setTopicShow(false);
                }}
              />
            </div>
            <div className={style.topicSection}>
              {topicList.length > 0 &&
                topicList[0].topics.map((topic) => {
                  return <p>{topic.value}</p>;
                })}
            </div>
            <div className={style.topicButon}>
              <button
                onClick={() => {
                  learningSave();
                  setTopicShow(false);
                }}
              >
                Assign Learning
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SingleLessonMapping;
