import React, { useEffect, useState } from "react";
import style from "./Billing.module.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineViewGrid } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { baseUrl } from "../Url";

const Billing = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [buttonShow, setButtonShow] = useState(false);
  const navigate = useNavigate();

  const { profile } = useSelector((state) => state.profileData);

  useEffect(() => {
    setOrgID(profile?.org_id);
    setLoginUserID(profile?._id);
  }, [profile]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [invoiceCreate, setInvoiceCreate] = useState(false);
  const [invoiceDelete, setInvoiceDelete] = useState(false);
  const [invoiceView, setInvoiceView] = useState(false);
  const [invoiceEdit, setInvoiceEdit] = useState(false);
  const [orgID, setOrgID] = useState("");
  const [loginUserID, setLoginUserID] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [netAmount, setNetAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [editPincode, setEditPincode] = useState("");
  const [editCountry, setEditCountry] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editTaxAmount, setEditTaxAmount] = useState("");
  const [editNetAmount, setEditNetAmount] = useState("");
  const [editDiscount, setEditDiscount] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [itemName, setItemName] = useState("");
  const [rate, setRate] = useState("");
  const [quantity, setQuantity] = useState("");
  const [total, setTotal] = useState("");
  const [itemDiscount, setItemDiscount] = useState("");
  const [itemNetTotal, setItemNetTotal] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [invoiceIndividual, setInvoiceIndividual] = useState({});
  const [receiptList, setReceiptList] = useState([]);
  const [receiptCreate, setReceiptCreate] = useState(false);
  const [receiptEdit, setReceiptEdit] = useState(false);
  const [receiptDelete, setReceiptDelete] = useState(false);
  const [receiptUsername, setReceiptUsername] = useState("");
  const [receiptUserId, setReceiptUserId] = useState("");
  const [receiptNetAmount, setReceiptNetAmount] = useState("");
  const [receiptDiscount, setReceiptDiscount] = useState("");
  const [receiptMode, setReceiptMode] = useState("");
  const [editReceiptNetAmount, setEditReceiptNetAmount] = useState("");
  const [editReceiptDiscount, setEditReceiptDiscount] = useState("");
  const [editReceiptMode, setEditReceiptMode] = useState("");
  const [inoviceNumber, setInvoiceNumber] = useState("");
  const [receptNumber, setReceiptNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [items, setItems] = useState([
    {
      itemname: "",
      rate: "",
      qty: "",
      total: "",
      discount: "",
      net_total: "",
      uom: "",
    },
  ]);
  const [editItems, setEditItems] = useState([
    {
      itemname: "",
      rate: "",
      qty: "",
      total: "",
      discount: "",
      net_total: "",
      uom: "",
    },
  ]);

  const createData = {
    user_name: selectedUserName,
    user_id: selectedUser,
    org_id: orgID,
    billingaddress: [
      {
        pin: pincode,
        country: country,
        address: address,
      },
    ],
    taxableamount: taxAmount,
    net_amount: netAmount,
    discount: discount,
    created_by: loginUserID,
    items: items,
  };
  const editData = {
    invoice_number: inoviceNumber,
    billingaddress: [
      {
        pin: editPincode,
        country: editCountry,
        address: editAddress,
      },
    ],
    taxableamount: editTaxAmount,
    net_amount: editNetAmount,
    discount: editDiscount,
    edited_by: loginUserID,
    items: editItems,
  };
  //get users
  async function getUsers() {
    await fetch(`${baseUrl}/fetch/org/customers`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setUserList(data.data);
        }
      });
  }
  async function getInvoices() {
    await fetch(`${baseUrl}/fetch/all/org/invoices`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setInvoiceList(data.data);
        }
      });
  }
  async function getIndividualInvoices(id) {
    await fetch(
      `${baseUrl}/fetch/individual/invoices`,
      {
        method: "POST",
        body: JSON.stringify({
          invoice_number: id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setInvoiceIndividual(data.data[0]);
        }
      });
  }

  const validateCreateInvoice = () => {
    const errors = {};

    if (pincode.trim() === "") {
      errors.pincode = "Pincode is required";
    }

    if (country.trim() === "") {
      errors.country = "Country is required";
    }

    if (address.trim() === "") {
      errors.address = "Address is required";
    }
    if (taxAmount.trim() === "") {
      errors.taxAmount = "TaxAmount is required";
    }
    if (netAmount.trim() === "") {
      errors.netAmount = "NetAmount is required";
    }
    if (discount.trim() === "") {
      errors.discount = "Discount is required";
    }
    if (selectedUserName.trim() === "") {
      errors.selectedUserName = "Select one user";
    }
    if (selectedUser.trim() === "") {
      errors.selectedUser = "Select one user";
    }
    if (
      items.some((item) =>
        Object.values(item).some((value) => value.trim() === "")
      )
    ) {
      errors.items = "All item fields are required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };
  async function createInvoice() {
    const isValidate = validateCreateInvoice();
    if (isValidate) {
      await fetch(
        `${baseUrl}/create/customer/invoice`,
        {
          method: "POST",
          body: JSON.stringify(createData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            setInvoiceCreate(false);
            getInvoices();
            setPincode("");
            setCountry("");
            setAddress("");
            setDiscount("");
            setTaxAmount("");
            setNetAmount("");
            setSelectedUser("");
            setSelectedUserName("");
            setItems([
              {
                itemname: "",
                rate: "",
                qty: "",
                total: "",
                discount: "",
                net_total: "",
                uom: "",
              },
            ]);
          }
        });
    }
  }
  const validateEditInvoice = () => {
    const errors = {};

    if (editPincode.trim() === "") {
      errors.editPincode = "Pincode is required";
    }

    if (editCountry.trim() === "") {
      errors.editCountry = "Country is required";
    }

    if (editAddress.trim() === "") {
      errors.editAddress = "Address is required";
    }
    if (editTaxAmount.trim() === "") {
      errors.editTaxAmount = "TaxAmount is required";
    }
    if (editNetAmount.trim() === "") {
      errors.editNetAmount = "NetAmount is required";
    }
    if (editDiscount.trim() === "") {
      errors.editDiscount = "Discount is required";
    }
    if (
      editItems.some((item) =>
        Object.values(item).some((value) => value.trim() === "")
      )
    ) {
      errors.editItems = "All item fields are required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };
  async function editInvoice() {
    const isValidate = validateEditInvoice();
    if (isValidate) {
      await fetch(
        `${baseUrl}/edit/customer/invoice`,
        {
          method: "POST",
          body: JSON.stringify(editData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            getInvoices();
            setInvoiceEdit(false);
            setEditPincode("");
            setEditCountry("");
            setEditAddress("");
            setEditDiscount("");
            setEditTaxAmount("");
            setEditNetAmount("");
            setEditItems([
              {
                itemname: "",
                rate: "",
                qty: "",
                total: "",
                discount: "",
                net_total: "",
                uom: "",
              },
            ]);
          }
        });
    }
  }
  async function deleteInvoice() {
    await fetch(
      `${baseUrl}/delete/customer/invoice`,
      {
        method: "POST",
        body: JSON.stringify({
          invoice_number: inoviceNumber,
          deleted_by: loginUserID,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setInvoiceDelete(false);
          getInvoices();
        }
      });
  }
  //get receipt
  async function getReceipts() {
    await fetch(`${baseUrl}/fetch/all/org/receipts`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setReceiptList(data.data);
        }
      });
  }
  const validateCreateReceipts = () => {
    const errors = {};

    if (receiptNetAmount.trim() === "") {
      errors.receiptNetAmount = "Net Amount is required";
    }

    if (receiptDiscount.trim() === "") {
      errors.receiptDiscount = "Discount is required";
    }

    if (receiptMode.trim() === "") {
      errors.receiptMode = "Payment Mode is required";
    }
    if (receiptUserId.trim() === "") {
      errors.receiptUserId = "Select One user";
    }
    if (receiptUsername.trim() === "") {
      errors.receiptUsername = "Select One user";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };
  //create resceipt
  async function createReceipts() {
    const body = {
      org_id: orgID,
      user_id: receiptUserId,
      user_name: receiptUsername,
      net_amount: receiptNetAmount,
      discount: receiptDiscount,
      created_by: loginUserID,
      mode: receiptMode,
    };
    const formValidate = validateCreateReceipts();
    if (formValidate) {
      await fetch(
        `${baseUrl}/create/customer/receipt`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            getReceipts();
            setReceiptCreate(false);
            setReceiptMode("");
            setReceiptDiscount("");
            setReceiptNetAmount("");
            setReceiptUserId("");
            setReceiptUsername("");
          }
        });
    }
  }
  const validateEditReceipts = () => {
    const errors = {};

    if (editReceiptNetAmount.trim() === "") {
      errors.editReceiptNetAmount = "Net Amount is required";
    }

    if (editReceiptDiscount.trim() === "") {
      errors.editReceiptDiscount = "Discount is required";
    }

    if (editReceiptMode.trim() === "") {
      errors.editReceiptMode = "Payment Mode is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };
  async function editReceipts() {
    const body = {
      receipt_number: receptNumber,
      net_amount: editReceiptNetAmount,
      discount: editReceiptDiscount,
      mode: editReceiptMode,
      edited_by: loginUserID,
    };
    const formValidate = validateEditReceipts();
    if (formValidate) {
      await fetch(
        `${baseUrl}/edit/customer/receipt`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            getReceipts();
            setReceiptEdit(false);
            setEditReceiptDiscount("");
            setEditReceiptMode("");
            setEditReceiptNetAmount("");
          }
        });
    }
  }
  async function individualReceipts(receiptno) {
    await fetch(
      `${baseUrl}/fetch/individual/receipts`,
      {
        method: "POST",
        body: JSON.stringify({
          receipt_number: receiptno,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setEditReceiptDiscount(data.data[0].discount);
          setEditReceiptMode(data.data[0].mode);
          setEditReceiptNetAmount(data.data[0].net_amount);
        }
      });
  }
  async function deleteReceipts() {
    await fetch(
      `${baseUrl}/delete/customer/receipt`,
      {
        method: "POST",
        body: JSON.stringify({
          receipt_number: receptNumber,
          deleted_by: loginUserID,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          getReceipts();
          setReceiptDelete(false);
        }
      });
  }
  useEffect(() => {
    if (orgID !== "" && orgID !== undefined) {
      console.log("called");
      getUsers();
      getInvoices();
    }
  }, [orgID, profile]);

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };
  const handleEditItemChange = (index, field, value) => {
    const updatedItems = [...editItems];
    updatedItems[index][field] = value;
    setEditItems(updatedItems);
  };
  const addNewItem = () => {
    setItems([
      ...items,
      {
        itemname: "",
        rate: "",
        qty: "",
        total: "",
        discount: "",
        net_total: "",
        uom: "",
      },
    ]);
  };
  const addNewEditItem = () => {
    setEditItems([
      ...editItems,
      {
        itemname: "",
        rate: "",
        qty: "",
        total: "",
        discount: "",
        net_total: "",
        uom: "",
      },
    ]);
  };

  const removeItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };
  const removeEditItem = (index) => {
    const updatedItems = [...editItems];
    updatedItems.splice(index, 1);
    setEditItems(updatedItems);
  };
  const handleSubmit = () => {
    console.log(createData);
  };

  useEffect(() => {
    setEditPincode(invoiceIndividual.billingaddress?.[0]?.pin || "");
    setEditCountry(invoiceIndividual.billingaddress?.[0]?.country || "");
    setEditAddress(invoiceIndividual.billingaddress?.[0]?.address || "");
    setEditTaxAmount(invoiceIndividual.taxableamount || "");
    setEditNetAmount(invoiceIndividual.net_amount || "");
    setEditDiscount(invoiceIndividual.discount || "");

    // Check if 'items' property exists in invoiceIndividual and is an array
    const itemsArray =
      invoiceIndividual.items && Array.isArray(invoiceIndividual.items)
        ? invoiceIndividual.items
        : [];

    setEditItems(itemsArray);
  }, [invoiceIndividual]);

  return (
    <div className={style.Container}>
      {invoiceCreate ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setInvoiceCreate(false);
              setPincode("");
              setCountry("");
              setAddress("");
              setDiscount("");
              setTaxAmount("");
              setNetAmount("");
              setItems([
                {
                  itemname: "",
                  rate: "",
                  qty: "",
                  total: "",
                  discount: "",
                  net_total: "",
                  uom: "",
                },
              ]);
              setErrors({});
              setSelectedUser("");
              setSelectedUserName("");
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setInvoiceCreate(false);
                  setPincode("");
                  setCountry("");
                  setAddress("");
                  setDiscount("");
                  setTaxAmount("");
                  setNetAmount("");
                  setItems([
                    {
                      itemname: "",
                      rate: "",
                      qty: "",
                      total: "",
                      discount: "",
                      net_total: "",
                      uom: "",
                    },
                  ]);
                  setErrors({});
                  setSelectedUser("");
                  setSelectedUserName("");
                }}
              />
            </div>
            <div className={style.membersection}>
              <div className={style.UserSlector}>
                <select
                  name=""
                  id=""
                  onChange={(e) => {
                    const userID = e.target.value;
                    setSelectedUser(userID);
                    const userName =
                      e.target.options[e.target.selectedIndex].text;
                    setSelectedUserName(userName);
                  }}
                >
                  <option value="">Select User</option>
                  {userList.length > 0 &&
                    userList.map((user, index) => {
                      return (
                        <option key={index} value={user._id}>
                          {user.Username}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Pincode</label>
                  <input
                    type="text"
                    name="Pincode"
                    id="Pincode"
                    placeholder="Pincode"
                    autocomplete="off"
                    value={pincode}
                    onChange={(e) => {
                      setPincode(e.target.value);
                    }}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Country</label>
                  <input
                    type="text"
                    name="Country"
                    id="Country"
                    placeholder="Country"
                    autocomplete="off"
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Address</label>
                  <input
                    type="text"
                    name="Address"
                    id="Address"
                    placeholder="Address"
                    autocomplete="off"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Taxable Amount</label>
                  <input
                    type="text"
                    name="TaxableAmount"
                    id="TaxableAmount"
                    placeholder="TaxableAmount"
                    autocomplete="off"
                    value={taxAmount}
                    onChange={(e) => {
                      setTaxAmount(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Net Amount</label>
                  <input
                    type="text"
                    name="NetAmount"
                    id="NetAmount"
                    placeholder="NetAmount"
                    autocomplete="off"
                    value={netAmount}
                    onChange={(e) => {
                      setNetAmount(e.target.value);
                    }}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Discount</label>
                  <input
                    type="text"
                    name="Discount"
                    id="Discount"
                    placeholder="Discount"
                    autocomplete="off"
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                    }}
                  />
                </div>
              </div>
              <p style={{ margin: "4px" }}>
                Details related Items like Mentor,Course,Batch....
              </p>
              {/* <input
                 type="text"
                 name="ItemName"
                 id="ItemName"
                 placeholder="ItemName"
                 value={itemName}
                 onChange={(e)=>{
                  setItemName(e.target.value)
                 }}
               />
               <input
                 type="text"
                 name="Rate"
                 id="Rate"
                 placeholder="Rate"
                 value={rate}
                 onChange={(e)=>{
                  setRate(e.target.value)
                 }}
               />
               <input
                 type="text"
                 name="Quantity"
                 id="Quantity"
                 placeholder="Quantity"
                 value={quantity}
                 onChange={(e)=>{
                  setQuantity(e.target.value)
                 }}
               />
               <input
                 type="text"
                 name="Total"
                 id="Total"
                 placeholder="Total"
                 value={total}
                 onChange={(e)=>{
                  setTotal(e.target.value)
                 }}
               />
               <input
                 type="text"
                 name="ItemDiscount"
                 id="ItemDiscount"
                 placeholder="Discount"
                 value={itemDiscount}
                 onChange={(e)=>{
                  setItemDiscount(e.target.value)
                 }}
               />
                <input
                 type="text"
                 name="ItemTotal"
                 id="ItemTotal"
                 placeholder="Total"
                 value={itemNetTotal}
                 onChange={(e)=>{
                  setItemNetTotal(e.target.value)
                 }}
               />
               <input
                 type="text"
                 name="UOM"
                 id="UOM"
                 placeholder="UOM"
                 value={uom}
                 onChange={(e)=>{
                  setUom(e.target.value)
                 }}
               /> */}
              <div>
                <table className={style.InputTable}>
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Discount</th>
                      <th>Net Total</th>
                      <th>UOM</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.itemname}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "itemname",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.rate}
                            onChange={(e) =>
                              handleItemChange(index, "rate", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.qty}
                            onChange={(e) =>
                              handleItemChange(index, "qty", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.total}
                            onChange={(e) =>
                              handleItemChange(index, "total", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.discount}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "discount",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.net_total}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "net_total",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.uom}
                            onChange={(e) =>
                              handleItemChange(index, "uom", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <AiOutlineClose onClick={() => removeItem(index)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <button onClick={addNewItem}>Add Item</button>
              </div>
              <div className={style.errorMessages}>
                {Object.values(errors).map((error, index) => (
                  <div key={index} className={style.errorValue}>
                    <p>{error}</p>
                  </div>
                ))}
              </div>
              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    createInvoice();
                  }}
                >
                  Create
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setInvoiceCreate(false);
                    setPincode("");
                    setCountry("");
                    setAddress("");
                    setDiscount("");
                    setTaxAmount("");
                    setNetAmount("");
                    setItems([
                      {
                        itemname: "",
                        rate: "",
                        qty: "",
                        total: "",
                        discount: "",
                        net_total: "",
                        uom: "",
                      },
                    ]);
                    setErrors({});
                    setSelectedUser("");
                    setSelectedUserName("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {invoiceView ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setInvoiceView(false);
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setInvoiceView(false);
                }}
              />
            </div>
            <div className={style.membersection}>
              <div className={style.InputContainer}>
                <div>
                  Invoice number&nbsp;:&nbsp;{invoiceIndividual.invoice_number}
                </div>
                <div>Date&nbsp;:&nbsp;{invoiceIndividual.date || ""}</div>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Address</label>
                  <input
                    type="text"
                    name="Address"
                    id="Address"
                    placeholder="Address"
                    readOnly
                    value={invoiceIndividual.billingaddress?.[0]?.address || ""}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Pincode</label>
                  <input
                    type="text"
                    name="Pincode"
                    id="Pincode"
                    placeholder="Pincode"
                    readOnly
                    value={invoiceIndividual.billingaddress?.[0]?.pin || ""}
                  />
                </div>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>State</label>
                  <input
                    type="text"
                    name="TaxableAmount"
                    id="TaxableAmount"
                    placeholder="TaxableAmount"
                    readOnly
                    value={invoiceIndividual.state || ""}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Country</label>
                  <input
                    type="text"
                    name="Country"
                    id="Country"
                    placeholder="Country"
                    readOnly
                    value={invoiceIndividual.billingaddress?.[0]?.country || ""}
                  />
                </div>
              </div>
              <p style={{ margin: "4px" }}>
                Details related Items like Mentor,Course,Batch....
              </p>
              <div>
                <table className={style.InputTable}>
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Discount</th>
                      <th>Net Total</th>
                      <th>UOM</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceIndividual.items &&
                      invoiceIndividual.items.map((item, index) => (
                        <tr key={index}>
                          <td>{item.itemname}</td>
                          <td>{item.rate}</td>
                          <td>{item.qty}</td>
                          <td>{item.total}</td>
                          <td>{item.discount}</td>
                          <td>{item.net_total}</td>
                          <td>{item.uom}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Total</label>
                  <input
                    type="text"
                    name="NetAmount"
                    id="NetAmount"
                    placeholder="NetAmount"
                    readOnly
                    value={invoiceIndividual.total || ""}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Taxable Amount</label>
                  <input
                    type="text"
                    name="Discount"
                    id="Discount"
                    placeholder="Discount"
                    readOnly
                    value={invoiceIndividual.taxableamount || ""}
                  />
                </div>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Discount</label>
                  <input
                    type="text"
                    name="Discount"
                    id="Discount"
                    placeholder="Discount"
                    readOnly
                    value={invoiceIndividual.discount || ""}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>net Amount</label>
                  <input
                    type="text"
                    name="NetAmount"
                    id="NetAmount"
                    placeholder="NetAmount"
                    readOnly
                    value={invoiceIndividual.net_amount || ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {invoiceEdit ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setInvoiceEdit(false);
              setEditPincode("");
              setEditCountry("");
              setEditAddress("");
              setEditDiscount("");
              setEditTaxAmount("");
              setEditNetAmount("");
              setEditItems([
                {
                  itemname: "",
                  rate: "",
                  qty: "",
                  total: "",
                  discount: "",
                  net_total: "",
                  uom: "",
                },
              ]);
              setErrors({});
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setInvoiceEdit(false);
                  setEditPincode("");
                  setEditCountry("");
                  setEditAddress("");
                  setEditDiscount("");
                  setEditTaxAmount("");
                  setEditNetAmount("");
                  setEditItems([
                    {
                      itemname: "",
                      rate: "",
                      qty: "",
                      total: "",
                      discount: "",
                      net_total: "",
                      uom: "",
                    },
                  ]);
                  setErrors({});
                }}
              />
            </div>
            <div className={style.membersection}>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Pincode</label>
                  <input
                    type="text"
                    name="Pincode"
                    id="Pincode"
                    placeholder="Pincode"
                    autocomplete="off"
                    value={editPincode}
                    onChange={(e) => {
                      setEditPincode(e.target.value);
                    }}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Country</label>
                  <input
                    type="text"
                    name="Country"
                    id="Country"
                    placeholder="Country"
                    autocomplete="off"
                    value={editCountry}
                    onChange={(e) => {
                      setEditCountry(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Address</label>
                  <input
                    type="text"
                    name="Address"
                    id="Address"
                    placeholder="Address"
                    autocomplete="off"
                    value={editAddress}
                    onChange={(e) => {
                      setEditAddress(e.target.value);
                    }}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Taxable Amount</label>
                  <input
                    type="text"
                    name="TaxableAmount"
                    id="TaxableAmount"
                    placeholder="TaxableAmount"
                    autocomplete="off"
                    value={editTaxAmount}
                    onChange={(e) => {
                      setEditTaxAmount(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Net Amount</label>
                  <input
                    type="text"
                    name="NetAmount"
                    id="NetAmount"
                    placeholder="NetAmount"
                    autocomplete="off"
                    value={editNetAmount}
                    onChange={(e) => {
                      setEditNetAmount(e.target.value);
                    }}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Discount</label>
                  <input
                    type="text"
                    name="Discount"
                    id="Discount"
                    placeholder="Discount"
                    autocomplete="off"
                    value={editDiscount}
                    onChange={(e) => {
                      setEditDiscount(e.target.value);
                    }}
                  />
                </div>
              </div>
              <p style={{ margin: "4px" }}>
                Details related Items like Mentor,Course,Batch....
              </p>

              <div>
                <table className={style.InputTable}>
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Discount</th>
                      <th>Net Total</th>
                      <th>UOM</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editItems.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.itemname}
                            onChange={(e) =>
                              handleEditItemChange(
                                index,
                                "itemname",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.rate}
                            onChange={(e) =>
                              handleEditItemChange(
                                index,
                                "rate",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.qty}
                            onChange={(e) =>
                              handleEditItemChange(index, "qty", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.total}
                            onChange={(e) =>
                              handleEditItemChange(
                                index,
                                "total",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.discount}
                            onChange={(e) =>
                              handleEditItemChange(
                                index,
                                "discount",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.net_total}
                            onChange={(e) =>
                              handleEditItemChange(
                                index,
                                "net_total",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            autocomplete="off"
                            value={item.uom}
                            onChange={(e) =>
                              handleEditItemChange(index, "uom", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <AiOutlineClose
                            onClick={() => removeEditItem(index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <button onClick={addNewEditItem}>Add Item</button>
              </div>
              <div className={style.errorMessages}>
                {Object.values(errors).map((error, index) => (
                  <div key={index} className={style.errorValue}>
                    <p>{error}</p>
                  </div>
                ))}
              </div>
              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    editInvoice();
                  }}
                >
                  Update
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setInvoiceEdit(false);
                    setEditPincode("");
                    setEditCountry("");
                    setEditAddress("");
                    setEditDiscount("");
                    setEditTaxAmount("");
                    setEditNetAmount("");
                    setEditItems([
                      {
                        itemname: "",
                        rate: "",
                        qty: "",
                        total: "",
                        discount: "",
                        net_total: "",
                        uom: "",
                      },
                    ]);
                    setErrors({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {invoiceDelete ? (
        <>
          <div
            onClick={() => {
              setInvoiceDelete(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setInvoiceDelete(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    deleteInvoice();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setInvoiceDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {receiptCreate ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setReceiptCreate(false);
              setReceiptMode("");
              setReceiptDiscount("");
              setReceiptNetAmount("");
              setErrors({});
              setReceiptUserId("");
              setReceiptUsername("");
            }}
          ></div>
          <div className={style.receiptCreateCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setReceiptCreate(false);
                  setReceiptMode("");
                  setReceiptDiscount("");
                  setReceiptNetAmount("");
                  setErrors({});
                  setReceiptUserId("");
                  setReceiptUsername("");
                }}
              />
            </div>
            <div className={style.receiptSection}>
              <div className={style.UserSlector}>
              <FormControl sx={{ width: "70%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select User
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select User"
                onChange={(e) => {
                  const userID = e.target.value;
                    setReceiptUserId(userID);
                  const selecteduser = userList.find(user => user._id === userID);
                      const userName = selecteduser ? selecteduser.Username : '';
                    setReceiptUsername(userName);
                }}
              >
                {userList && userList.length > 0 ? (
                  userList.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.Username}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No User available</MenuItem>
                )}
              </Select>
            </FormControl>
          

              </div>
              <div className={style.LabelContainer}>
                <label> Amount</label>
                <input
                  type="text"
                  name="NetAmount"
                  id="NetAmount"
                  placeholder="Amount"
                  autocomplete="off"
                  value={receiptNetAmount}
                  onChange={(e) => {
                    setReceiptNetAmount(e.target.value);
                  }}
                />
              </div>
              <div className={style.LabelContainer}>
                <label>Reference Number</label>
                <input
                  type="text"
                  name="Discount"
                  id="Discount"
                  placeholder="Reference Number"
                  autocomplete="off"
                  value={receiptDiscount}
                  onChange={(e) => {
                    setReceiptDiscount(e.target.value);
                  }}
                />
              </div>
              <div className={style.LabelContainer}>
                <label>Payment Mode</label>
                <FormControl sx={{ width: "70%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select payment mode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select payment mode "
                onChange={(e) => {
                  const mode = e.target.value;
                  setReceiptMode(mode);
                }}
              >
               
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Online">Online</MenuItem>
                  <MenuItem value="Cheque">Cheque</MenuItem>
              </Select>
            </FormControl>
               
              </div>
              <div className={style.errorMessages}>
                {Object.values(errors).map((error, index) => (
                  <div key={index} className={style.errorValue}>
                    <p>{error}</p>
                  </div>
                ))}
              </div>

              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    createReceipts();
                  }}
                >
                  Create
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setReceiptCreate(false);
                    setReceiptMode("");
                    setReceiptDiscount("");
                    setReceiptNetAmount("");
                    setErrors({});
                    setReceiptUserId("");
                    setReceiptUsername("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {receiptEdit ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setReceiptEdit(false);
              setEditReceiptDiscount("");
              setEditReceiptMode("");
              setEditReceiptNetAmount("");
              setErrors({});
            }}
          ></div>
          <div className={style.receiptCreateCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setReceiptEdit(false);
                  setEditReceiptDiscount("");
                  setEditReceiptMode("");
                  setEditReceiptNetAmount("");
                  setErrors({});
                }}
              />
            </div>
            <div className={style.receiptSection}>
              <div className={style.LabelContainer}>
                <label>Amount</label>
                <input
                  type="text"
                  name="NetAmount"
                  id="NetAmount"
                  placeholder="NetAmount"
                  autocomplete="off"
                  value={editReceiptNetAmount}
                  onChange={(e) => {
                    setEditReceiptNetAmount(e.target.value);
                  }}
                />
              </div>
              <div className={style.LabelContainer}>
                <label>Reference Number</label>
                <input
                  type="text"
                  name="Discount"
                  id="Discount"
                  placeholder="Reference Number"
                  autocomplete="off"
                  value={editReceiptDiscount}
                  onChange={(e) => {
                    setEditReceiptDiscount(e.target.value);
                  }}
                />
              </div>
              <div className={style.LabelContainer}>
                <label>Payment Mode</label>
                <FormControl sx={{ width: "70%", margin: "0 0.25rem" }}>
              <InputLabel id="demo-simple-select-label">
                Select payment mode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select payment mode "
                onChange={(e) => {
                  const mode = e.target.value;
                  setEditReceiptMode(mode);
                }}
                value={editReceiptMode}
              >
               
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Online">Online</MenuItem>
                  <MenuItem value="Cheque">Cheque</MenuItem>
              </Select>
            </FormControl>
                
              </div>
              <div className={style.errorMessages}>
                {Object.values(errors).map((error, index) => (
                  <div key={index} className={style.errorValue}>
                    <p>{error}</p>
                  </div>
                ))}
              </div>

              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    editReceipts();
                  }}
                >
                  Update
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setReceiptEdit(false);
                    setEditReceiptDiscount("");
                    setEditReceiptMode("");
                    setEditReceiptNetAmount("");
                    setErrors({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {receiptDelete ? (
        <>
          <div
            onClick={() => {
              setReceiptDelete(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setReceiptDelete(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    deleteReceipts();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setReceiptDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className={style.tabSection}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#83b516" } }}
          value={selectedTab}
          onChange={handleTabChange}
          
        >
          <Tab
            label="Invoices"
            sx={{
              "&.Mui-selected": {
                color: "#3e61a3",
                backgroundColor: "",
              },
              fontSize: "1.25rem",
              color: "#1a1e5d",
              backgroundColor: "",
              width: "500px",
              margin: "0 .5rem",
              borderRadius: "10px",
              textTransform: "capitalize",
            }}
          />
          <Tab
            label=" Receipts"
            sx={{
              "&.Mui-selected": {
                color: "#3e61a3",
                backgroundColor: "",
              },
              fontSize: "1.25rem",
              color: "#1a1e5d",
              backgroundColor: "",
              width: "500px",
              margin: "0 .5rem",
              borderRadius: "10px",
              textTransform: "capitalize",
            }}
            onClick={() => {
              getReceipts();
            }}
          />
        </Tabs>
      </div>
      <Typography>
        {selectedTab === 0 && (
          <div>
            <div className={style.Header}>
              <div className={style.HeaderText}>
                <p>Invoice List</p>
              </div>
              <div className={style.HeadButton}>
                <button
                  onClick={() => {
                    navigate("/invoice");
                  }}
                >
                  Create
                </button>
              </div>
            </div>
            {/* <div className={style.select_section}>
        <div className={style.org_select}>
          <select
            name=""
            id=""
            onChange={(e) => {
              const userID = e.target.value;
              setSelectedUser(userID)
            }}
          >
            <option value="">Select User</option>
            {userList.length > 0 &&
              userList.map((user,index) => {
                return (
                  <option key={index} value={user._id}>
                    {user.Username}
                  </option>
                );
              })}
          </select>
        </div>
      </div> */}
            <div className={style.Table}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="name"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      key="total"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      key="taxamount"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Taxable Amount
                    </TableCell>
                    <TableCell
                      key="discount"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Discount
                    </TableCell>
                    <TableCell
                      key="netamount"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Net Amount
                    </TableCell>
                    <TableCell
                      key="Action"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceList && invoiceList.length > 0
                    ? invoiceList.map((invoice, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              key="name"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              {invoice.user_name}
                            </TableCell>
                            <TableCell
                              key="total"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              {invoice.total}
                            </TableCell>
                            <TableCell
                              key="taxamount"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              {invoice.taxableamount}
                            </TableCell>
                            <TableCell
                              key="discount"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              {invoice.discount}
                            </TableCell>
                            <TableCell
                              key="netamount"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              {invoice.net_amount}
                            </TableCell>
                            <TableCell
                              key="action"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              <div className={style.Buttons}>
                                <div className={style.iconContainer}>
                                  <HiOutlineViewGrid
                                    className={style.calanderSchedule}
                                    onClick={() => {
                                      setInvoiceView(true);
                                      getIndividualInvoices(
                                        invoice.invoice_number
                                      );
                                    }}
                                  />
                                  <span className={style.tooltipText}>
                                    View
                                  </span>
                                </div>
                                <div className={style.iconContainer}>
                                  <BiEdit
                                    className={style.editButton}
                                    onClick={() => {
                                      setInvoiceNumber(invoice.invoice_number);
                                      // setInvoiceEdit(true)
                                      // getIndividualInvoices(invoice.invoice_number)
                                      navigate("/invoice", {
                                        state: {
                                          invoice_no: invoice.invoice_number,
                                          invoiceEdit: true,
                                        },
                                      });
                                    }}
                                  />
                                  <span className={style.tooltipText}>
                                    Edit
                                  </span>
                                </div>
                                <div className={style.iconContainer}>
                                  <RiDeleteBinLine
                                    className={style.deleteButton}
                                    onClick={(e) => {
                                      setInvoiceDelete(true);
                                      setInvoiceNumber(invoice.invoice_number);
                                    }}
                                  />
                                  <span className={style.tooltipText}>
                                    Delete
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            <div className={style.Header}>
              <div className={style.HeaderText}>
                <p>Receipt List</p>
              </div>
              <div className={style.HeadButton}>
                <button
                  onClick={() => {
                    setReceiptCreate(true);
                  }}
                >
                  Create
                </button>
              </div>
            </div>
            <div className={style.Table}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="name"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      key="amount"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      key="referencenumber"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Reference Number
                    </TableCell>
                    <TableCell
                      key="mode"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Payment Mode
                    </TableCell>

                    <TableCell
                      key="Action"
                      align="center"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#535370",
                        background: "#f8faff",
                        borderRight: "2px solid  #dedede",
                        zIndex: "0",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {receiptList && receiptList.length > 0
                    ? receiptList.map((receipt, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              key="name"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              {receipt.user_name}
                            </TableCell>
                            <TableCell
                              key="netamount"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              {receipt.net_amount}
                            </TableCell>
                            <TableCell
                              key="discount"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              {receipt.discount}
                            </TableCell>
                            <TableCell
                              key="mode"
                              align="left"
                              style={{
                                border: "1px solid #e8e4ef",
                                fontSize: "1rem",
                              }}
                            >
                              {receipt.mode}
                            </TableCell>
                            <TableCell>
                              {receipt.mode!=="Online"?
                              <div className={style.Buttons}>
                                <div className={style.iconContainer}>
                                  <BiEdit
                                    className={style.editButton}
                                    onClick={() => {
                                      setReceiptNumber(receipt.receipt_number);
                                      individualReceipts(
                                        receipt.receipt_number
                                      );
                                      setReceiptEdit(true);
                                    }}
                                  />
                                  <span className={style.tooltipText}>
                                    Edit
                                  </span>
                                </div>
                                <div className={style.iconContainer}>
                                  <RiDeleteBinLine
                                    className={style.deleteButton}
                                    onClick={(e) => {
                                      setReceiptDelete(true);
                                      setReceiptNumber(receipt.receipt_number);
                                    }}
                                  />
                                  <span className={style.tooltipText}>
                                    Delete
                                  </span>
                                </div>
                              </div>
                              :''}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </Typography>
    </div>
  );
};

export default Billing;
