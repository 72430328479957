import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./UserLesson.module.css";
import { baseUrl } from "../Url";
import Loader from "../Loader/Loader";

const UserLesson = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const user_id = state.user;
  const learning_id = state.learningId;
  const category_name = state.category;
  const goal_name = state.goal;
  const status = state.status;
  const timeline = state.timeline;
  const userId = state.userID;
  console.log(state);

  const [topics, setTopics] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [mainTopicId, setMainTopicId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  async function topicGet() {
    await fetch(`${baseUrl}/userlearning/details/individual/admin`, {
      method: "POST",
      body: JSON.stringify({
        user_id: user_id,
        userlearning_id: learning_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setTopics(data.data[0].topics);
        setOrgId(data.data[0].Topics[0].org_id);
        setMainTopicId(data.data[0].Topics[0].topic_id);
        setIsLoading(false)
      });
  }

  useEffect(() => {
    topicGet();
  }, [user_id]);

  return (
    <div className={style.Container}>
      <div className={style.header}>
        <h3>Lessons</h3>
      </div>
      <div className={style.lessonSection}>
        <div className={style.lessonHeader}>
          <h4>{category_name}</h4>
        </div>
        <div className={style.lessonDetail}>
          <div className={style.detailLeft}>
            <p>{category_name}</p>
            <p>Goal: {goal_name}</p>
          </div>
          <div className={style.detaiRIght}>
            <p>Timeline: {timeline}Month</p>
            <p>Status: {status}</p>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={style.topic}>
            <div className={style.topicContent}>
              {topics.length > 0 &&
                topics.map((topic) => {
                  return (
                    <p
                      onClick={() => {
                        navigate("/lesson/edit", {
                          state: {
                            organization: orgId,
                            topicId: topic.id,
                            topicName: topic.value,
                            learningId: learning_id,
                            mainTopicId: mainTopicId,
                            Id: userId,
                          },
                        });
                      }}
                    >
                      {topic.value}
                    </p>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserLesson;
